import { ElementType, HTMLAttributeAnchorTarget } from "react";
import { Link } from "react-router-dom";
import styles from "./HeaderButton.module.scss";

type HeaderButtonProps = {
  label: string;
  icon: ElementType;
  href: string;
  target?: HTMLAttributeAnchorTarget;
  externalLink?: boolean;
  rel?: string;
};

export const HeaderButton = ({
  label,
  icon: Icon,
  href,
  target,
  externalLink,
  rel = "noopener noreferrer",
}: HeaderButtonProps) => {
  const content = (
    <>
      <div className={styles.iconContainer}>
        <Icon className={styles.icon} />
      </div>
      <span className={styles.label}>{label}</span>
    </>
  );
  if (externalLink) {
    return (
      <a href={href} target={target} rel={rel} className={styles.button}>
        {content}
      </a>
    );
  }
  return (
    <Link to={href} target={target} rel={rel} className={styles.button}>
      {content}
    </Link>
  );
};
