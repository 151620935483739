import { Modal } from "antd";
import { useTranslation } from "react-i18next";

type HelpModalProps = {
  open: boolean;
  onClose: () => void;
};

export const HelpModal = ({ open, onClose }: HelpModalProps) => {
  const { t } = useTranslation();

  const handleOnClose = () => {
    onClose();
  };

  return (
    <Modal title={t("Help")} open={open} onCancel={handleOnClose} width={688} footer={null}>
      <iframe
        src="https://www.loom.com/embed/c54159e214be45aeb1f03274a8fffc51?sid=7da8d187-6e4e-42b6-94e4-f0dd245814fd&hideEmbedTopBar=true"
        allowFullScreen
        width="640"
        height="360"
        style={{ border: 0 }}
      ></iframe>
    </Modal>
  );
};
