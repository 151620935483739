import { Card, Collapse, Space, Tag, Typography } from "antd";
import moment from "moment";
import { DateFormats } from "@app/constants/date.constants";
import {
  EActivityLogDirection,
  EActivityLogType,
} from "@app/features/super-admin/types/super-admin.activity-log.types";

export const ActivityLogCard = ({
  title,
  date,
  superAdminName,
  type,
  direction,
  children,
}: {
  title: string;
  date?: string;
  superAdminName?: string;
  type: EActivityLogType;
  direction?: EActivityLogDirection;
  children?: React.ReactNode;
}) => {
  const getSourceTag = () => {
    switch (type) {
      case EActivityLogType.SIPGATE:
        return (
          <Tag style={{ marginRight: 0 }} color="purple">
            Sipgate
          </Tag>
        );
      case EActivityLogType.SUPERCHAT:
        return (
          <Tag style={{ marginRight: 0 }} color="blue">
            Superchat
          </Tag>
        );
      default:
        return null;
    }
  };

  const label = (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      {/* Left Content */}
      <Space size="small">
        <Typography.Text style={{ whiteSpace: "pre-line" }}>{title}</Typography.Text>
        <Typography.Text
          type="secondary"
          style={{
            fontSize: "12px",
          }}
        >
          {moment(date).format(DateFormats.FULL_DATE_TIME)}
          {superAdminName ? ` - ${superAdminName}` : ""}
        </Typography.Text>
      </Space>

      {/* Right-Aligned Tags */}
      <div style={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap", gap: "4px" }}>
        {getSourceTag()}
        <Tag
          style={{ marginRight: 0 }}
          color={direction === EActivityLogDirection.IN ? "green" : "cyan"}
        >
          {direction}
        </Tag>
      </div>
    </div>
  );

  if (!children) {
    return (
      <Card
        style={{
          border: "1px solid #d9d9d9",
        }}
        styles={{ body: { padding: "8px 12px" } }}
        size="small"
      >
        {label}
      </Card>
    );
  }

  return (
    <Collapse
      size="small"
      items={[
        { key: "1", label, children: <div style={{ whiteSpace: "pre-line" }}>{children}</div> },
      ]}
      style={{ backgroundColor: "white" }}
    />
  );
};
