import { useQuery } from "@tanstack/react-query";
import {
  CompanyIntegrationSyncDef,
  CompanyIntegrationSyncRequestDef,
  CompanyIntegrationSyncWithPaginationDef,
} from "@app/types/company-integration-sync.types";
import { api } from "./api";

const getCompanyIntegrationSyncs = async (
  params: CompanyIntegrationSyncRequestDef
): Promise<CompanyIntegrationSyncWithPaginationDef> => {
  const response = await api.get<CompanyIntegrationSyncWithPaginationDef>(
    "/crafthunts/company_integration_syncs",
    { params }
  );
  return response.data;
};

export const launchSync = async (
  companyIntegrationId: string
): Promise<CompanyIntegrationSyncDef> => {
  const response = await api.put<CompanyIntegrationSyncDef>(
    "/crafthunts/company_integration_syncs",
    {
      companyIntegrationId,
    }
  );
  return response.data;
};

export const useGetCompanyIntegrationSyncs = (
  params: CompanyIntegrationSyncRequestDef,
  options?: {
    refetchInterval?: number;
  }
) =>
  useQuery({
    queryKey: ["company-integration-syncs", params] as const,
    queryFn: ({ queryKey }) => getCompanyIntegrationSyncs(queryKey[1]).then((res) => res),
    refetchInterval: options?.refetchInterval,
  });
