import { ALL_LANGUAGES } from "@app/constants/languages.constants";
import { AuthTokenDef } from "@app/features/auth/types/auth.types";
import { convertLangForLanguagePicker } from "@app/helpers/language.helper";
import { Dispatch } from "@app/store/store";
import i18n from "@app/translations/i18n";

export const onLogin = async (dispatch: Dispatch, parsedToken: AuthTokenDef) => {
  if (!parsedToken.company) {
    return null;
  }
  const companyId = parsedToken.company.id;
  const isInternalSwitching = parsedToken.isInternalSwitching;
  const company = await dispatch.companyInfo.getCompanyInfo(companyId);

  if (company?.company?.lang && !isInternalSwitching) {
    const companyLang = ALL_LANGUAGES.find(
      (lang) => lang.value === convertLangForLanguagePicker(company.company.lang)
    );
    if (companyLang) {
      i18n.changeLanguage(companyLang.value);
    }
  }

  await Promise.all(
    [
      // shared
      dispatch.tags.getAllJobTitles(),
      dispatch.tags.getAllTrades(),
      dispatch.tags.getAllIndustries(),
      dispatch.tags.getAllProjectTypes(),
      // company data
      dispatch.companyData.getBenefits(),
      dispatch.companyData.getCompanyBenefits(),
      dispatch.machines.getMachines(),
      dispatch.projects.getProjects(),
      dispatch.companyData.getKeyData(),
      dispatch.trades.getCompanyTrades(),
      dispatch.candidates.getCandidateStatuses(),
      dispatch.auth.getMagicLink(),
    ].filter(Boolean)
  );
};
