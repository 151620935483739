import { useQuery } from "@tanstack/react-query";
import {
  CandidateSADef,
  CandidatesSAWithPaginationDef,
  ESuperAdminCandidateRejectionReason,
  GetCandidatesSAParamsDef,
} from "@app/features/super-admin/types/super-admin.candidate.types";
import { ECandidateApprovalAction } from "@app/types/candidate.types";
import { queryKeysSA } from "./queryKeys";
import { superAdminApi } from "./super-admin.api";

export const getCandidatesSA = (params: GetCandidatesSAParamsDef) => {
  return superAdminApi.get<CandidatesSAWithPaginationDef>("/super_admin/candidates", {
    params,
  });
};

export const useCandidatesSA = (
  params: GetCandidatesSAParamsDef,
  options?: {
    refetchInterval?: number;
    enabled?: boolean;
  }
) =>
  useQuery({
    enabled: options?.enabled,
    queryKey: [...queryKeysSA.lists(), "candidates", params] as const,
    queryFn: ({ queryKey }) => getCandidatesSA(queryKey[3]).then(({ data }) => data),
    refetchInterval: options?.refetchInterval,
  });

export const useCandidateSA = (
  params: {
    id?: string | null;
  },
  options?: {
    refetchInterval?: number;
  }
) =>
  useQuery({
    enabled: Boolean(params.id),
    queryKey: [...queryKeysSA.all, "candidates", params] as const,
    queryFn: () =>
      superAdminApi
        .get<CandidateSADef>(`/super_admin/candidates/${params.id}`)
        .then(({ data }) => data),
    refetchInterval: options?.refetchInterval,
  });

export const approveCandidateSA = (
  payload: {
    candidateId: string;
    description: string;
    isVisibleToCompany: boolean;
  } & (
    | {
        action: ECandidateApprovalAction.APPROVE;
      }
    | {
        action: ECandidateApprovalAction.REJECT;
        rejectionReason: ESuperAdminCandidateRejectionReason;
        rejectionMessage: string;
      }
  )
) => {
  return superAdminApi.post(`/super_admin/candidate_approvals`, payload);
};
