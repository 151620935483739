import { Col, Form, Row, message } from "antd";
import { Rule } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AddressPicker, EAddressPickerType } from "@app/components/ui/AddressPicker/AddressPicker";
import { MomentDatePicker } from "@app/components/ui/DatePicker";
import { FormModal } from "@app/components/ui/FormModal/FormModal";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { DateFormats } from "@app/constants/date.constants";
import {
  editUserTimelineSA,
  postUserTimelineSA,
} from "@app/features/super-admin/api/super-admin.user.api";
import { mapTimelineToApi } from "@app/features/super-admin/helpers/super-admin.helper";
import {
  EditTimelineDef,
  TimelineFormDef,
} from "@app/features/super-admin/types/super-admin.user.types";
import { ApiFormErrorDef, getErrorMessages } from "@app/helpers/error.helper";
import { RootState } from "@app/store/store";
import { CompanySearch } from "./components/CompanySearch";

type TimelineModalProps = {
  open: boolean;
  workerProfileId: string;
  selectedTimeline?: TimelineFormDef;
  onSave: () => void;
  onCancel: () => void;
};

export const TimelineModal = ({
  open,
  workerProfileId,
  selectedTimeline,
  onSave,
  onCancel,
}: TimelineModalProps) => {
  const { t } = useTranslation();
  const jobTitles = useSelector((state: RootState) => state.tags.jobTitles);
  const availableTradesTags = useSelector((state: RootState) => state.tags.trades);
  const [form] = useForm<TimelineFormDef>();

  const editMode = !!selectedTimeline?.id;
  const requiredRule: Rule = { required: true, whitespace: true, message: t("required") };
  const jobTitleChoices = jobTitles.map((i) => ({
    value: i.id,
    label: t(i.name),
  }));
  const tradesChoices = availableTradesTags.map((i) => ({
    value: i.id,
    label: t(i.name),
  }));

  useEffect(() => {
    form.resetFields();
  }, [JSON.stringify(selectedTimeline)]);

  const handleMonthPickerChange = (
    date: moment.Moment | null,
    pickerType: "startDate" | "endDate"
  ) => {
    const firstDayOfMonth = date?.startOf("month");
    form.setFieldsValue({
      [pickerType]: firstDayOfMonth,
    });
  };

  const onSubmitForm = async (values: TimelineFormDef) => {
    try {
      if (selectedTimeline?.id) {
        await editUserTimelineSA(
          selectedTimeline.id,
          workerProfileId,
          mapTimelineToApi(values) as EditTimelineDef
        );
      } else {
        await postUserTimelineSA(workerProfileId, mapTimelineToApi(values));
      }
      message.success({ content: t("Saved!"), key: "user-timeline" });
      onSave();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error({
          content: getErrorMessages(error as ApiFormErrorDef),
          key: "user-timeline",
          duration: 10,
        });
      }
    }
  };

  return (
    <FormModal
      title={t(editMode ? "Edit" : "Add")}
      open={open}
      onFinish={onSubmitForm}
      onCancel={onCancel}
      form={form}
      initialValues={selectedTimeline}
      id="timeline-form"
    >
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item
            label={t("Company")}
            name={"companyId"}
            rules={[
              {
                ...requiredRule,
                type: "object" as const,
              },
            ]}
          >
            <CompanySearch placeholder={t("Search for companies by name...")} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item rules={[requiredRule]} label={t("Role")} name="jobTitleId" required>
            <SelectAutocomplete placeholder={t("Select")} options={jobTitleChoices} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("Trades")} name="tradesId">
            <SelectAutocomplete mode="multiple" placeholder={t("Select")} options={tradesChoices} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("Country")} name="address">
            <AddressPicker type={EAddressPickerType.COUNTRY} disableMap />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            rules={[
              {
                ...requiredRule,
                type: "object" as const,
              },
            ]}
            label={t("Start date")}
            name="startDate"
          >
            <MomentDatePicker
              picker="month"
              size="large"
              format={DateFormats.M_Y}
              allowClear
              placeholder={DateFormats.M_Y}
              style={{ width: "100%" }}
              disabledDate={(current) =>
                form.getFieldValue("endDate") ? current > form.getFieldValue("endDate") : false
              }
              onChange={(date) => handleMonthPickerChange(date, "startDate")}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("End date")} name="endDate">
            <MomentDatePicker
              picker="month"
              size="large"
              format={DateFormats.M_Y}
              allowClear
              placeholder={DateFormats.M_Y}
              style={{ width: "100%" }}
              disabledDate={(current) => current < form.getFieldValue("startDate")}
              onChange={(date) => handleMonthPickerChange(date, "endDate")}
            />
          </Form.Item>
        </Col>
      </Row>
    </FormModal>
  );
};
