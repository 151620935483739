import { App, Card, Popconfirm, Space } from "antd";
import axios from "axios";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@app/components/ui/Button/Button";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { DateFormats } from "@app/constants/date.constants";
import {
  deleteUserCertificateSA,
  getUserCertificatesSA,
} from "@app/features/super-admin/api/super-admin.user.api";
import {
  CertificateDef,
  CertificateFormDef,
  UserFormDef,
} from "@app/features/super-admin/types/super-admin.user.types";
import { ApiFormErrorDef, getErrorMessages } from "@app/helpers/error.helper";
import { CertificateModal } from "./components/CertificateModal/CertificateModal";

type UserCertificatesProps = {
  selectedUser?: UserFormDef;
};

export const UserCertificates = ({ selectedUser }: UserCertificatesProps) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [loading, setLoading] = useState(true);
  const [certificates, setCertificates] = useState<CertificateDef[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState<CertificateFormDef | undefined>();

  const fetchData = async () => {
    if (selectedUser?.id) {
      setLoading(true);
      try {
        const data = await getUserCertificatesSA(selectedUser.id);
        setCertificates(data.data);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOnAdd = () => {
    setOpen(true);
    setSelectedCertificate(undefined);
  };

  const handleOnEdit = (certificate: CertificateDef) => {
    setOpen(true);
    setSelectedCertificate({
      id: certificate.id,
      title: certificate.title,
      description: certificate.description,
      issueDate: certificate.issueDate ? moment(certificate.issueDate) : null,
      typeId: certificate.typeId,
      address: {
        address: certificate.address || undefined,
        coordinates: certificate.coordinate || undefined,
      },
    });
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleOnSave = () => {
    handleOnClose();
    fetchData();
  };

  const handleOnDelete = async (certificate: CertificateDef) => {
    try {
      if (selectedUser) {
        await deleteUserCertificateSA(certificate.id, selectedUser.id);
        handleOnSave();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error({
          content: getErrorMessages(error as ApiFormErrorDef),
          key: "user-certificates",
          duration: 10,
        });
      }
    }
  };

  return (
    <div>
      {loading && <LoadingSpinner />}
      <Button onClick={handleOnAdd}>{t("Add")}</Button>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        {certificates.map((item) => (
          <Card
            key={item.id}
            title={t(item.title)}
            extra={
              <Space>
                <Popconfirm
                  title={t("Are you sure you want to delete this?")}
                  okText={t("Yes")}
                  cancelText={t("No")}
                  onConfirm={() => handleOnDelete(item)}
                >
                  <Button type="text" size="small" danger>
                    {t("Delete")}
                  </Button>
                </Popconfirm>
                <Button type="text" size="small" onClick={() => handleOnEdit(item)}>
                  {t("Edit")}
                </Button>
              </Space>
            }
            size="small"
          >
            {!!item.description && <div>{item.description}</div>}
            {!!item.address && (
              <div>
                {t("Country")}: {item.address.country}
              </div>
            )}
            {!!item.issueDate && (
              <div>
                {t("Issue date")}: {moment(item.issueDate).format(DateFormats.M_Y)}
              </div>
            )}
          </Card>
        ))}
      </Space>
      {selectedUser && (
        <CertificateModal
          open={open}
          workerProfileId={selectedUser.id}
          selectedCertificate={selectedCertificate}
          onSave={handleOnSave}
          onCancel={handleOnClose}
        />
      )}
    </div>
  );
};
