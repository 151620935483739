import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isProd } from "@app/constants/env";

export const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    if (isProd()) {
      window.analytics?.page(location.pathname);
    }
  }, [location]);
};
