import { Input, Space, Spin, Typography } from "antd";
import { Fragment, memo, useState } from "react";
import {
  createActivityLogSA,
  useActivityLogsSA,
} from "@app/features/super-admin/api/super-admin.activity-log.api";
import { EActivityLogType } from "@app/features/super-admin/types/super-admin.activity-log.types";
import { ActivityLogNote } from "./components/ActivityLogNote";
import { ActivityLogSipgate } from "./components/ActivityLogSipgate";
import { ActivityLogSuperchat } from "./components/ActivityLogSuperchat";

type ActivityLogsProps = {
  workerProfileId: string;
  disabled: boolean;
};

export const ActivityLogs = memo(({ workerProfileId, disabled }: ActivityLogsProps) => {
  const [noteMessage, setNoteMessage] = useState("");
  const activityLogsQuery = useActivityLogsSA(
    {
      workerProfileId,
      limit: 100,
    },
    {
      refetchInterval: 30000,
    }
  );

  const handleCreateNote = async (value: string) => {
    if (!value) {
      return;
    }
    setNoteMessage("");
    await createActivityLogSA({
      content: { message: value },
      type: EActivityLogType.NOTE,
      workerProfileId,
    });
    activityLogsQuery.refetch();
  };

  if (activityLogsQuery.isLoading) {
    return <Spin />;
  }

  return (
    <div style={{ height: "400px", display: "flex", flexDirection: "column" }}>
      {/* Create Note Input */}
      <div>
        <Input.Search
          placeholder="Create a note"
          enterButton="Send"
          size="large"
          onSearch={(value) => {
            handleCreateNote(value);
          }}
          onChange={(e) => setNoteMessage(e.target.value)}
          value={noteMessage}
          allowClear
          disabled={disabled}
        />
      </div>
      {/* Scrollable Activity Logs */}
      <div style={{ flex: 1, overflowY: "auto", marginTop: "16px" }}>
        {!activityLogsQuery.data?.data?.length && (
          <Typography.Text type="secondary">No activity logs yet</Typography.Text>
        )}
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
          {activityLogsQuery.data?.data?.map((activityLog) => (
            <Fragment key={activityLog.id}>
              {
                {
                  [EActivityLogType.NOTE]: <ActivityLogNote activityLog={activityLog} />,
                  [EActivityLogType.SIPGATE]: <ActivityLogSipgate activityLog={activityLog} />,
                  [EActivityLogType.SUPERCHAT]: <ActivityLogSuperchat activityLog={activityLog} />,
                }[activityLog.type]
              }
            </Fragment>
          ))}
        </Space>
      </div>
    </div>
  );
});
