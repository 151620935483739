import { Country, isSupportedCountry } from "react-phone-number-input";

/**
 * Convert language to backend format (eg. pt-br)
 */
export const convertLangForBackend = (lang: string) =>
  (lang || "en").replace("_", "-").toLowerCase();

/**
 * Convert language to Language Picker format (eg. pt_BR)
 */
export const convertLangForLanguagePicker = (lang: string) => {
  const [first, second] = (lang || "en").replace("-", "_").split("_");
  return [first?.toLowerCase(), second?.toUpperCase()].filter(Boolean).join("_");
};
/**
 * Convert language to customer io format (eg. pt-BR)
 */
export const convertLangForAnalytics = (lang: string) => {
  const [first, second] = (lang || "en").replace("_", "-").split("-");
  return [first?.toLowerCase(), second?.toUpperCase()].filter(Boolean).join("-");
};

/**
 * Convert language to google maps region (eg. pt_BR -> pt)
 * so search results are biased to a particular region
 */
export const mapLangToGoogleMapsRegion = (lang: string) => {
  const [first, second] = (lang || "en").toLowerCase().split("_");
  if (first === "en") {
    return "de";
  }
  if (second === "br") {
    return second;
  }
  return first;
};

export const mapLocaleToPhoneInputCountry = (locale: string): Country | null => {
  switch (locale.toLowerCase()) {
    case "en":
      return "GB";
    case "de":
      return "DE";
    case "pt_br":
      return "BR";
    case "da":
      return "DK";
    case "cs":
      return "CZ";
    default:
      if (isSupportedCountry(locale.toUpperCase() as Country)) {
        return locale.toUpperCase() as Country;
      }
      return null;
  }
};

export const getDefaultCountry = (locale: string): Country => {
  const country = mapLocaleToPhoneInputCountry(locale);
  if (!country) {
    return "DE";
  }
  switch (country) {
    case "GB":
      return "DE";
    default:
      return country;
  }
};

const I18N_LANGUAGE_LEY = "i18n-lng";

export const saveLanguage = (locale: string) => {
  try {
    localStorage?.setItem(I18N_LANGUAGE_LEY, locale);
  } catch (err) {
    console.error(err);
  }
};

export const getSavedLanguage = () => {
  try {
    return localStorage?.getItem(I18N_LANGUAGE_LEY)?.toString() || null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getLangName = (
  displayLang: string,
  langCode: string,
  type: Intl.DisplayNamesType = "language"
) => {
  try {
    return new Intl.DisplayNames([displayLang], {
      type,
      fallback: "code",
    }).of(langCode);
  } catch {
    return langCode;
  }
};
