import { Card, Col, Row } from "antd";
import { useSelector } from "react-redux";
import { useGetCompanyIntegration } from "@app/api/company-integrations.api";
import { RootState } from "@app/store/store";
import { ECompanyIntegrationSyncFeatures } from "@app/types/company-integration.types";
import { CompanyIntegrationSync } from "./components/CompanyIntegrationSync";

export const CompanyIntegrationSettings = () => {
  const parsedSuperToken = useSelector((state: RootState) => state.superAdmin.parsedSuperToken);
  const companyIntegrationQuery = useGetCompanyIntegration();
  const canSyncJobs = companyIntegrationQuery.data?.syncFeatures?.includes(
    ECompanyIntegrationSyncFeatures.JOBS
  );
  const cardStyle = { height: "100%" };

  if (!parsedSuperToken) {
    return null;
  }

  return (
    <Row gutter={[16, 16]}>
      <Col>
        {companyIntegrationQuery.data && canSyncJobs && (
          <Card
            style={cardStyle}
            title={"ATS integration" + " - " + companyIntegrationQuery.data?.software}
          >
            <CompanyIntegrationSync companyIntegrationId={companyIntegrationQuery.data.id} />
          </Card>
        )}
      </Col>
    </Row>
  );
};
