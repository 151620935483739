import { Switch, SwitchProps } from "antd";
import styles from "./InlineSwitch.module.scss";

type InlineSwitchProps = {
  label: string;
} & SwitchProps;

export const InlineSwitch = ({ id, label, ...rest }: InlineSwitchProps) => {
  return (
    <div className={styles.inlineSwitch}>
      <Switch id={id} {...rest} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
