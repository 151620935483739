import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { App, Card, Col, Divider, Form, Input, Row, Switch, Typography } from "antd";
import { useWatch } from "antd/lib/form/Form";
import axios from "axios";
import Linkify from "linkify-react";
import { useEffect, useState } from "react";
import isEqual from "react-fast-compare";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "@app/components/ui/Button/Button";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { Svg } from "@app/components/ui/Svg/Svg";
import { ApiFormErrorDef, getErrorMessages } from "@app/helpers/error.helper";
import { RootState, store } from "@app/store/store";
import { CompanyBenefitFormDef, InternalCompanyBenefit } from "@app/types/company-data.types";

type EmployeeBenefitCardProps = {
  benefit: InternalCompanyBenefit;
};

export const EmployeeBenefitCard = ({ benefit }: EmployeeBenefitCardProps) => {
  const { message } = App.useApp();
  const companyBenefits = useSelector((state: RootState) => state.companyData.companyBenefits);
  const [isSaving, setIsSaving] = useState(false);
  const [showTextarea, setShowTextarea] = useState(false);
  const { dispatch } = store;
  const { t } = useTranslation();
  const [form] = Form.useForm<CompanyBenefitFormDef>();
  const customDescription = useWatch("description", form);
  const [hasChangedValues, setHasChangedValues] = useState(false);

  useEffect(() => {
    form.setFieldsValue(benefit);
  }, [JSON.stringify(benefit)]);

  const handleOnChange = (_: Partial<CompanyBenefitFormDef>, values: CompanyBenefitFormDef) => {
    const hasActuallyChanged = !isEqual(
      { description: values.description || "", isActive: values.isActive },
      { description: benefit.description || "", isActive: benefit.isActive }
    );
    setHasChangedValues(hasActuallyChanged);
  };

  async function updateBenefit(values: CompanyBenefitFormDef) {
    setIsSaving(true);
    const savedBenefit = companyBenefits.find((i) => i.benefitId === benefit.id);
    const description = values.description?.trim() || null;
    try {
      if (savedBenefit) {
        await dispatch.companyData.updateCompanyBenefitAction({
          id: savedBenefit.id,
          description,
          isActive: values.isActive,
        });
      } else if (values.description || values.isActive) {
        await dispatch.companyData.addCompanyBenefitAction({
          benefitId: benefit.id,
          description,
          isActive: values.isActive,
        });
      }
      message.success({ content: t("Saved!"), key: "employee-benefit", duration: 1 });
      setHasChangedValues(false);
      setShowTextarea(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error({
          content: getErrorMessages(error as ApiFormErrorDef),
          key: "employee-benefit",
          duration: 10,
        });
      }
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <Card
      style={{ width: "100%", height: "100%" }}
      styles={{
        body: {
          height: "100%",
        },
      }}
    >
      {isSaving && <LoadingSpinner />}
      <Form
        form={form}
        layout="vertical"
        name={`employee-benefit-${benefit.benefit}`}
        onValuesChange={handleOnChange}
        style={{ height: "100%" }}
        onFinish={updateBenefit}
        initialValues={benefit}
        data-hs-do-not-collect="true"
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Row
              align="middle"
              wrap={false}
              gutter={10}
              style={{ minHeight: 80, paddingBottom: 10 }}
            >
              <Col flex="none">
                <Svg image={benefit.imageUrl} style={{ width: 45, height: 45, display: "flex" }} />
              </Col>
              <Col flex="auto">
                <Typography.Title level={5}>{t(benefit.benefit)}</Typography.Title>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item
                  name="description"
                  rules={[{ whitespace: true, message: t("required") }]}
                >
                  {!showTextarea ? (
                    <Typography.Paragraph style={{ whiteSpace: "pre-line" }}>
                      <Linkify options={{ target: "_blank" }}>
                        {customDescription || t(`${benefit.benefit}-desc`)}
                      </Linkify>
                    </Typography.Paragraph>
                  ) : (
                    <Input.TextArea
                      size="large"
                      autoSize={{ minRows: 4, maxRows: 8 }}
                      style={{ width: "100%" }}
                      placeholder={t("Enter...")}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col flex="auto" />
              <Col flex="none">
                <Button onClick={() => setShowTextarea((prevState) => !prevState)}>
                  {!showTextarea ? <EditOutlined /> : <CheckOutlined />}
                </Button>
              </Col>
              <Divider />
              <Col span={24}>
                <Form.Item
                  label={t("We offer this!")}
                  name="isActive"
                  valuePropName="checked"
                  hasFeedback={false}
                >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item noStyle>
                  <Button
                    htmlType="submit"
                    type="primary"
                    style={{ float: "right" }}
                    disabled={!hasChangedValues}
                  >
                    {t("Save")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Card>
  );
};
