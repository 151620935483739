import { Button, App } from "antd";
import { PropsWithChildren } from "react";
import { generateShortLink } from "@app/api/short-link.api";
import { isDev } from "@app/constants/env";
import { getMagicLink } from "@app/features/super-admin/api/super-admin.magic.api";

const LINK_KEY = "link";

export const MagicLinkButton = ({
  userId,
  style,
  children,
}: PropsWithChildren<{ userId: string; style?: React.CSSProperties }>) => {
  const { message, modal } = App.useApp();

  const copyToClipboard = (value: string) => {
    try {
      navigator.clipboard.writeText(value);
      message.success({ content: "Link copied!", key: LINK_KEY, duration: 0.5 });
    } catch {
      modal.error({
        title: "Something went wrong, here is the raw link",
        content: value,
      });
      message.destroy(LINK_KEY);
    }
  };

  const generateShortMagicLink = async () => {
    try {
      message.loading({ content: "Preparing magic link", key: LINK_KEY, duration: 0 });
      const magicLink = await getMagicLink(userId);
      const shortMagicLink = isDev() ? magicLink.data : await generateShortLink(magicLink.data);
      copyToClipboard(shortMagicLink);
    } catch {
      modal.error({
        title: "Something went wrong",
      });
      message.destroy(LINK_KEY);
    }
  };

  return (
    <Button style={style} onClick={() => generateShortMagicLink()} type="link">
      {children}
    </Button>
  );
};
