import { createModel } from "@rematch/core";
import { getBearerToken, parseJwt } from "@app/features/auth/helpers/auth.helper";
import { superAdminApi, superAdminApiCached } from "@app/features/super-admin/api/super-admin.api";
import { superAdminCheck } from "@app/features/super-admin/api/super-admin.check.api";
import { loginCompanySA } from "@app/features/super-admin/api/super-admin.company.api";
import { updateUserSA } from "@app/features/super-admin/api/super-admin.user.api";
import { SUPER_TOKEN_KEY } from "@app/features/super-admin/constants/super-admin.constants";
import {
  LoginAsAdminDef,
  LoginUnclaimCompanyDef,
} from "@app/features/super-admin/types/super-admin.company.types";
import { EditUserDef } from "@app/features/super-admin/types/super-admin.user.types";
import { disableAnalytics } from "@app/helpers/analytics.helper";
import { RootModel } from "@app/store/models/models";

export const superAdmin = createModel<RootModel>()({
  state: {
    superToken: localStorage.getItem(SUPER_TOKEN_KEY) as string | null,
    parsedSuperToken: parseJwt(localStorage.getItem(SUPER_TOKEN_KEY) as string),
  },
  reducers: {
    setSuperAdminToken: (state, payload: string) => {
      localStorage.setItem("super-token", payload);
      const parsed = parseJwt(payload);
      if (!parsed) {
        localStorage.removeItem("super-token");
        return;
      }
      superAdminApi.defaults.headers.common.Authorization = getBearerToken(payload);
      superAdminApiCached.defaults.headers.common.Authorization = getBearerToken(payload);
      // Disable analytics when super admin token is set
      disableAnalytics();
      state.superToken = payload;
      state.parsedSuperToken = parsed;
      return state;
    },
    removeSuperAdminToken: (state) => {
      localStorage.removeItem("super-token");
      delete superAdminApi.defaults.headers.common.Authorization;
      delete superAdminApiCached.defaults.headers.common.Authorization;
      state.superToken = null;
      state.parsedSuperToken = undefined;
      return state;
    },
  },
  effects: (dispatch) => ({
    superAdminCheck: async (password) => {
      try {
        const res = await superAdminCheck(password);
        if (res.data?.accessToken) {
          dispatch.superAdmin.setSuperAdminToken(res.data.accessToken);
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    clearAdminData: () => {
      dispatch.superAdmin.removeSuperAdminToken();
    },
    loginCompanySA: async (payload: LoginAsAdminDef | LoginUnclaimCompanyDef) => {
      try {
        let token;
        if ((payload as LoginAsAdminDef).targetWorkerProfileId) {
          token = await loginCompanySA(payload as LoginAsAdminDef);
        } else {
          token = await loginCompanySA(payload as LoginUnclaimCompanyDef);
        }
        await dispatch.auth.setTokenData(token.data.accessToken);
        // just in case, clear super admin jobs
        dispatch.jobAds.clearAllJobAdAction();
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    updateUserSA: (payload: Partial<EditUserDef>) => {
      return updateUserSA(payload);
    },
  }),
});
