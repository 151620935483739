import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { isDev, ENV, isProd } from "./constants/env";

Sentry.init({
  dsn: "https://2d2a105784fe56e2c6d6a65c4ee1d04e@o4504809331752960.ingest.us.sentry.io/4508676842389504",
  enabled: !isDev(),
  release: ENV.VERSION,
  environment: isProd() ? "production" : "staging",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
