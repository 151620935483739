import { PlusOutlined } from "@ant-design/icons";
import { Col, Row, Space, Typography } from "antd";
import { ReactNode, useRef } from "react";
import { Button } from "@app/components/ui/Button/Button";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";

type CardLayoutProps<T> = {
  loading?: boolean;
  data: T[];
  renderData: (item: T, index: number) => ReactNode;
  title?: string;
  addMoreTitle?: string;
  onAddMore?: () => void;
};

export const CardLayout = <T extends Record<"id", string>>({
  loading,
  data,
  renderData,
  title,
  onAddMore,
  addMoreTitle,
}: CardLayoutProps<T>) => {
  const lastCard = useRef<HTMLDivElement>(null);

  const handleOnClickAddMore = () => {
    onAddMore?.();
    setTimeout(() => {
      lastCard.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  return (
    <>
      {(!!title || !!addMoreTitle) && (
        <Col>
          <Space direction="horizontal" size="large">
            {!!title && (
              <Typography.Title level={5} style={{ margin: 0 }}>
                {title}
              </Typography.Title>
            )}
            {!!addMoreTitle && (
              <Button onClick={handleOnClickAddMore} type="primary">
                <PlusOutlined />
                {addMoreTitle}
              </Button>
            )}
          </Space>
        </Col>
      )}
      <Col style={{ position: "relative" }} span={24}>
        {loading && <LoadingSpinner style={{ alignItems: "start", paddingTop: "15vh" }} />}
        <Row gutter={[16, 16]} style={{ maxWidth: 1400 }}>
          {data.map((item, index) => (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={8}
              xxl={6}
              key={item.id}
              ref={index === data.length - 1 ? lastCard : null}
              style={{ display: "flex" }}
            >
              {renderData(item, index)}
            </Col>
          ))}
        </Row>
      </Col>
    </>
  );
};
