import { parseAsString, useQueryState } from "nuqs";

export const useApplicationModal = () => {
  const [applicationId, setApplicationId] = useQueryState("applicationId", parseAsString);

  return {
    applicationId,
    setApplicationId,
  };
};
