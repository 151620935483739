import { useQuery } from "@tanstack/react-query";
import { VideoDef } from "@app/types/videos.types";
import { api } from "./api";

export const getCompanyVideos = async (params: { companyId: string }) => {
  const { data } = await api.get<VideoDef[]>(`/crafthunts/videos`, {
    params,
  });
  return data;
};

export const createCompanyVideo = async (params: {
  title: string;
  url: string;
  description?: string;
  lang: string;
}) => {
  const { data } = await api.post<VideoDef>(`/crafthunts/videos`, params);
  return data;
};

export const deleteCompanyVideo = async (id: string) => {
  await api.delete<void>(`/crafthunts/videos/${id}`);
};

export const updateCompanyVideo = async (params: {
  id: string;
  title: string;
  url: string;
  description?: string;
  lang: string;
}) => {
  const { data } = await api.put<VideoDef>(`/crafthunts/videos/${params.id}`, params);
  return data;
};

export const useVideos = (params: { companyId: string }) =>
  useQuery({
    queryKey: ["videos", params] as const,
    queryFn: ({ queryKey }) => getCompanyVideos(queryKey[1]),
  });
