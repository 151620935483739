import { UserSettingsDef } from "@app/types/user-settings.types";
import { api } from "./api";

export const getUserSettings = async () => {
  const response = await api.get<UserSettingsDef[]>("/worker_profiles/info_settings", {
    params: {
      method: "lang",
    },
  });
  const data = response?.data[0];
  return {
    id: data.id,
    lang: data.lang,
  } as UserSettingsDef;
};

export const putUserSettings = async (payload: UserSettingsDef) => {
  const response = await api.put<UserSettingsDef>(`/worker_profiles/info_settings/${payload.id}`, {
    lang: payload.lang,
  });
  return {
    id: response.data.id,
    lang: response.data.lang,
  } as UserSettingsDef;
};
