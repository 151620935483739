import { StarFilled } from "@ant-design/icons";
import { Typography } from "antd";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Logo } from "@app/components/ui/Logo/Logo";
import styles from "./AuthHero.module.scss";
import logoBauer from "./logos/bauer.webp";
import logoDrees from "./logos/drees&sommer.webp";
import logoEiffage from "./logos/eiffage.webp";
import logoGeiger from "./logos/geiger.webp";
import logoOctopus from "./logos/octopus_energy.webp";
import logoSpie from "./logos/spie.webp";
import logoStrabag from "./logos/strabag.webp";
import logoZausinger from "./logos/zausinger.webp";

const logos = [
  logoBauer,
  logoDrees,
  logoGeiger,
  logoOctopus,
  logoEiffage,
  logoSpie,
  logoZausinger,
  logoStrabag,
];

const TESTIMONIALS = [
  {
    image: "https://a.storyblok.com/f/123131/800x800/f1eae39de0/1718310640898.jpeg",
    title: "testimonial-quote-zausinger",
    author: "Rene Breyer",
    authorRole: "testimonial-role-zausinger",
    authorCompany: "Zausinger GmbH & Co. KG",
    authorLink: "https://crafthunt.app/companies/zausinger-gmbh-and-co-kg-d7vup8ht",
    companyLogo:
      "https://a.storyblok.com/f/123131/600x150/0723d2ecce/bf924aad-fd59-4bb0-8b5b-538831586cd4.png",
    companyLogoHeight: 50,
  },
];

export const AuthHero = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.mainLogo}>
          <Logo />
        </div>

        <AuthHeroTitles />

        <div className={styles.items}>
          <Typography.Text className={styles.item}>✓ {t("sign-up-list-item-1")}</Typography.Text>
          <Typography.Text className={styles.item}>✓ {t("sign-up-list-item-2")}</Typography.Text>
          <Typography.Text className={styles.item}>✓ {t("sign-up-list-item-3")}</Typography.Text>
        </div>

        {TESTIMONIALS.slice(0, 1).map((item) => (
          <section key={item.title} className={styles.review}>
            <figure className={styles.figure}>
              <div className={styles.stars}>
                <StarFilled width={30} aria-hidden="true" className={styles.star} />
                <StarFilled aria-hidden="true" className={styles.star} />
                <StarFilled aria-hidden="true" className={styles.star} />
                <StarFilled aria-hidden="true" className={styles.star} />
                <StarFilled aria-hidden="true" className={styles.star} />
              </div>
              <blockquote className={styles.quote}>
                <p>“{t(item.title)}”</p>
              </blockquote>
              <figcaption className={styles.figcaption}>
                <img alt="" src={item.image} className={styles.avatar} />
                <div>
                  <div className={styles.author}>{item.author}</div>
                  <a
                    href={item.authorLink}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.role}
                  >
                    {t(item.authorRole, { 0: item.authorCompany })}
                  </a>
                </div>
              </figcaption>
            </figure>
          </section>
        ))}

        <AuthHeroLogos />
      </div>
    </div>
  );
};

export const AuthHeroTitles = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.titles}>
      <Typography.Title level={1} className={styles.title}>
        {t("sign-up-title")} <br className={styles.br} />
        <span className={styles.subtitle}>{t("sign-up-subtitle")}</span>
      </Typography.Title>
    </div>
  );
};

export const AuthHeroLogos = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.logos}>
      <div className={clsx(styles.logosTitle, styles.desktop)}>{t("sign-up-logo-cloud-title")}</div>
      <div className={clsx(styles.logosTitle, styles.mobile)}>
        {t("sign-up-logo-cloud-title-mobile")}
      </div>
      <div className={styles.logosList}>
        {logos.map((logo) => (
          <img src={logo} alt="" key={logo} className={styles.logo} />
        ))}
      </div>
    </div>
  );
};
