import { Divider, Typography } from "antd";

import styles from "./AuthHeader.module.scss";

const { Title, Paragraph } = Typography;

type AuthHeaderProps = {
  title: string;
  description?: string;
};

export const AuthHeader = ({ title, description }: AuthHeaderProps) => {
  return (
    <div className={styles.root}>
      <Title className={styles.title} level={3}>
        {title}
      </Title>
      {!!description && <Paragraph>{description}</Paragraph>}
      <Divider className={styles.divider} />
    </div>
  );
};
