import { PaginationResponseDef } from "@app/types/pagination.types";

export enum EActivityLogType {
  SUPERCHAT = "superchat",
  NOTE = "note",
  SIPGATE = "sipgate",
}

export enum EActivityLogDirection {
  IN = "in",
  OUT = "out",
}

export type ActivityLogDef = {
  id?: string;
  content: IActivityLogContentNote | IActivityLogContentSipgate | IActivityLogContentSuperchat;
  direction?: EActivityLogDirection;
  type: EActivityLogType;
  workerProfileId: string;
  createdAt?: string;
  superAdmin?: {
    firstName: string;
    lastName: string;
  };
};

export type IActivityLogContentNote = {
  message: string;
};

// Sipgate
export enum ESipgateEventType {
  NEW_CALL = "newCall",
  ANSWER = "answer",
  HANGUP = "hangup",
  DATA = "dtmf",
}
export type IActivityLogContentSipgate = {
  event: ESipgateEventType;
};

// Superchat
export enum ESuperchatEventType {
  MESSAGE_INBOUND = "message_inbound",
  MESSAGE_OUTBOUND = "message_outbound",
  MESSAGE_FAILED = "message_failed",
}
export enum ESuperchatMessageContentType {
  TEXT = "text",
  EMAIL = "email",
  WHATS_APP_TEMPLATE = "whats_app_template",
  GENERIC_TEMPLATE = "generic_template",
}
export type IActivityLogContentSuperchat = {
  event: ESuperchatEventType;
  message: {
    id: string;
    url: string;
    content: {
      body: string;
      type: ESuperchatMessageContentType;
    };
    in_reply_to: string | null;
  };
};

export type ActivityLogsWithPaginationDef = PaginationResponseDef<ActivityLogDef>;
