import axios, { AxiosRequestConfig } from "axios";
import { ENV } from "@app/constants/env";

export const apiConfig: AxiosRequestConfig = {
  baseURL: ENV.API_URL,
  headers: {
    registerapp: "crafthunt",
  },
};

export const api = axios.create(apiConfig);
