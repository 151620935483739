import { Button, Divider, RefSelectProps, Select, SelectProps, Spin } from "antd";
import _debounce from "lodash/debounce";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import {
  createCompanySA,
  getCompaniesSA,
} from "@app/features/super-admin/api/super-admin.company.api";

export type SelectOptionDef = { key?: string; label: React.ReactNode; value: string };

type CompanySearchProps = Omit<SelectProps, "options" | "children">;

export const CompanySearch = ({ onChange, ...props }: CompanySearchProps) => {
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<SelectOptionDef[]>([]);
  const [companySearch, setCompanySearch] = useState("");
  const fetchRef = useRef(0);
  const inputRef = useRef<RefSelectProps>(null);
  const [open, setOpen] = useState(false);
  const [loadingAddCompany, setLoadingAddCompany] = useState(false);

  const handleCreateCompany = async (companySearch: string) => {
    try {
      setLoadingAddCompany(true);
      const response = await createCompanySA({ name: companySearch });
      const newValue = {
        key: response.data.id,
        value: response.data.id,
        label: response.data.name,
      };
      onChange?.(newValue, newValue);
      setOpen(false);
    } finally {
      setLoadingAddCompany(false);
    }
  };

  const loadOptions = _debounce((value: string) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setFetching(true);

    getCompaniesSA({ keyword: value, limit: 10, offset: 0 }).then((response) => {
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return;
      }

      const newOptions = response.data.data.map(
        (item) =>
          ({
            key: item.id,
            value: item.id,
            label: item.name,
          } as SelectOptionDef)
      );

      setOptions(newOptions);
      setFetching(false);
    });
  }, 300);

  const debounceFetcher = (searchValue: string) => {
    setCompanySearch(searchValue);
    loadOptions(searchValue);
  };

  return (
    <Select
      showSearch
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : <div>{t("No match")}</div>}
      onChange={onChange}
      {...props}
      options={options}
      loading={fetching}
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      size="large"
      dropdownRender={(menu) => (
        <>
          {loadingAddCompany && <LoadingSpinner />}
          {companySearch && (
            <>
              <Button
                style={{ marginLeft: 8 }}
                onClick={() => handleCreateCompany(companySearch)}
                size="middle"
              >
                {t("Add")}: {companySearch}
              </Button>
              <Divider style={{ margin: "8px 0" }} />
            </>
          )}
          {menu}
        </>
      )}
      ref={inputRef}
    />
  );
};
