import { CaretDownFilled, CaretRightFilled } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Button, Checkbox, Space, Tag, Typography } from "antd";
import { useEffect } from "react";
import {
  createCompletedTaskSA,
  deleteCompletedTaskSA,
} from "@app/features/super-admin/api/super-admin.task.api";
import { CompletedTask, Task } from "@app/features/super-admin/types/super-admin.task.types";

const TaskItem = ({
  userProgressId,
  candidateProgressId,
  task,
  completedTask,
  disabled,
  onSettled,
}: {
  userProgressId: string;
  candidateProgressId?: string;
  task: Task;
  completedTask?: CompletedTask;
  disabled?: boolean;
  onSettled: () => void;
}) => {
  const createCompletedTask = useMutation({
    mutationFn: createCompletedTaskSA,
    onSettled,
  });

  const deleteCompletedTask = useMutation({
    mutationFn: deleteCompletedTaskSA,
    onSettled,
  });

  return (
    <Checkbox
      key={task.id}
      disabled={disabled || deleteCompletedTask.isPending || createCompletedTask.isPending}
      checked={Boolean(completedTask)}
      onChange={() => {
        if (deleteCompletedTask.isPending || createCompletedTask.isPending) {
          return;
        }
        if (completedTask) {
          deleteCompletedTask.mutate({
            completedTaskId: completedTask.id,
          });
        } else {
          createCompletedTask.mutate({
            taskId: task.id,
            workerProfileProgressId: userProgressId,
            candidateProgressId: task.type === "candidate" ? candidateProgressId : undefined,
          });
        }
      }}
    >
      {task.name}
    </Checkbox>
  );
};

export const TaskGroup = ({
  group: group,
  userProgressId,
  candidateProgressId,
  isExpanded: propsIsExpanded,
  setExpanded,
  invalidateQueries,
  disabled,
}: {
  group: {
    goal: Task["goal"];
    type: Task["type"];
    name: string;
    order: number;
    status: "active" | "inactive";
    isCompleted: boolean;
    tasks: (Task & {
      completedTask?: CompletedTask;
    })[];
  };
  userProgressId: string;
  candidateProgressId?: string;
  isExpanded: boolean;
  setExpanded: (expanded: boolean) => void;
  invalidateQueries: () => void;
  disabled: boolean;
}) => {
  const isExpanded = propsIsExpanded;

  const toggleExpanded = () => {
    setExpanded(!isExpanded);
  };

  useEffect(() => {
    if (group.status === "active" && !group.isCompleted) {
      setExpanded(true);
    }
  }, []);

  return (
    <Space direction="vertical">
      <Space>
        <Button
          size="small"
          type="text"
          icon={
            isExpanded ? (
              <CaretDownFilled style={{ fontSize: 16 }} />
            ) : (
              <CaretRightFilled style={{ fontSize: 16 }} />
            )
          }
          onClick={toggleExpanded}
        />
        <Typography.Text
          style={{
            textTransform: "capitalize",
            cursor: "pointer",
            fontWeight: group.status === "active" ? 600 : 500,
            color: group.status == "active" ? undefined : "rgba(0, 0, 0, 0.75)",
          }}
          onClick={toggleExpanded}
        >
          {group.name}
        </Typography.Text>
        {group.status === "active" && (
          <>
            {group.isCompleted ? (
              <Tag color="success">Completed</Tag>
            ) : (
              <Tag color="green">Active goal</Tag>
            )}
          </>
        )}
        {group.status === "inactive" && <Tag>Inactive goal</Tag>}
      </Space>
      {isExpanded && (
        <Space
          direction="vertical"
          size="small"
          style={{
            paddingLeft: "16px",
          }}
        >
          {group.tasks.map((task) => {
            return (
              <TaskItem
                key={task.id}
                disabled={disabled || group.status === "inactive"}
                userProgressId={userProgressId}
                candidateProgressId={candidateProgressId}
                task={task}
                completedTask={task.completedTask}
                onSettled={invalidateQueries}
              />
            );
          })}
        </Space>
      )}
    </Space>
  );
};
