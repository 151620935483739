import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, createSearchParams, useNavigate } from "react-router-dom";
import { FullPageSpinner } from "@app/components/ui/FullPageSpinner/FullPageSpinner";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { Dispatch, RootState } from "@app/store/store";

type ProtectedRouteProps = {
  children: ReactNode;
};

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const parsedToken = useSelector((state: RootState) => state.auth.parsedToken);
  const loggedIn = useSelector((state: RootState) => state.auth.loggedIn);
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const currentPath = window.location.pathname + window.location.search;

  const logout = () => {
    dispatch.auth.logout();
    navigate(
      {
        pathname: RoutePaths.LOGIN,
        search: createSearchParams({
          from: currentPath,
        }).toString(),
      },
      { replace: true }
    );
  };

  useEffect(() => {
    const refreshToken = async () => {
      try {
        await dispatch.auth.refreshToken(token as string);
      } catch {
        logout();
      }
    };
    if (token && parsedToken) {
      if (!parsedToken.isSuperAdmin && !parsedToken.isInternal) {
        refreshToken();
      } else {
        dispatch.auth.setTokenData(token);
      }
    }
  }, []);

  if (!token) {
    return (
      <Navigate
        to={{
          pathname: RoutePaths.LOGIN,
          search: createSearchParams({ from: currentPath }).toString(),
        }}
        replace
      />
    );
  }

  if (!loggedIn) {
    return <FullPageSpinner />;
  }

  return <>{children}</>;
};
