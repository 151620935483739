import { App } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ModelNames } from "@app/store/models/models";
import { RootState } from "@app/store/store";

export const useLoadingPopup = (key: ModelNames) => {
  const { message } = App.useApp();
  const loading = useSelector((state: RootState) => state.loading.models[key]);
  const [hasMessage, setHasMessage] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (loading) {
      message.loading({ content: t("Saving..."), key });
      setHasMessage(true);
    } else if (hasMessage) {
      message.success({ content: t("Saved!"), key, duration: 1 });
      setHasMessage(false);
    }
  }, [loading]);

  return loading;
};
