import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DesktopOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Alert, Badge, Grid, Layout, Menu, Space, Tag, Tooltip } from "antd";
import { ItemType } from "antd/es/menu/interface";
import Sider from "antd/lib/layout/Sider";
import { Content } from "antd/lib/layout/layout";
import { MenuProps } from "antd/lib/menu";
import clsx from "clsx";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { MdAdminPanelSettings } from "react-icons/md";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { Logo } from "@app/components/ui/Logo/Logo";
import { Colors } from "@app/constants/colors.constants";
import { ProtectedRoute } from "@app/features/routes/components/ProtectedRoute/ProtectedRoute";
import { ALL_ROUTES } from "@app/features/routes/constants/routes.constants";
import { useCurrentRoute } from "@app/features/routes/hooks/useCurrentRoute";
import { RouteDef, RoutePaths } from "@app/features/routes/types/routes.types";
import SuperAdminPasswordModal from "@app/features/super-admin/components/SuperAdminPasswordModal/SuperAdminPasswordModal";
import { useAccountSetupProgress } from "@app/hooks/useAccountSetupProgress";
import { useLiveURL } from "@app/hooks/useLiveUrl";
import { RootState } from "@app/store/store";
import { ApprovalStatusEnum } from "@app/types/company-info.types";
import styles from "./MainLayout.module.scss";
import { MenuItem } from "./components/MenuItem/MenuItem";

const { useBreakpoint } = Grid;

export const MainLayout = () => {
  const { t, i18n } = useTranslation();
  const liveURL = useLiveURL();
  const navigate = useNavigate();
  const location = useLocation();
  const isSuperAdmin = useSelector((state: RootState) => state.auth.parsedToken?.isSuperAdmin);
  const isInternal = useSelector((state: RootState) => state.auth.parsedToken?.isInternal);
  const superToken = useSelector((state: RootState) => state.superAdmin.superToken);
  const companyName = useSelector((state: RootState) => state.companyInfo.companyInfo.name);
  const currentRoute = useCurrentRoute();
  const { approvalStatus, steps } = useAccountSetupProgress();
  const [superAdminModalOpen, setSuperAdminModalOpen] = useState(false);
  const screens = useBreakpoint();
  const isSmallerScreen = "lg" in screens && !screens.lg;

  const [isMenuCollapsed, setIsMenuCollapsed] = useLocalStorage("sa-isMenuCollapsed", false);

  const shouldCollapseMenu = isSmallerScreen || (Boolean(superToken) && isMenuCollapsed);

  const parentPath = location.pathname.substring(0, location.pathname.lastIndexOf("/"));
  const parentKey = parentPath !== RoutePaths.DASHBOARD ? parentPath : null;
  const selectedKeys = [location.pathname, parentKey].filter(Boolean) as string[];
  const openKeys = [currentRoute.parentKey].filter(Boolean) as string[];

  const goTo: MenuProps["onClick"] = (e) => {
    if (e?.key === RoutePaths.SUPER_ADMIN_STUFF && !superToken) {
      setSuperAdminModalOpen(true);
    } else if (e && e.key !== "live-url" && e.key !== "collapse-menu") {
      navigate(e.key);
    }
  };

  const getCompanyInfoStatusIcon = (item: RouteDef, index: number) => {
    if (approvalStatus === ApprovalStatusEnum.NOT_STARTED) {
      switch (item.key) {
        case RoutePaths.COMPANY_INFO:
          return <MenuItem step={index} isDone={steps.companyInfo} />;
        case RoutePaths.COMPANY_TRADES:
          return <MenuItem step={index} isDone={steps.trades} />;
        case RoutePaths.COMPANY_CULTURE:
          return <MenuItem step={index} isDone={steps.companyCulture} />;
        case RoutePaths.KEY_DATA:
          return <MenuItem step={index} isDone={steps.keyData} />;
        case RoutePaths.CONSTRUCTION_PROJECTS:
          return <MenuItem step={index} isDone={steps.projects} />;
        case RoutePaths.EQUIPMENT_AND_MACHINERY:
          return <MenuItem step={index} isDone={steps.machines} />;
        case RoutePaths.EMPLOYEE_BENEFITS:
          return <MenuItem step={index} isDone={steps.employeeBenefits} />;
      }
    }
    return "icon" in item && item.icon;
  };

  const getSubmitForReviewStatusIcon = () => {
    switch (approvalStatus) {
      case ApprovalStatusEnum.APPROVED:
        return <CheckCircleOutlined style={{ color: "green" }} />;
      case ApprovalStatusEnum.PENDING:
        return <QuestionCircleOutlined style={{ color: "yellow" }} />;
      case ApprovalStatusEnum.REJECTED:
        return <CloseCircleOutlined style={{ color: "red" }} />;
      case ApprovalStatusEnum.NOT_STARTED:
      default:
        return <ClockCircleOutlined />;
    }
  };

  const items = useMemo(
    () =>
      ALL_ROUTES.filter((item) => !item.hideInMenu)
        .filter((item) => {
          if (item.hasFeatureFlag) {
            switch (item.key) {
              case RoutePaths.SUPER_ADMIN_STUFF:
                return isSuperAdmin || superToken;
              default:
                return false;
            }
          } else {
            return true;
          }
        })
        .map((item) => {
          const label = typeof item.label === "string" ? t(item.label) : item.label;
          let icon = "icon" in item && item.icon;

          if (item.key === RoutePaths.SUBMIT_FOR_REVIEW) {
            icon = getSubmitForReviewStatusIcon();
          }

          const approvalCount =
            (approvalStatus !== ApprovalStatusEnum.APPROVED &&
              Object.values(steps).reduce((result, item) => {
                if (!item) {
                  return result + 1;
                }
                return result;
              }, 0)) ||
            0;

          return {
            icon,
            key: item.key,
            label: item.isNew ? (
              <Space size="small">
                <span>{label}</span>
                <Tag color="orange">{t("New")}</Tag>
              </Space>
            ) : (
              label
            ),
            ...(approvalCount &&
              item.children?.length && {
                expandIcon: <Badge count={approvalCount} color={Colors.primary} />,
              }),
            children: item.children?.map((child, childIndex) => ({
              key: child.key,
              label: typeof child.label === "string" ? t(child.label) : child.label,
              type: "type" in child && child.type,
              icon: getCompanyInfoStatusIcon(child, childIndex),
            })),
          };
        }),
    [i18n.language, approvalStatus, steps, isSuperAdmin, superToken]
  );

  const mainMenuItems: ItemType[] = [
    ...items,
    ...(superToken && !isSmallerScreen
      ? [
          {
            key: "collapse-menu",
            label: shouldCollapseMenu ? "Expand menu" : "Collapse menu",
            icon: shouldCollapseMenu ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />,
            onClick: () => setIsMenuCollapsed(!shouldCollapseMenu),
          },
        ]
      : []),
    {
      type: "divider",
    },
    {
      key: "live-url",
      label: (
        <a href={liveURL} target="_blank" rel="noreferrer">
          {t("Link to your Crafthunt profile")}
        </a>
      ),
      icon: <DesktopOutlined />,
      style: {
        cursor: "pointer",
        color: "lightblue",
        textDecoration: "underline",
      },
    },
    {
      type: "divider",
    },
  ];

  return (
    <ProtectedRoute>
      <Layout hasSider>
        <Helmet>
          <title>{typeof currentRoute.label === "string" && t(currentRoute.label)}</title>
          {currentRoute.description && (
            <meta name="description" content={t(currentRoute.description)} />
          )}
        </Helmet>
        <Sider
          collapsible
          collapsed={shouldCollapseMenu}
          trigger={null}
          className={clsx(styles.sider, {
            [styles.collapsed]: shouldCollapseMenu,
          })}
          onCollapse={(collapsed) => setIsMenuCollapsed(collapsed)}
        >
          {superToken &&
            isInternal &&
            (shouldCollapseMenu ? (
              <Tooltip title={`Super admin logged in as: ${companyName}`}>
                <div className={styles.superAdmin}>
                  <MdAdminPanelSettings color="white" size={24} />
                </div>
              </Tooltip>
            ) : (
              <div style={{ padding: 10 }}>
                <Alert
                  message={`Super admin logged in as: ${companyName}`}
                  type="success"
                  closable
                />
              </div>
            ))}
          <div className={clsx(styles.logo)}>
            <Logo width={215} iconOnly={shouldCollapseMenu} />
          </div>
          <div className={styles.menu}>
            <Menu
              theme="dark"
              mode="inline"
              defaultOpenKeys={openKeys}
              selectedKeys={selectedKeys}
              inlineCollapsed={shouldCollapseMenu}
              items={mainMenuItems}
              onClick={goTo}
            />
          </div>
        </Sider>
        <Layout className={styles.contentLayout}>
          <Content
            className={clsx(styles.content, {
              [styles.collapsed]: shouldCollapseMenu,
              [styles.noPadding]: currentRoute.fullHeight,
            })}
          >
            <Outlet />
          </Content>
        </Layout>
        <SuperAdminPasswordModal open={superAdminModalOpen} setOpen={setSuperAdminModalOpen} />
      </Layout>
    </ProtectedRoute>
  );
};
