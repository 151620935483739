import "./sentry";
import { ErrorBoundary } from "@sentry/react";
import { Button, Result } from "antd";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { NuqsAdapter } from "nuqs/adapters/react-router";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { firebaseConfig } from "./firebaseConfig";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";

const app = initializeApp(firebaseConfig);
getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <ErrorBoundary
    fallback={
      <Result
        status="warning"
        title="We're sorry, something went wrong"
        subTitle="Our team is already notified. You can try to reload the page."
        extra={
          <Button type="primary" onClick={() => window.location.reload()}>
            Reload the page
          </Button>
        }
      />
    }
  >
    <NuqsAdapter>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </NuqsAdapter>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
