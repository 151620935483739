import { Col, Form, Row, App } from "antd";
import { Rule } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { FormModal } from "@app/components/ui/FormModal/FormModal";
import { UploaderFiles } from "@app/components/ui/UploaderFiles/UploaderFiles";
import { postUserHighlightSA } from "@app/features/super-admin/api/super-admin.user.api";
import { HighlightFormDef } from "@app/features/super-admin/types/super-admin.user.types";
import { ApiFormErrorDef, getErrorMessages } from "@app/helpers/error.helper";

type HighlightModalProps = {
  open: boolean;
  workerProfileId: string;
  onSave: () => void;
  onCancel: () => void;
};

export const HighlightModal = ({
  open,
  workerProfileId,
  onSave,
  onCancel,
}: HighlightModalProps) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [form] = useForm<HighlightFormDef>();

  const requiredRule: Rule = { required: true, whitespace: true, message: t("required") };

  const onSubmitForm = async (values: HighlightFormDef) => {
    try {
      await Promise.all(
        values.images.map((image) => {
          if (!image.url) {
            console.error("Missing file");
            return;
          }
          return postUserHighlightSA(workerProfileId, { url: image.url }).catch();
        })
      );

      message.success({ content: t("Saved!"), key: "user-highlights" });
      onSave();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error({
          content: getErrorMessages(error as ApiFormErrorDef),
          key: "user-highlights",
          duration: 10,
        });
      }
    }
  };

  return (
    <FormModal
      title={t("Add")}
      open={open}
      onFinish={onSubmitForm}
      onCancel={onCancel}
      form={form}
      id="highlight-form"
    >
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item
            rules={[
              {
                ...requiredRule,
                type: "array" as const,
              },
            ]}
            label={t("Image")}
            name="images"
          >
            <UploaderFiles
              initialFiles={[]}
              onChange={(files) => {
                form.setFieldValue("images", files);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </FormModal>
  );
};
