import axios from "axios";
import { setupCache } from "axios-cache-interceptor";
import qs from "qs";
import { apiConfig } from "@app/api/api";

export const superAdminApi = axios.create(apiConfig);
export const superAdminApiCached = setupCache(axios.create(apiConfig));

superAdminApi.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: "brackets" });
};

export const getCached = <T>(
  id: string,
  path: string,
  params?: Record<string, string | number>
) => {
  return superAdminApiCached.get<T>(path, {
    id,
    params,
  });
};
export const postCached = <T>(id: string, path: string, data: Record<string, unknown>) => {
  return superAdminApiCached.post<T>(path, data, {
    cache: {
      update: {
        [id]: "delete",
      },
    },
  });
};
export const putCached = <T>(id: string, path: string, data: Record<string, unknown>) => {
  return superAdminApiCached.put<T>(path, data, {
    cache: {
      update: {
        [id]: "delete",
      },
    },
  });
};
export const deleteCached = <T>(id: string, path: string, params?: Record<string, string>) => {
  return superAdminApiCached.delete<T>(path, {
    params,
    cache: {
      update: {
        [id]: "delete",
      },
    },
  });
};
