import classNames from "classnames";
import { CSSProperties, ReactNode, forwardRef } from "react";
import styles from "./Container.module.scss";

export interface ContainerProps {
  children?: ReactNode;
  columns?: number;
  label?: string | ReactNode;
  style?: CSSProperties;
  horizontal?: boolean;
  hover?: boolean;
  scrollable?: boolean;
  shadow?: boolean;
  placeholder?: boolean;
  onClick?(): void;
  onRemove?(): void;
  noUlWrapper?: boolean;
}

export const Container = forwardRef<HTMLDivElement, ContainerProps>(function Container(
  {
    children,
    columns = 1,
    horizontal,
    hover,
    onClick,
    label,
    placeholder,
    style,
    scrollable,
    shadow,
    noUlWrapper,
    ...props
  }: ContainerProps,
  ref
) {
  const Component = onClick ? "button" : "div";

  return (
    <Component
      {...props}
      ref={ref as never}
      style={
        {
          ...style,
          "--columns": columns,
        } as CSSProperties
      }
      className={classNames(
        styles.Container,
        horizontal && styles.horizontal,
        hover && styles.hover,
        placeholder && styles.placeholder,
        scrollable && styles.scrollable,
        shadow && styles.shadow
      )}
      onClick={onClick}
      tabIndex={onClick ? 0 : undefined}
    >
      {label ? <div className={styles.Header}>{label}</div> : null}
      {placeholder || noUlWrapper ? children : <ul>{children}</ul>}
    </Component>
  );
});
