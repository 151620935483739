import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import React, { useEffect, useState } from "react";
import { Item } from "@app/features/kanban/components/Kanban/components/Item/Item";

export interface SortableItemProps {
  containerId: UniqueIdentifier;
  id: UniqueIdentifier;
  index: number;
  disabled?: boolean;
  style(args: {
    value?: UniqueIdentifier;
    index?: number;
    overIndex?: number;
    isDragging?: boolean;
    containerId?: UniqueIdentifier;
    isSorting?: boolean;
    isDragOverlay?: boolean;
  }): React.CSSProperties;
  getIndex(id: UniqueIdentifier): number;
  renderItem?(value: UniqueIdentifier): React.ReactNode;
  wrapperStyle({ index }: { index: number }): React.CSSProperties;
}

export function SortableItem({
  disabled,
  id,
  index,
  renderItem,
  style,
  containerId,
  getIndex,
  wrapperStyle,
}: SortableItemProps) {
  const { setNodeRef, listeners, isDragging, isSorting, over, overIndex, transform, transition } =
    useSortable({
      id,
    });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      value={id}
      dragging={isDragging}
      sorting={isSorting}
      index={index}
      wrapperStyle={wrapperStyle({ index })}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId,
      })}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={renderItem}
    />
  );
}

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}
