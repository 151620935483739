import { Tag } from "antd";
import {
  ActivityLogDef,
  IActivityLogContentSuperchat,
} from "@app/features/super-admin/types/super-admin.activity-log.types";
import { ActivityLogCard } from "./ActivityLogCard";

export const ActivityLogSuperchat = ({ activityLog }: { activityLog: ActivityLogDef }) => {
  const data = activityLog.content as IActivityLogContentSuperchat;

  return (
    <ActivityLogCard
      title={"Message"}
      date={activityLog.createdAt}
      superAdminName={activityLog.superAdmin?.firstName}
      direction={activityLog.direction}
      type={activityLog.type}
    >
      {data.message.content.body}
      <div>
        <Tag>{data.message.content.type}</Tag>
      </div>
    </ActivityLogCard>
  );
};
