import daLocale from "antd/es/locale/da_DK";
import deLocale from "antd/es/locale/de_DE";
import enLocale from "antd/es/locale/en_GB";
import esLocale from "antd/es/locale/es_ES";
import frLocale from "antd/es/locale/fr_FR";
import itLocale from "antd/es/locale/it_IT";
import plLocale from "antd/es/locale/pl_PL";
import roLocale from "antd/es/locale/ro_RO";
import moment from "moment-timezone";
import "moment/locale/da";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/pl";
import "moment/locale/ro";

export const getMomentLocale = (locale: string) => {
  moment.locale(locale);
  switch (locale) {
    case "da":
      return daLocale;
    case "de":
      return deLocale;
    case "es":
      return esLocale;
    case "it":
      return itLocale;
    case "fr":
      return frLocale;
    case "ro":
      return roLocale;
    case "pl":
      return plLocale;
    case "en":
    default:
      moment.locale("en_GB");
      return enLocale;
  }
};
