import { EventProperties } from "@segment/analytics-next";
import posthog from "posthog-js";
import { isProd } from "@app/constants/env";

const UTM_PARAMS = [
  "utm_campaign",
  "utm_medium",
  "utm_source",
  "utm_content",
  "utm_term",
  "fbclid",
];

export const getAllUTMs = () => {
  let searchQuery = decodeURIComponent(window.location.search.substring(1));
  if (!searchQuery) {
    // in case the params are not found, then try manually
    const splitUrl = window.location.href.split("?");
    if (splitUrl.length > 1) {
      searchQuery = decodeURIComponent(splitUrl[1]);
    }
  }
  const urlParams = searchQuery.split("&").map((x) => {
    const [key, value] = x.split("=");
    return { key, value };
  });
  const utmParams: Record<string, string> = {};
  for (const urlParam of urlParams) {
    if (UTM_PARAMS.includes(urlParam.key)) {
      utmParams[urlParam.key] = urlParam.value;
    }
  }
  return utmParams;
};

export const triggerAnalyticsEvent = (event: string, data?: EventProperties) => {
  const utmParams = getAllUTMs();
  const analyticsData = {
    ...data,
    ...utmParams,
  };
  // Segment
  window.analytics?.track(event, analyticsData);

  // Google Tag Manager
  window.dataLayer?.push({
    event,
    eventProps: analyticsData,
  });
};

export const triggerIdentify = (
  userId: string,
  data: {
    email?: string | null;
    phone?: string | null;
    companyName?: string;
  }
) => {
  if (!isProd()) {
    return;
  }
  const utmParams = getAllUTMs();
  const analyticsData = {
    ...data,
    ...utmParams,
  };
  window.analytics?.identify(userId, analyticsData);
  posthog?.identify(userId, analyticsData);
};

export const disableAnalytics = () => {
  if (!isProd()) {
    return;
  }
  if (window.analytics) {
    window.analytics.reset();
    (window.analytics as unknown) = null;
  }
  if (posthog) {
    posthog.opt_out_capturing();
    posthog.stopSessionRecording();
  }
};

export const resetAnalytics = () => {
  if (!isProd()) {
    return;
  }
  posthog?.reset();
  window.analytics?.reset();
};
