import { Button } from "antd";
import moment from "moment";
import { IconType } from "react-icons";
import { MdDownload } from "react-icons/md";
import { Icon } from "@app/components/ui/Icon/Icon";
import { DateFormats } from "@app/constants/date.constants";
import { ProfileDocumentDef } from "@app/types/candidate.types";
import styles from "./DocumentItem.module.scss";

type DocumentItemProps = {
  onDownload: () => void;
  doc: ProfileDocumentDef;
  icon: IconType;
};

export const DocumentItem = ({ onDownload, doc, icon }: DocumentItemProps) => {
  return (
    <div className={styles.listItem}>
      <div className={styles.listItemInfo}>
        <Icon icon={icon} size={24} />
        <div className={styles.listItemText}>
          <a href={doc.url} target="_blank" className={styles.listItemLink} rel="noreferrer">
            {doc.filename}
          </a>
          <div className={styles.listItemDate}>
            {moment(doc.createdAt).format(DateFormats.FULL_DATE_TIME)}
          </div>
        </div>
      </div>
      <Button
        onClick={onDownload}
        className={styles.listItemDownload}
        icon={<Icon icon={MdDownload} size={18} />}
        type="text"
      />
    </div>
  );
};
