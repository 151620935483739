import { Divider, Row, Typography } from "antd";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styles from "./JobAdFormSection.module.scss";

const { Text } = Typography;

type JobAdFormSectionProps = {
  title: string;
  children: ReactNode;
};

export const JobAdFormSection = ({ title, children }: JobAdFormSectionProps) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, { xs: 2, sm: 4, md: 6, lg: 8 }]}>
      <Text className={styles.title} type="secondary">
        {t(title)}
      </Text>
      {children}
      <Divider className={styles.divider} />
    </Row>
  );
};
