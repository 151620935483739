import {
  CalendarOutlined,
  EuroCircleOutlined,
  FieldTimeOutlined,
  NodeIndexOutlined,
  QuestionCircleFilled,
  ScheduleOutlined,
  TeamOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { App, Col, Row, Typography } from "antd";
import { CSSProperties, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RemovableCardForm } from "@app/components/ui/RemovableCardForm/RemovableCardForm";
import {
  RemovableCardFormInputOptions,
  RemovableCardFormInputTypes,
} from "@app/components/ui/RemovableCardForm/RemovableCardForm.types";
import { Colors } from "@app/constants/colors.constants";
import { initialKeyCompanyData } from "@app/constants/initial-key-data.constants";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { useLoadingPopup } from "@app/hooks/useLoadingPopup";
import { ModelNames } from "@app/store/models/models";
import { RootState, store } from "@app/store/store";
import {
  KeyCompanyDataForm,
  KeyDataType,
  PostKeyCompanyData,
  UpdateKeyCompanyData,
} from "@app/types/company-key-data.types";
import { CardLayout } from "@layouts/CardLayout/CardLayout";
import { ContentLayout } from "@layouts/ContentLayout/ContentLayout";

export const MANDATORY_KEY_DATA = [KeyDataType.Employees];

export const KeyData = () => {
  const { message } = App.useApp();
  const keyData = useSelector((state: RootState) => state.companyData.keyData);
  const [innerKeyData, setInnerKeyData] = useState<KeyCompanyDataForm[]>(initialKeyCompanyData);
  useLoadingPopup(ModelNames.COMPANY_DATA);
  const [isSaving, setIsSaving] = useState(false);
  const { dispatch } = store;
  const { t } = useTranslation();

  // merging existing key data with initialKeyCompanyData(dummy data with temp id and no value).
  const checkKeyDataExist = () => {
    const initArray = [...initialKeyCompanyData];
    keyData.forEach((saved) => {
      initArray.some((v, i) => {
        if (saved.name === v.name) {
          initArray[i] = saved;
          return true;
        }
        return false;
      });
    });
    setInnerKeyData(initArray);
  };

  useEffect(() => {
    if (keyData?.length) {
      checkKeyDataExist();
    }
  }, [keyData]);

  const options: (item: KeyDataType) => RemovableCardFormInputOptions<KeyCompanyDataForm>[] = (
    item
  ) => {
    const optionsList: RemovableCardFormInputOptions<KeyCompanyDataForm>[] = [];
    const required = MANDATORY_KEY_DATA.includes(item);

    if (item === KeyDataType.AverageProjectTime) {
      optionsList.push({
        inputType: RemovableCardFormInputTypes.select,
        required,
        description: t(item),
        placeholder: "Select",
        value: "value",
        hideLabel: true,
        sortAlphabetically: false,
        choices: [
          {
            text: `1-7 ${t("days")}`,
            value: 1,
          },
          {
            text: `1-4 ${t("weeks")}`,
            value: 15,
          },
          {
            text: `1-12 ${t("months")}`,
            value: 180,
          },
          {
            text: `1-2 ${t("years")}`,
            value: 360,
          },
          {
            text: `2-3 ${t("years")}`,
            value: 720,
          },
          {
            text: t("More than 3 years"),
            value: 1080,
          },
        ],
      });
    } else {
      optionsList.push({
        inputType: RemovableCardFormInputTypes.numberTextField,
        required,
        description: t(item),
        placeholder: "Enter...",
        min: 1,
        value: "value",
        hideLabel: true,
      });
    }

    return [...optionsList];
  };

  const getIcon = (i: KeyDataType) => {
    const sharedStyle: CSSProperties = { fontSize: "20pt", color: Colors.primary };
    switch (i) {
      case KeyDataType.Employees:
        return <TeamOutlined style={sharedStyle} />;
      case KeyDataType.EmployeeAge:
        return <CalendarOutlined style={sharedStyle} />;
      case KeyDataType.AverageProjectTime:
        return <FieldTimeOutlined style={sharedStyle} />;
      case KeyDataType.AverageDistanceToConstructionProjects:
        return <NodeIndexOutlined style={sharedStyle} />;
      case KeyDataType.CompletedConstructionProjects:
        return <ScheduleOutlined style={sharedStyle} />;
      case KeyDataType.ActiveConstructionProjects:
        return <ToolOutlined style={sharedStyle} />;
      case KeyDataType.AnnualConstructionVolume:
        return <EuroCircleOutlined style={sharedStyle} />;
      default:
        return <QuestionCircleFilled style={sharedStyle} />;
    }
  };

  const saveKeyData = async (keyData: KeyCompanyDataForm) => {
    if (keyData.value === 0 || keyData.value === null) {
      return removeKeyData(keyData);
    }
    const isUnsaved = "isTemp" in keyData;
    try {
      setIsSaving(true);
      if (isUnsaved) {
        await dispatch.companyData.addKeyDataAction(keyData as PostKeyCompanyData);
      } else {
        await dispatch.companyData.updateKeyDataAction(keyData as UpdateKeyCompanyData);
      }
    } catch {
      message.error({ content: t("Sorry, something went wrong."), key: ModelNames.COMPANY_DATA });
    } finally {
      setIsSaving(false);
    }
  };

  const removeKeyData = async (keyData: KeyCompanyDataForm) => {
    const isUnsaved = "isTemp" in keyData;
    if (isUnsaved) {
      return;
    }
    try {
      setIsSaving(true);
      await dispatch.companyData.removeKeyDataAction(keyData.id);
    } catch {
      message.error({ content: t("Sorry, something went wrong."), key: ModelNames.COMPANY_DATA });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <ContentLayout
      title={t("Key data about the company")}
      description={t(
        "Fill in this section so that you can address the appropriate employees. The data can also be filtered from employees and here it is also important that you confirm your details to create trust."
      )}
      nextRoute={RoutePaths.CONSTRUCTION_PROJECTS}
    >
      <CardLayout
        loading={isSaving}
        data={innerKeyData}
        renderData={(item) => (
          <RemovableCardForm<KeyCompanyDataForm>
            key={item.name}
            initialValues={item}
            options={options(item.name)}
            onSave={saveKeyData}
            onRemove={removeKeyData}
            noRemoveButton={MANDATORY_KEY_DATA.includes(item.name)}
            title={
              <Row align="middle" justify="start" style={{ minHeight: 50 }}>
                <Col xs={4}>{getIcon(item.name)}</Col>
                <Col xs={20} style={{ paddingLeft: "4px", whiteSpace: "normal" }}>
                  <Typography.Text>
                    {t(item.name)}
                    <Typography.Text type="secondary" italic>
                      {!MANDATORY_KEY_DATA.includes(item.name) && ` (${t("Optional")})`}
                    </Typography.Text>
                  </Typography.Text>
                </Col>
              </Row>
            }
          />
        )}
      />
    </ContentLayout>
  );
};
