import { useQuery } from "@tanstack/react-query";
import { CompanyIntegrationDef } from "@app/types/company-integration.types";
import { api } from "./api";

const getCompanyIntegration = async () => {
  return api.get<CompanyIntegrationDef>("/crafthunts/company_integrations");
};

export const useGetCompanyIntegration = () =>
  useQuery({
    queryKey: ["company-integration"] as const,
    queryFn: () => getCompanyIntegration().then(({ data }) => data),
  });
