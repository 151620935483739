import { useMutation } from "@tanstack/react-query";
import { Col, Drawer, Form, Input, Space, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Label } from "@app/components/ui/Label/Label";
import {
  createTagSimilaritySA,
  deleteTagSimilaritySA,
  updateTagSimilaritySA,
  useTagSimilaritiesSA,
} from "@app/features/super-admin/api/super-admin.tag-similarity.api";
import { updateTagSA, useTagsSA } from "@app/features/super-admin/api/super-admin.tag.api";
import { TagSimilarityFormDef } from "@app/features/super-admin/types/super-admin.tag-similarity.types";
import { ETagType, ETradeCategoryType, TagDef } from "@app/types/tags.types";
import { SimilarTagListForm } from "./SimilarTagListForm";

type TagDrawerProps = {
  onClose: () => void;
  open: boolean;
  tag: TagDef<ETagType>;
};

export const TagDrawer = ({ onClose, open, tag }: TagDrawerProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [tagDefinition, setTagDefinition] = useState(tag.definition || "");
  const [isElectricianTrade, setIsElectricianTrade] = useState(
    tag.tradeCategory === ETradeCategoryType.ELECTRICIAN
  );
  const tagSimilaritiesQuery = useTagSimilaritiesSA({
    mainTagId: tag.id,
    similarTagType: tag.type,
  });
  const tradeTagSimilaritiesQuery = useTagSimilaritiesSA(
    {
      mainTagId: tag.id,
      similarTagType: ETagType.Crafthunt_Trade,
    },
    {
      enabled: tag.type === ETagType.Crafthunt_JobTitle,
    }
  );
  const ProjectCategoriesTagSimilaritiesQuery = useTagSimilaritiesSA(
    {
      mainTagId: tag.id,
      similarTagType: ETagType.Crafthunt_Project_Category,
    },
    {
      enabled: tag.type === ETagType.Crafthunt_Industry,
    }
  );
  const tagsQuery = useTagsSA({
    target: tag.type,
  });
  const tradesQuery = useTagsSA(
    {
      target: ETagType.Crafthunt_Trade,
    },
    {
      enabled: tag.type === ETagType.Crafthunt_JobTitle,
    }
  );
  const projectCategoriesQuery = useTagsSA(
    {
      target: ETagType.Crafthunt_Project_Category,
    },
    {
      enabled: tag.type === ETagType.Crafthunt_Industry,
    }
  );

  const createTagSimilarity = useMutation({
    mutationFn: createTagSimilaritySA,
    onSettled: () => {
      tagSimilaritiesQuery.refetch();
      tradeTagSimilaritiesQuery.refetch();
      ProjectCategoriesTagSimilaritiesQuery.refetch();
    },
  });

  const updateTagSimilarity = useMutation({
    mutationFn: updateTagSimilaritySA,
    onSettled: () => {
      tagSimilaritiesQuery.refetch();
      tradeTagSimilaritiesQuery.refetch();
      ProjectCategoriesTagSimilaritiesQuery.refetch();
    },
  });

  const deleteTagSimilarity = useMutation({
    mutationFn: deleteTagSimilaritySA,
    onSettled: () => {
      tagSimilaritiesQuery.refetch();
      tradeTagSimilaritiesQuery.refetch();
      ProjectCategoriesTagSimilaritiesQuery.refetch();
    },
  });

  const updateTag = useMutation({
    mutationFn: updateTagSA,
  });

  const updateTagDefinition = async () => {
    if (tagDefinition === (tag.definition || "")) {
      return;
    }
    updateTag.mutate({
      id: tag.id,
      definition: tagDefinition,
    });
  };

  const updateTagTradeCategory = async (isElectrician: boolean) => {
    setIsElectricianTrade(isElectrician);
    updateTag.mutate({
      id: tag.id,
      tradeCategory: isElectrician ? ETradeCategoryType.ELECTRICIAN : null,
    });
  };

  const handleBlur = (fields?: TagSimilarityFormDef) => {
    if (fields?.similarTagId && fields.weight) {
      if (fields.id) {
        updateTagSimilarity.mutate({
          id: fields.id,
          weight: parseFloat(fields.weight),
        });
      } else {
        createTagSimilarity.mutate({
          mainTagId: tag.id,
          weight: parseFloat(fields.weight),
          similarTagId: fields.similarTagId,
        });
      }
    }
  };

  const handleRemove = (fields?: TagSimilarityFormDef) => {
    if (fields?.id) {
      deleteTagSimilarity.mutate({ id: fields.id });
    }
  };

  useEffect(() => {
    if (tagSimilaritiesQuery.data) {
      form.setFieldsValue({
        similarTags: tagSimilaritiesQuery.data,
      });
    }
    if (tradeTagSimilaritiesQuery.data) {
      form.setFieldsValue({
        relatedTrades: tradeTagSimilaritiesQuery.data,
      });
    }
    if (ProjectCategoriesTagSimilaritiesQuery.data) {
      form.setFieldsValue({
        relatedProjectCategories: ProjectCategoriesTagSimilaritiesQuery.data,
      });
    }
  }, [
    tagSimilaritiesQuery.data,
    tradeTagSimilaritiesQuery.data,
    ProjectCategoriesTagSimilaritiesQuery,
    form,
  ]);

  useEffect(() => {
    setTagDefinition(tag.definition || "");
    setIsElectricianTrade(tag.tradeCategory === ETradeCategoryType.ELECTRICIAN);
  }, [tag]);

  return (
    <Drawer open={open} onClose={onClose} title={t(tag.name)}>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <div>
          <Label htmlFor="tag-definition">Definition</Label>
          <Input.TextArea
            id="tag-definition"
            rows={4}
            placeholder={"Definition"}
            value={tagDefinition}
            onChange={(event) => setTagDefinition(event.target.value)}
            onBlur={updateTagDefinition}
          />
        </div>
        {tag.type === ETagType.Crafthunt_Trade && (
          <Col>
            <Label htmlFor="isElectrician">Is Electrician Trade</Label>
            <Switch
              id="isElectrician"
              checked={isElectricianTrade}
              onChange={() => {
                updateTagTradeCategory(!isElectricianTrade);
              }}
            />
          </Col>
        )}

        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          labelAlign="right"
          form={form}
          data-hs-do-not-collect="true"
        >
          {/* Similar Tags */}
          <SimilarTagListForm
            label="Similar Tags"
            name="similarTags"
            options={tagsQuery.data?.map((item) => ({
              value: item.id,
              label: t(item.name),
            }))}
            placeholder="Tag"
            form={form}
            onBlur={handleBlur}
            onRemove={handleRemove}
          />
          {/* Trade Similar Tags (only for JobTitle) */}
          {tag.type === ETagType.Crafthunt_JobTitle && (
            <SimilarTagListForm
              label="Related Trades"
              name="relatedTrades"
              options={tradesQuery.data?.map((item) => ({
                value: item.id,
                label: t(item.name),
              }))}
              placeholder="Trade Tag"
              form={form}
              onBlur={handleBlur}
              onRemove={handleRemove}
            />
          )}
          {/* Project Category Similar Tags (only for industries) */}
          {tag.type === ETagType.Crafthunt_Industry && (
            <SimilarTagListForm
              label="Related project categories"
              name="relatedProjectCategories"
              options={projectCategoriesQuery.data?.map((item) => ({
                value: item.id,
                label: t(item.name),
              }))}
              placeholder="Project Category Tag"
              form={form}
              onBlur={handleBlur}
              onRemove={handleRemove}
            />
          )}
        </Form>
      </Space>
    </Drawer>
  );
};
