import { Col, Divider, Modal, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { OnboardingSteps } from "@app/components/ui/OnboardingSteps/OnboardingSteps";

export const FillOutInfoModal = (props: { open: boolean; close: () => void }) => {
  const { t } = useTranslation();

  return (
    <Modal
      cancelButtonProps={{ style: { display: "none", pointerEvents: "none" } }}
      width={1000}
      style={{ paddingBottom: 100 }}
      title={t("First steps")}
      centered
      open={props.open}
      onOk={() => props.close()}
      onCancel={() => props.close()}
      okText={t("Done")}
      okButtonProps={{ size: "large" }}
    >
      <Row justify="start">
        <Col xs={24}>
          <Typography.Text>
            {t("First, we need some information from you. Click the links below to begin.")}
          </Typography.Text>
        </Col>
        <Divider />
        <Col xs={16}>
          <OnboardingSteps />
        </Col>
      </Row>
    </Modal>
  );
};
