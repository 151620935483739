import { createModel } from "@rematch/core";
import api from "@app/api/project.api";
import { PostProjectDef, ProjectDef, UpdateProjectDef } from "@app/types/projects.types";
import { RootModel } from "./models";

export const projects = createModel<RootModel>()({
  state: {
    projects: [] as ProjectDef[],
  },
  reducers: {
    // company
    addProject: (state, project: ProjectDef) => {
      state.projects.push(project);
      return state;
    },
    removeProject: (state, projectIndex: number) => {
      state.projects.splice(projectIndex, 1);
      return state;
    },
    updateProject: (state, payload: { project: ProjectDef; index: number }) => {
      state.projects.splice(payload.index, 1, payload.project);
      return state;
    },
    addProjects: (state, payload: ProjectDef[]) => {
      state.projects = payload;
      return state;
    },
  },
  effects: (dispatch) => ({
    getProjects: async () => {
      try {
        const result = await api.getProjects();
        dispatch.projects.addProjects(result);
      } catch (e) {
        console.error(e);
      }
    },
    removeProjectAction: async (payload: string, rootState) => {
      const projectIndex = rootState.projects.projects.findIndex((i) => i.id === payload);
      if (projectIndex > -1) {
        // Api call goes here
        await api.removeProject(payload);
        dispatch.projects.removeProject(projectIndex);
      }
    },
    addProjectAction: async (payload: PostProjectDef) => {
      // Api call goes here
      const result = await api.postProject(payload);
      dispatch.projects.addProject(result);
    },
    updateProjectAction: async (payload: UpdateProjectDef, rootState) => {
      const projectIndex = rootState.projects.projects.findIndex((i) => i.id === payload.id);
      if (projectIndex > -1) {
        // Api call goes here
        const result = await api.putProject(payload);
        dispatch.projects.updateProject({ project: result, index: projectIndex });
      }
    },
  }),
});
