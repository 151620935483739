import { Spin, SpinProps } from "antd";

export const LoadingSpinner = ({ style, ...rest }: SpinProps) => {
  return (
    <Spin
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
        zIndex: "10",
        backgroundColor: "rgba(255,255,255,0.5)",
        ...style,
      }}
      {...rest}
    />
  );
};
