import {
  ActivityLogDef,
  ESipgateEventType,
  IActivityLogContentSipgate,
} from "@app/features/super-admin/types/super-admin.activity-log.types";
import { ActivityLogCard } from "./ActivityLogCard";

export const ActivityLogSipgate = ({ activityLog }: { activityLog: ActivityLogDef }) => {
  const data = activityLog.content as IActivityLogContentSipgate;
  const getTitle = () => {
    switch (data.event) {
      case ESipgateEventType.NEW_CALL:
        return "New Call";
      case ESipgateEventType.ANSWER:
        return "Call Answered";
      case ESipgateEventType.HANGUP:
        return "Call Ended";
      default:
        return "";
    }
  };

  return (
    <ActivityLogCard
      title={getTitle()}
      date={activityLog.createdAt}
      superAdminName={activityLog.superAdmin?.firstName}
      direction={activityLog.direction}
      type={activityLog.type}
    />
  );
};
