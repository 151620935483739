import { CheckOutlined } from "@ant-design/icons";
import styles from "./MenuItem.module.scss";

type MenuItemProps = {
  isDone: boolean;
  step: number;
};

export const MenuItem = ({ isDone, step }: MenuItemProps) => {
  if (isDone) {
    return <CheckOutlined className={styles.stepDone} />;
  }
  return <div className={styles.step}>{step + 1}</div>;
};
