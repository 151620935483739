import {
  CertificateTagDef,
  ETagType,
  IndustryTagDef,
  JobTitleTagDef,
  ProjectTypeTagDef,
  TradeTagDef,
} from "@app/types/tags.types";
import { api } from "./api";

export const getAllTagsByTarget = <T>(target: ETagType) => {
  return api.get<T>("/crafthunts/tags", {
    params: {
      target,
    },
  });
};

export const getAllJobTitles = () => {
  return getAllTagsByTarget<JobTitleTagDef[]>(ETagType.Crafthunt_JobTitle);
};

export const getAllTrades = () => {
  return getAllTagsByTarget<TradeTagDef[]>(ETagType.Crafthunt_Trade);
};

export const getAllIndustries = () => {
  return getAllTagsByTarget<IndustryTagDef[]>(ETagType.Crafthunt_Industry);
};

export const getAllProjectTypes = () => {
  return getAllTagsByTarget<ProjectTypeTagDef[]>(ETagType.Crafthunt_Project_Category);
};

export const getAllCertificates = () => {
  return getAllTagsByTarget<CertificateTagDef[]>(ETagType.Certificate);
};
