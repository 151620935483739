import { LoadScriptProps, useJsApiLoader } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { ENV } from "@app/constants/env";
import { mapLangToGoogleMapsRegion } from "@app/helpers/language.helper";

const MAP_LIBRARIES: LoadScriptProps["libraries"] = ["places", "geometry"];

export function useMap() {
  const { i18n } = useTranslation();

  return useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: ENV.GOOGLE_KEY,
    libraries: MAP_LIBRARIES,
    language: "en",
    region: mapLangToGoogleMapsRegion(i18n.language),
  });
}
