import Icon from "@ant-design/icons";
import { MdVerified } from "react-icons/md";
import { Colors } from "@app/constants/colors.constants";

type VerifiedBadgeProps = {
  className?: string;
  style?: React.CSSProperties;
};

export const VerifiedBadge = ({ className, style }: VerifiedBadgeProps) => {
  return (
    <Icon
      component={MdVerified}
      className={className}
      style={{ color: Colors.primary, ...style }}
    />
  );
};
