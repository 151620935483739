import { Modal } from "antd";
import { useTranslation } from "react-i18next";

type HiringModalProps = {
  open: boolean;
  onHire: () => void;
  onCancel: () => void;
};

export const HiringModal = ({ open, onHire, onCancel }: HiringModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("hire-candidate")}
      open={open}
      onOk={onHire}
      onCancel={onCancel}
      cancelText={t("Cancel")}
      okText={t("hire-candidate")}
      okType="primary"
    >
      <p>{t("hire-candidate-confirmation-text")}</p>
    </Modal>
  );
};
