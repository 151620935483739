import { createModel } from "@rematch/core";
import api from "@app/api/machine.api";
import { MachineDef, PostMachineDef, UpdateMachineDef } from "@app/types/machines.types";
import { RootModel } from "./models";

export const machines = createModel<RootModel>()({
  state: {
    machines: [] as MachineDef[],
  },
  reducers: {
    addMachine: (state, machine: MachineDef) => {
      state.machines.push(machine);
      return state;
    },
    removeMachine: (state, machineIndex: number) => {
      state.machines.splice(machineIndex, 1);
      return state;
    },
    updateMachine: (state, payload: { machine: MachineDef; index: number }) => {
      state.machines.splice(payload.index, 1, payload.machine);
      return state;
    },
    addMachines: (state, payload: MachineDef[]) => {
      state.machines = payload;
      return state;
    },
  },
  effects: (dispatch) => ({
    getMachines: async () => {
      const result = await api.getMachines();
      dispatch.machines.addMachines(result);
    },
    removeMachineAction: async (payload: string, rootState) => {
      const machineIndex = rootState.machines.machines.findIndex((i) => i.id === payload);
      if (machineIndex > -1) {
        // Api call goes here
        await api.removeMachine(payload);
        dispatch.machines.removeMachine(machineIndex);
      }
    },
    addMachineAction: async (payload: PostMachineDef) => {
      // Api call goes here
      const result = await api.postMachine(payload);
      dispatch.machines.addMachine(result);
    },
    updateMachineAction: async (payload: UpdateMachineDef, rootState) => {
      const machineIndex = rootState.machines.machines.findIndex((i) => i.id === payload.id);
      if (machineIndex > -1) {
        // Api call goes here
        const result = await api.putMachine(payload);
        dispatch.machines.updateMachine({ machine: result, index: machineIndex });
      }
    },
  }),
});
