import { Alert, Card, List, Space, Tag } from "antd";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { useJobsQuestionsSA } from "@app/features/super-admin/api/super-admin.job.api";
import { EJobQuestionAnswerType, EJobQuestionType } from "@app/types/job-questions.types";
import styles from "./QuestionsTab.module.scss";

export const QuestionsTab = ({ jobId }: { jobId: string }) => {
  const questionsQuery = useJobsQuestionsSA({ jobAdId: jobId });

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {questionsQuery.isLoading && <LoadingSpinner />}
      {questionsQuery.isError && <Alert message="Sorry, something went wrong." type="error" />}
      {questionsQuery.data && questionsQuery.data.length === 0 && (
        <>
          <Alert message="No questions" type="info" />
        </>
      )}
      {questionsQuery.data?.map((question) => {
        return (
          <Card
            key={question.id}
            size="small"
            title={question.title}
            className={styles.questionCard}
            extra={
              <>
                <Tag>
                  {question.answerType === EJobQuestionAnswerType.OPTION && (
                    <>{question.answerChoice}-</>
                  )}
                  {question.answerType}
                </Tag>
                {question.type === EJobQuestionType.REQUIRED && <Tag>required</Tag>}
              </>
            }
          >
            {question.answerType === EJobQuestionAnswerType.OPTION &&
              question.options &&
              question.options.length > 0 && (
                <List
                  size="small"
                  dataSource={question.options}
                  renderItem={(item, index) => (
                    <List.Item>
                      {index + 1}. {item.text}
                    </List.Item>
                  )}
                />
              )}
          </Card>
        );
      })}
    </Space>
  );
};
