import { Base64 } from "js-base64";
import { AuthTokenDef } from "@app/features/auth/types/auth.types";

export const parseJwt = (token?: string): AuthTokenDef | void => {
  if (!token) return;
  const [, base64Url] = token.split(".");
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(Base64.decode(base64));
};

export const getBearerToken = (token: string) => `Bearer ${token}`;
