import {
  ApiResponseKeyCompanyData,
  KeyCompanyDataForm,
  PostKeyCompanyData,
  UpdateKeyCompanyData,
} from "@app/types/company-key-data.types";
import { api } from "./api";

export default {
  async getKeyData(): Promise<KeyCompanyDataForm[]> {
    const response = await api.get<ApiResponseKeyCompanyData[]>(`/crafthunts/company_stats`);
    return response.data.map((i) => ({
      id: i.id,
      name: i.name,
      value: i.value,
    }));
  },
  async postKeyData(payload: PostKeyCompanyData): Promise<KeyCompanyDataForm> {
    const response = await api.post<ApiResponseKeyCompanyData>(`/crafthunts/company_stats`, {
      name: payload.name,
      value: payload.value,
    });
    return {
      id: response.data.id,
      name: response.data.name,
      value: response.data.value,
    };
  },
  async putKeyData(payload: UpdateKeyCompanyData): Promise<KeyCompanyDataForm> {
    const response = await api.put<ApiResponseKeyCompanyData>(
      `/crafthunts/company_stats/${payload.id}`,
      {
        name: payload.name,
        value: payload.value,
      }
    );
    return {
      id: response.data.id,
      name: response.data.name,
      value: response.data.value,
    };
  },
  deleteKeyData(id: string) {
    return api.delete<void>(`/crafthunts/company_stats/${id}`);
  },
};
