import axios from "axios";
import { ENV } from "@app/constants/env";

export const generateShortLink = async (magicLink: string) => {
  try {
    const { data } = await axios.post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks`,
      {
        dynamicLinkInfo: {
          domainUriPrefix: "https://crafthunt.page.link",
          link: magicLink,
          androidInfo: {
            androidPackageName: "app.crafthunt.crafthunt",
            androidFallbackLink: magicLink,
          },
          iosInfo: {
            iosBundleId: "app.crafthunt.crafthunt",
            iosFallbackLink: magicLink,
            iosIpadFallbackLink: magicLink,
          },
          navigationInfo: {
            enableForcedRedirect: true,
          },
        },
        suffix: {
          option: "SHORT",
        },
      },
      {
        params: {
          key: ENV.FIREBASE_SHORT_LINK_API_KEY,
        },
      }
    );
    return data?.shortLink || magicLink;
  } catch {
    return magicLink;
  }
};
