import { Descriptions, Popover, Tag } from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import map from "lodash/map";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { UserProgress } from "@app/features/super-admin/types/super-admin.progress.types";
import { RootState } from "@app/store/store";

export const PointsTag = memo(({ userProgress }: { userProgress: UserProgress }) => {
  const { t } = useTranslation();
  const trades = useSelector((state: RootState) => state.tags.trades);

  return (
    <Popover
      trigger="hover"
      style={{
        maxWidth: "600px",
      }}
      title={
        <>
          Points metadata <Tag color="orange">{userProgress.totalPoints} points</Tag>
          <div>
            <code
              style={{
                fontSize: "10px",
              }}
            >
              Total points = Profile <b>({userProgress.profilePoints})</b> + User active goal{" "}
              <b>({userProgress.activeGoalPoints})</b> + Best application active goal{" "}
              <b>
                (
                {Math.max(
                  ...userProgress.candidatesProgress.map(
                    (candidateProgress) => candidateProgress.activeGoalPoints
                  )
                )}
                )
              </b>
            </code>
          </div>
        </>
      }
      content={
        <Descriptions
          column={1}
          size="small"
          bordered
          style={{
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          <DescriptionsItem label="Trades">
            {userProgress.profilePointsMetadata.trades
              ?.map((tradeId) => trades.find((trade) => trade.id === tradeId)?.name)
              .map((name) => name && t(name))
              .join(", ")}
          </DescriptionsItem>
          <DescriptionsItem label="Has job matches above 75">
            {userProgress.profilePointsMetadata.hasJobMatchesAbove75 ? "✅" : "❌"}
          </DescriptionsItem>
          <DescriptionsItem label="Is Electrician">
            {userProgress.profilePointsMetadata.isElectrician ? "✅" : "❌"}
          </DescriptionsItem>
          <DescriptionsItem label="Is Electrician or job matches above 75">
            {userProgress.profilePointsMetadata.isElectricianOrJobMatchesAbove75Points} of{" "}
            {userProgress.profilePointsMetadata.isElectricianOrJobMatchesAbove75PointsOption} points
          </DescriptionsItem>
          <DescriptionsItem label="Speaks German">
            {userProgress.profilePointsMetadata.speaksGerman ? "✅" : "❌"}{" "}
            {userProgress.profilePointsMetadata.speaksGermanPoints} of{" "}
            {userProgress.profilePointsMetadata.speaksGermanPointsOption} points
          </DescriptionsItem>
          <DescriptionsItem label="Has German phone">
            {userProgress.profilePointsMetadata.hasGermanPhone ? "✅" : "❌"}{" "}
            {userProgress.profilePointsMetadata.hasGermanPhonePoints} of{" "}
            {userProgress.profilePointsMetadata.hasGermanPhonePointsOption} points
          </DescriptionsItem>
          <DescriptionsItem label="Has documents">
            {userProgress.profilePointsMetadata.hasDocuments ? "✅" : "❌"}
          </DescriptionsItem>
          <DescriptionsItem label="Filled out profile">
            {map(userProgress.profilePointsMetadata.filledOutProfile, (value, key) => (
              <div key={key}>
                {value ? "✅" : "❌"} {camelCaseToNormal(key)}
              </div>
            ))}
          </DescriptionsItem>
          <DescriptionsItem label="Has documents or filled out profile">
            {userProgress.profilePointsMetadata.hasDocumentsOrFilledOutProfilePoints} of{" "}
            {userProgress.profilePointsMetadata.hasDocumentsOrFilledOutProfilePointsOption} points
          </DescriptionsItem>
          <DescriptionsItem label="Has signed up (user goal)">
            {userProgress.activeGoalPointsMetadata.has_signed_up} of{" "}
            {userProgress.activeGoalPointsMetadata.has_signed_up_option} points
          </DescriptionsItem>
          <DescriptionsItem label="Ready for recommendation (user goal)">
            {userProgress.activeGoalPointsMetadata.ready_for_recommendation} of{" "}
            {userProgress.activeGoalPointsMetadata.ready_for_recommendation_option} points
          </DescriptionsItem>
          {userProgress.candidatesProgress.map((candidateProgress, index) => {
            return (
              <DescriptionsItem key={candidateProgress.id} label={`Application ${index + 1}`}>
                {candidateProgress.activeGoals.map((goal) => {
                  if (candidateProgress.activeGoalPointsMetadata[goal] == undefined) {
                    return null;
                  }

                  const points = candidateProgress.activeGoalPointsMetadata[goal];
                  const pointsOption = candidateProgress.activeGoalPointsMetadata[`${goal}_option`];

                  return (
                    <div key={goal}>
                      <b>{goal.replaceAll("_", " ")}:</b> {points} of {pointsOption} points
                    </div>
                  );
                })}
              </DescriptionsItem>
            );
          })}
        </Descriptions>
      }
    >
      <Tag color="orange" style={{ margin: 0 }}>
        {userProgress.totalPoints} point
        {userProgress.totalPoints > 1 ? "s" : ""}
      </Tag>
    </Popover>
  );
});

function camelCaseToNormal(text: string): string {
  return text
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camelCase words
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2") // Handle cases like "XMLHttpRequest"
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
}
