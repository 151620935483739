import { Tag } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdDownload, MdPictureAsPdf } from "react-icons/md";
import { generateCV } from "@app/api/generated-cv.api";
import { Icon } from "@app/components/ui/Icon/Icon";
import { triggerAnalyticsEvent } from "@app/helpers/analytics.helper";
import { downloadFile } from "@app/helpers/file.helper";
import { Button } from "@ui/Button/Button";
import styles from "./GenerateCV.module.scss";

type GenerateCVProps = {
  candidateUserId: string;
};

export const GenerateCV = ({ candidateUserId }: GenerateCVProps) => {
  const { t, i18n } = useTranslation();
  const [isLoadingCv, setIsLoadingCV] = useState(false);

  const handleGenerateCV = async () => {
    try {
      setIsLoadingCV(true);
      triggerAnalyticsEvent("generate_cv_button_clicked_admin", {
        lang: i18n.language,
        workerProfileId: candidateUserId,
      });
      const res = await generateCV(i18n.language, candidateUserId);
      // get file name from fileUrl after the pdf-cvs (which is the folder name)
      const fileName = res.data.fileUrl.split("pdf-cvs%2F")[1].split("?")[0];
      downloadFile(res.data.fileUrl, fileName);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingCV(false);
    }
  };

  return (
    <Button
      type="primary"
      block
      onClick={handleGenerateCV}
      loading={isLoadingCv}
      className={styles.button}
    >
      <div className={styles.buttonText}>
        <Icon icon={MdPictureAsPdf} size={24} />
        <div>
          {t("crafthunt-cv-file")} <Tag color="orange">{t("New")}</Tag>
        </div>
      </div>
      {!isLoadingCv && <Icon icon={MdDownload} size={18} />}
    </Button>
  );
};
