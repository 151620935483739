import { JobAnswerApiResponse } from "@app/types/job-answers.types";
import { api } from "./api";

const JOB_ANSWERS_BASE_URL = "/crafthunts/job_questions_answers";

export const getQuestionAnswer = async (questionId: string, lang: string, userId: string) => {
  const res = await api.get<JobAnswerApiResponse>(JOB_ANSWERS_BASE_URL, {
    params: { lang, jobQuestionId: questionId, workerProfileId: userId },
  });
  return res.data[0];
};
