import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDeepCompareEffect } from "react-use";
import { v4 } from "uuid";
import { RemovableCardForm } from "@app/components/ui/RemovableCardForm/RemovableCardForm";
import {
  RemovableCardFormInputOptions,
  RemovableCardFormInputTypes,
} from "@app/components/ui/RemovableCardForm/RemovableCardForm.types";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { useLoadingPopup } from "@app/hooks/useLoadingPopup";
import { ModelNames } from "@app/store/models/models";
import { RootState, store } from "@app/store/store";
import { ProjectDef, TempProjectDef } from "@app/types/projects.types";
import { CardLayout } from "@layouts/CardLayout/CardLayout";
import { ContentLayout } from "@layouts/ContentLayout/ContentLayout";

export const ConstructionProjects = () => {
  const loading = useLoadingPopup(ModelNames.PROJECTS);
  const projectTypes = useSelector((state: RootState) => state.tags.projectTypes);
  const savedProjects = useSelector((state: RootState) => state.projects.projects);
  const [innerProjects, setInnerProjects] = useState<ProjectDef[]>(savedProjects);
  const [unsavedProjects, setUnsavedProjects] = useState<TempProjectDef[]>([]);
  const [data, setData] = useState<Array<ProjectDef | TempProjectDef>>([]);
  const [isSaving, setIsSaving] = useState(false);
  const { dispatch } = store;
  const { t, i18n } = useTranslation();

  const projectTypeChoices = projectTypes.map((i) => ({
    value: i.id,
    text: t(i.name),
  }));

  useEffect(() => {
    if (!loading) {
      setInnerProjects(savedProjects);
    }
  }, [savedProjects, loading]);

  useDeepCompareEffect(() => {
    setData([...innerProjects, ...unsavedProjects]);
  }, [innerProjects, unsavedProjects]);

  const options: RemovableCardFormInputOptions<ProjectDef>[] = [
    {
      inputType: RemovableCardFormInputTypes.uploader,
      required: false,
      description: t("Images"),
      value: "coverImages",
    },
    {
      inputType: RemovableCardFormInputTypes.textArea,
      required: true,
      description: t("Title"),
      value: "name",
      placeholder: "Residential",
    },
    {
      inputType: RemovableCardFormInputTypes.textArea,
      required: false,
      description: t("Description"),
      value: "description",
      placeholder: "Residential - Family Home",
    },
    {
      inputType: RemovableCardFormInputTypes.select,
      isMultiple: true,
      required: true,
      description: t("Project Type"),
      value: "tagTypeIds",
      placeholder: "Project Types",
      choices: projectTypeChoices,
    },
    {
      inputType: RemovableCardFormInputTypes.address,
      required: false,
      description: t("Address"),
      value: "address",
      placeholder: "Address",
    },
    {
      inputType: RemovableCardFormInputTypes.datePicker,
      required: false,
      placeholder: "DD/MM/YYYY",
      description: t("Start date"),
      shouldBeBefore: "ends",
      value: "starts",
    },
    {
      inputType: RemovableCardFormInputTypes.datePicker,
      required: false,
      placeholder: "DD/MM/YYYY",
      description: t("End date"),
      shouldBeAfter: "starts",
      value: "ends",
    },
  ];

  function addProject() {
    const project: TempProjectDef = {
      id: `temp-${v4()}`,
      name: "",
      description: "",
      starts: null,
      ends: null,
      tagTypeIds: [],
      coverImages: [],
      isTemp: true,
    };
    setUnsavedProjects([...unsavedProjects, project]);
  }

  async function removeProject(project: ProjectDef | TempProjectDef) {
    if ("isTemp" in project) {
      const index = unsavedProjects.findIndex((p) => p.id === project.id);
      if (index > -1) {
        const splicedOut = [...unsavedProjects];
        splicedOut.splice(index, 1);
        setUnsavedProjects(splicedOut);
      }
    } else {
      await dispatch.projects.removeProjectAction(project.id);
    }
  }

  async function saveProject(project: ProjectDef | TempProjectDef) {
    setIsSaving(true);
    if ("isTemp" in project) {
      const index = unsavedProjects.findIndex((p) => p.id === project.id);
      delete project.isTemp;
      if (index > -1) {
        await dispatch.projects.addProjectAction({
          ...project,
          lang: convertLangForBackend(i18n.language),
        });
        const newArr = [...unsavedProjects];
        newArr.splice(index, 1);
        setUnsavedProjects(newArr);
      }
    } else {
      await dispatch.projects.updateProjectAction({
        ...project,
        lang: convertLangForBackend(i18n.language),
      });
    }
    setIsSaving(false);
  }

  const changeImages = (project: ProjectDef | TempProjectDef, newImages: string[]) => {
    const newProject = { ...project };
    newProject.coverImages = newImages;
    if ("isTemp" in project) {
      const index = unsavedProjects.findIndex((p) => p.id === project.id);
      const newState = [...unsavedProjects];
      newState.splice(index, 1, newProject);
      setUnsavedProjects(newState);
    } else {
      const index = innerProjects.findIndex((p) => p.id === project.id);
      const newState = [...innerProjects];
      newState.splice(index, 1, newProject);
      setInnerProjects(newState);
    }
  };

  return (
    <ContentLayout
      title={t("Construction projects")}
      description={t(
        "Show your interesting projects to inspire potential Projects for your company. Our experience shows that pictures from eye level, unadorned and raw work best on craftsmen."
      )}
      nextRoute={RoutePaths.EQUIPMENT_AND_MACHINERY}
    >
      <CardLayout
        onAddMore={addProject}
        addMoreTitle={t("Add more projects")}
        loading={isSaving}
        data={data}
        renderData={(item) => {
          return (
            <RemovableCardForm
              options={options}
              onRemove={removeProject}
              onSave={saveProject}
              initialValues={item}
              onImagesChange={(images) => changeImages(item, images)}
            />
          );
        }}
      />
    </ContentLayout>
  );
};
