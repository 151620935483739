import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CardLayout } from "@app/components/layouts/CardLayout/CardLayout";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { RootState } from "@app/store/store";
import { InternalCompanyBenefit } from "@app/types/company-data.types";
import { ContentLayout } from "@layouts/ContentLayout/ContentLayout";
import { EmployeeBenefitCard } from "./components/EmployeeBenefitCard/EmployeeBenefitCard";

export const EmployeeBenefits = () => {
  const benefits = useSelector((state: RootState) => state.companyData.benefits);
  const companyBenefits = useSelector((state: RootState) => state.companyData.companyBenefits);
  const loading = useSelector(
    (state: RootState) => state.loading.effects.companyData.getCompanyBenefits
  );
  const { t } = useTranslation();
  const [benefitsList, setBenefitsList] = useState<InternalCompanyBenefit[]>([]);

  useEffect(() => {
    const newBenefitsList: InternalCompanyBenefit[] = benefits.map((i) => {
      const savedBenefit = companyBenefits.find((e) => e.benefitId === i.id);
      return {
        id: i.id,
        benefit: i.title,
        imageUrl: i.imageUrl,
        isActive: !!savedBenefit?.isActive,
        description: savedBenefit?.description,
      };
    });
    setBenefitsList(newBenefitsList);
  }, [companyBenefits]);

  return (
    <ContentLayout
      title={t("Employee benefits")}
      description={t(
        "Today, employees don't just make up their minds about salary. As a company, you have to offer a total package that attracts qualified employees and, above all, craftsmen. Ask your employees what they value most about you and build on that."
      )}
      nextRoute={RoutePaths.SUBMIT_FOR_REVIEW}
    >
      <CardLayout
        loading={loading}
        data={benefitsList}
        renderData={(item) => <EmployeeBenefitCard key={item.id} benefit={item} />}
      />
    </ContentLayout>
  );
};
