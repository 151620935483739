import { mapFormDataToApi, mapResponseToFormData } from "@app/helpers/job-ads.helper";
import {
  CreateJobAdDef,
  IJobAdApiResponse,
  JobAdDef,
  JobAdsRequestDef,
  JobAdsWithPaginationDef,
  JobAdsWithPaginationResponseDef,
  UpdateJobAdDef,
} from "@app/types/job-ads.types";
import { api } from "./api";

export const getJobAds = async (params: JobAdsRequestDef): Promise<JobAdsWithPaginationDef> => {
  const response = await api.get<JobAdsWithPaginationResponseDef>(`/crafthunts/job_ads`, {
    params,
  });
  return {
    ...response.data,
    data: response.data.data.map((ad) => mapResponseToFormData(ad)),
  };
};

export const getJobAd = async (id: string): Promise<JobAdDef> => {
  const response = await api.get<IJobAdApiResponse>(`/crafthunts/job_ads/${id}`);
  return mapResponseToFormData(response.data);
};

export const postJobAd = async (payload: CreateJobAdDef): Promise<JobAdDef> => {
  const response = await api.post<IJobAdApiResponse>(
    `/crafthunts/job_ads`,
    mapFormDataToApi(payload)
  );
  return mapResponseToFormData(response.data);
};

export const updateJobAd = async (payload: UpdateJobAdDef): Promise<JobAdDef> => {
  const response = await api.put<IJobAdApiResponse>(
    `/crafthunts/job_ads/${payload.id}`,
    mapFormDataToApi(payload)
  );
  return mapResponseToFormData(response.data);
};

export const removeJobAds = (id: string) => {
  return api.delete<void>(`/crafthunts/job_ads/${id}`);
};
