import {
  CheckOutlined,
  ExclamationOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Autocomplete } from "@react-google-maps/api";
import { Input, Typography } from "antd";
import { isObject } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMap } from "@app/features/map/context/MapContext";
import { getFullAddress, parseAddress } from "@app/helpers/address.helper";
import { mapLangToGoogleMapsRegion } from "@app/helpers/language.helper";
import { AddressDef, AddressObjDef } from "@app/types/address.types";
import { StateStatusDef } from "@app/types/state-machine.types";
import Map from "./components/Map";

export enum EAddressPickerType {
  COUNTRY = "(regions)",
  CITY = "(cities)",
  ADDRESS = "address",
}

type AddressPickerProps = {
  value?: AddressObjDef | string;
  address?: AddressDef;
  type?: EAddressPickerType;
  onChange?: (v: AddressObjDef | null) => void;
  onStatusUpdate?: (status: StateStatusDef) => void;
  mapOffset?: number;
  disabled?: boolean;
  disableMap?: boolean;
};

export const AddressPicker = ({
  value,
  address,
  onChange,
  onStatusUpdate,
  mapOffset = 16,
  disabled,
  disableMap,
  type = EAddressPickerType.ADDRESS,
}: AddressPickerProps) => {
  const { t, i18n } = useTranslation();
  const mapApi = useMap();
  const initialFullAddress =
    typeof value === "string" ? value : getFullAddress(value?.address || address);
  const newFullAddressRef = useRef<string>(initialFullAddress);
  const searchRef = useRef<string>();
  const timerRef = useRef<number>();
  const [position, setPosition] = useState<google.maps.LatLngLiteral>();
  const [zoom, setZoom] = useState<number>();
  const [search, setSearch] = useState<string>(initialFullAddress);
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();
  const [status, setStatus] = useState<StateStatusDef>("initial");

  useEffect(() => {
    setSearch(initialFullAddress);
  }, [initialFullAddress]);

  useEffect(() => {
    searchRef.current = search;
    if (!search) {
      onChange?.(
        isObject(value)
          ? {
              address: null,
              coordinates: null,
            }
          : null
      );
      setStatus("initial");
    }
  }, [search]);

  useEffect(() => {
    if (status) {
      onStatusUpdate?.(status);
    }
  }, [status]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        window.clearTimeout(timerRef.current);
      }
    };
  }, []);

  const updatePlaceOnBlur = () => {
    timerRef.current = window.setTimeout(() => {
      if (!autocomplete || !searchRef.current) return;
      if (newFullAddressRef.current === searchRef.current) return;
      setStatus("loading");
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        { address: searchRef.current, region: mapLangToGoogleMapsRegion(i18n.language) },
        function (results, status) {
          if (status == google.maps.GeocoderStatus.OK && results?.length) {
            const place = results[0];
            autocomplete?.set("place", place);
          } else {
            setStatus("error");
          }
        }
      );
    }, 150);
  };

  const onPlaceChanged = () => {
    const place = autocomplete?.getPlace();
    if (place?.geometry?.location && place.address_components) {
      const newPosition = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      const parsed = parseAddress(place.address_components, newPosition);
      const parsedFullAddress = getFullAddress(parsed.address);
      if (parsedFullAddress !== newFullAddressRef.current) {
        setPosition(newPosition);
        setZoom(11);
        newFullAddressRef.current = parsedFullAddress;
        setSearch(parsedFullAddress);
        if (!parsed.address?.postalCode && type === EAddressPickerType.ADDRESS) {
          setStatus("error");
        } else {
          onChange?.(parsed);
          setStatus("success");
        }
      }
    }
  };

  const getStatusIcon = () => {
    switch (status) {
      case "error":
        return <ExclamationOutlined style={{ color: "#f5222d" }} />;
      case "loading":
        return <LoadingOutlined />;
      case "success":
        return <CheckOutlined style={{ color: "green" }} />;
      default:
        return <SearchOutlined />;
    }
  };

  return (
    <>
      <div style={{ marginBottom: disabled || disableMap ? 0 : mapOffset }}>
        {mapApi.isLoaded && (
          <Autocomplete
            onLoad={setAutocomplete}
            onPlaceChanged={onPlaceChanged}
            fields={["geometry", "address_components"]}
            types={[type]}
          >
            <>
              <Input
                formNoValidate
                suffix={getStatusIcon()}
                type="text"
                placeholder={t("Search for address...")}
                size="large"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                onBlur={updatePlaceOnBlur}
                status={status === "error" ? "error" : undefined}
                disabled={disabled}
              />
              {status === "error" && (
                <Typography.Text type="danger">
                  {t("Address needs street number and postal code")}
                </Typography.Text>
              )}
            </>
          </Autocomplete>
        )}
      </div>
      {!disabled && !disableMap && <Map address={initialFullAddress} pin={position} zoom={zoom} />}
    </>
  );
};
