import { UserOutlined } from "@ant-design/icons";
import { UseQueryResult } from "@tanstack/react-query";
import { Avatar, Badge, Card, Empty, Spin, Tag, Tooltip, Typography } from "antd";
import clsx from "clsx";
import { AnimatePresence, motion } from "motion/react";
import { memo, useMemo } from "react";
import { useAdminsSA } from "@app/features/super-admin/api/super-admin.admin.api";
import {
  UserProgress,
  UserProgressWithPaginationDef,
} from "@app/features/super-admin/types/super-admin.progress.types";
import { ECandidateGoal, EUserGoal } from "@app/features/super-admin/types/super-admin.task.types";

import styles from "./UserList.module.scss";

export const UserList = ({
  userProgressId,
  setUserProgressId,
  usersProgressQuery,
}: {
  userProgressId: string | null;
  setUserProgressId: (value: string | null) => void;
  usersProgressQuery: UseQueryResult<UserProgressWithPaginationDef, Error>;
}) => {
  const adminsQuery = useAdminsSA();

  const adminsById = useMemo(
    () => new Map(adminsQuery.data?.map((admin) => [admin.id, admin])),
    [adminsQuery.data]
  );

  const data = usersProgressQuery.data?.data.map((item) => {
    return {
      ...item,
      adminName: item.assignedSuperAdminId
        ? adminsById.get(item.assignedSuperAdminId)?.firstName
        : "",
    };
  });

  return (
    <>
      {usersProgressQuery.isLoading && <Empty description={<Spin />} />}
      {usersProgressQuery.isError && (
        <Empty description={<Typography.Text type="danger">Error loading data</Typography.Text>} />
      )}
      {usersProgressQuery.data && usersProgressQuery.data.data.length === 0 && (
        <Empty description="No data" />
      )}
      {usersProgressQuery.data && usersProgressQuery.data.data.length > 0 && (
        <Typography.Text type="secondary" className={styles.header}>
          {usersProgressQuery.data.totalPages > 1 && (
            <>Top {usersProgressQuery.data.data.length} of</>
          )}{" "}
          {usersProgressQuery.data.count} user{usersProgressQuery.data.count > 1 ? "s" : ""}
        </Typography.Text>
      )}
      <AnimatePresence initial={false}>
        {data?.map((item) => {
          return (
            <motion.div
              key={item.id}
              layout
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
            >
              <Item
                userProgress={item}
                isActive={userProgressId === item.id}
                onClick={setUserProgressId}
              />
            </motion.div>
          );
        })}
      </AnimatePresence>
    </>
  );
};

const Item = memo(
  ({
    userProgress,
    isActive,
    onClick,
  }: {
    userProgress: UserProgress & { adminName?: string };
    isActive: boolean;
    onClick: (id: string | null) => void;
  }) => {
    return (
      <Badge.Ribbon text={<>{userProgress.totalPoints}</>}>
        <Card
          hoverable
          size="small"
          className={clsx(styles.card, isActive && styles.active)}
          onClick={() => {
            onClick(isActive ? null : userProgress.id);
          }}
        >
          <div className={styles.content}>
            <Avatar size="small" src={userProgress.workerProfile.picture} icon={<UserOutlined />} />{" "}
            <Typography.Text>
              {userProgress.workerProfile.firstName} {userProgress.workerProfile.lastName}{" "}
            </Typography.Text>
          </div>
          {userProgress.currentTask && (
            <Tooltip title={userProgress.currentTask.name}>
              <Tag
                className={styles.tag}
                color={colorByGoal[userProgress.currentTask.goal] || undefined}
              >
                <span className={styles.tagText}>
                  {userProgress.currentTask.goal.replace(/_/g, " ")}
                </span>
              </Tag>
            </Tooltip>
          )}
          {userProgress.adminName && (
            <Typography.Text className={styles.admin} type="secondary">
              {userProgress.adminName}
            </Typography.Text>
          )}
        </Card>
      </Badge.Ribbon>
    );
  }
);

const colorByGoal: Record<EUserGoal | ECandidateGoal, string> = {
  has_signed_up: "",
  ready_for_recommendation: "",
  has_been_active_sourced: "cyan",
  has_applied: "",
  has_been_accepted: "gold",
  has_been_invited_to_interview: "blue",
  has_been_to_interview: "blue",
  has_been_rejected: "red",
  has_been_hired: "green",
};
