export const downloadFile = async (docUrl: string, docFileName: string) => {
  const file = await fetch(docUrl);
  const fileBlog = await file.blob();
  const fileURL = URL.createObjectURL(fileBlog);

  const link = document.createElement("a");
  link.href = fileURL;
  link.download = docFileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
