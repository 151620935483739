import { PercentageOutlined, TranslationOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Spin,
  Table,
  TableProps,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useJobSA } from "@app/features/super-admin/api/super-admin.job.api";
import { useTagsSA } from "@app/features/super-admin/api/super-admin.tag.api";
import { useUserSA } from "@app/features/super-admin/api/super-admin.user.api";
import { ScreeningQuestions } from "@app/features/super-admin/components/SuperAdminStuff/components/ApplicationModal/ScreeningQuestions";
import { CandidateSADef } from "@app/features/super-admin/types/super-admin.candidate.types";
import { JobAdSADef } from "@app/features/super-admin/types/super-admin.job.types";
import { UsersSAResponseDef } from "@app/features/super-admin/types/super-admin.user.types";
import { ETagType } from "@app/types/tags.types";

export const JobCard = ({
  jobId,
  title,
  candidate,
  onJobOpen,
}: {
  jobId: string;
  title: string;
  candidate: CandidateSADef;
  onJobOpen: () => void;
}) => {
  const { t, i18n } = useTranslation();
  const [showOriginalDescription, setShowOriginalDescription] = useState(false);
  const jobQuery = useJobSA({
    jobId,
    workerProfileId: candidate.workerProfile.id,
  });
  const tradesQuery = useTagsSA({
    target: ETagType.Crafthunt_Trade,
    withCount: true,
    withSimilarTags: true,
  });
  const rolesQuery = useTagsSA({
    target: ETagType.Crafthunt_JobTitle,
    withCount: true,
    withSimilarTags: true,
  });
  const userQuery = useUserSA({
    userId: candidate.workerProfile.id,
    lang: i18n.language,
  });

  const columns: TableProps["columns"] = [
    {
      title: "Job",
      dataIndex: "jobAd",
      key: "jobAd",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Match",
      dataIndex: "match",
      key: "match",
    },
  ];

  const roleMatch =
    jobQuery.data?.matchScoreMetadata?.rolesScore ||
    jobQuery.data?.role?.id == candidate.workerProfile.jobTitle?.id;
  const similarRole = rolesQuery.data
    ?.find((role) => role.id === jobQuery.data?.role?.id)
    ?.similarTagsId?.find((tagId) => tagId === candidate.workerProfile.jobTitle?.id);
  const tradeMatch =
    jobQuery.data?.matchScoreMetadata?.tradesScore ||
    candidate.workerProfile.profileTrades.some((trade) => trade.id === jobQuery.data?.trade?.id);
  const similarTrade = tradesQuery.data
    ?.find((trade) => trade.id === jobQuery.data?.trade?.id)
    ?.similarTagsId?.find((tagId) =>
      candidate.workerProfile.profileTrades.some((trade) => trade.id === tagId)
    );

  const isUserAndJobCountryMatch = (
    user: UsersSAResponseDef | undefined,
    job: JobAdSADef | undefined
  ) => {
    if (!user || !job) {
      return "❌";
    }
    if (job.matchScoreMetadata?.distanceScore) {
      return `✅${
        job.matchScoreMetadata.distanceKm
          ? ` (${job.matchScoreMetadata.distanceKm.toFixed(1).replace(".0", "")} km)`
          : ""
      }`;
    }
    if (!job.requiredCountries?.length && user.geoSearchAddress?.isEU) {
      return <Tooltip title="Job has no required countries and User is in EU">✅</Tooltip>;
    }
    if (
      user.geoSearchAddress?.countryCode &&
      job.requiredCountries?.map((req) => req.value).includes(user.geoSearchAddress.countryCode)
    ) {
      return (
        <Tooltip
          title={`User is in required countries: ${job.requiredCountries
            .map((req) => req.value)
            .join(", ")}`}
        >
          ✅
        </Tooltip>
      );
    }
    return "❌";
  };

  const dataSource = [
    {
      key: "roles",
      jobAd: jobQuery.data?.role?.name && t(jobQuery.data.role.name),
      user: candidate.workerProfile.jobTitle ? t(candidate.workerProfile.jobTitle.name) : "",
      match: roleMatch || similarRole ? <>✅{similarRole && ` (Similar)`}</> : "❌",
    },
    {
      key: "trades",
      jobAd: jobQuery.data?.trade?.name && t(jobQuery.data.trade.name),
      user: candidate.workerProfile.profileTrades.map((trade) => t(trade.name)).join(", "),
      match: tradeMatch || similarTrade ? <>✅{similarTrade && ` (Similar)`}</> : "❌",
    },
    {
      key: "location",
      jobAd: [jobQuery.data?.address?.city, jobQuery.data?.address?.country]
        .filter(Boolean)
        .join(", "),
      user: [
        userQuery.data?.geoSearchAddress?.city,
        userQuery.data?.geoSearchAddress?.country,
        jobQuery.data?.matchScoreMetadata?.distanceScore &&
          !jobQuery.data.matchScoreMetadata.distanceKm &&
          "Flexible",
      ]
        .filter(Boolean)
        .join(", "),
      match: isUserAndJobCountryMatch(userQuery.data, jobQuery.data),
    },
  ];

  return (
    <Card
      size="small"
      title={
        <Flex align="center" justify="space-between" gap="small">
          <a onClick={() => onJobOpen()}>{title}</a>
          {jobQuery.data && jobQuery.data.score != null && (
            <Tag color={jobQuery.data.score > 0.5 ? "green" : "orange"}>
              Score: {Number(jobQuery.data.score) * 100}%
            </Tag>
          )}
        </Flex>
      }
    >
      {(jobQuery.isError || userQuery.isError) && (
        <Alert message="Sorry, something went wrong." type="error" />
      )}
      {(jobQuery.isLoading || userQuery.isLoading) && <Spin />}
      {jobQuery.data && userQuery.data && (
        <Flex
          vertical
          gap="middle"
          style={{
            overflow: "hidden",
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item layout="vertical" label="Job description">
                <Input.TextArea
                  placeholder="Empty"
                  size="large"
                  autoSize={{
                    minRows: 1,
                    maxRows: 20,
                  }}
                  disabled
                  style={{
                    fontSize: "14px",
                    background: "#fff",
                    color: "black",
                  }}
                  value={jobQuery.data.description}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                layout="vertical"
                label={
                  <Flex gap="small" align="center" justify="space-between">
                    User description
                    {userQuery.data.description &&
                      userQuery.data.description_original &&
                      userQuery.data.description !== userQuery.data.description_original && (
                        <Tooltip title="Toggle original/translated">
                          <Button
                            icon={<TranslationOutlined />}
                            size="small"
                            onClick={() => setShowOriginalDescription(!showOriginalDescription)}
                          />
                        </Tooltip>
                      )}
                  </Flex>
                }
              >
                <Input.TextArea
                  placeholder="Empty"
                  size="large"
                  autoSize={{
                    minRows: 1,
                    maxRows: 20,
                  }}
                  disabled
                  style={{
                    fontSize: "14px",
                    background: "#fff",
                    color: "black",
                  }}
                  value={
                    showOriginalDescription
                      ? userQuery.data.description_original ?? ""
                      : userQuery.data.description ?? ""
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Flex vertical gap="small">
            <Flex gap="small" align="center">
              <PercentageOutlined style={{ fontSize: 20 }} />
              <Typography.Text strong>Match score</Typography.Text>
            </Flex>
            <Table
              loading={jobQuery.isLoading}
              pagination={false}
              columns={columns}
              dataSource={dataSource}
            />
          </Flex>
          <ScreeningQuestions jobAdId={jobId} workerProfileId={candidate.workerProfile.id} />
        </Flex>
      )}
    </Card>
  );
};
