import { ECandidateStatusType } from "@app/types/candidate.types";
import {
  BulkUpdateIntegrationStatusMappingDef,
  IntegrationStatusMappingDef,
} from "@app/types/integration-status.types";
import { api } from "./api";

export const getIntegrationStatusMapping = async (params: {
  jobAdId: string;
  type?: ECandidateStatusType;
}) => {
  const response = await api.get<IntegrationStatusMappingDef[]>(
    "/crafthunts/company_integration_status_mappings",
    {
      params,
    }
  );
  return response.data;
};

export const bulkUpdateIntegrationStatusMapping = async (
  data: BulkUpdateIntegrationStatusMappingDef
) => {
  const response = await api.put<IntegrationStatusMappingDef[]>(
    "/crafthunts/company_integration_status_mappings/bulk",
    data
  );
  return response.data;
};
