import { mapFormDataToApi, mapResponseToFormData } from "@app/helpers/projects.helper";
import {
  ApiProjectResponse,
  PostProjectDef,
  ProjectDef,
  UpdateProjectDef,
} from "@app/types/projects.types";
import { api } from "./api";

export default {
  async getProjects(): Promise<ProjectDef[]> {
    const response = await api.get<ApiProjectResponse[]>(`/crafthunts/projects`);
    return response.data.map((i) => {
      return mapResponseToFormData(i);
    });
  },
  async postProject(payload: PostProjectDef): Promise<ProjectDef> {
    const { data } = await api.post<ApiProjectResponse>(
      `/crafthunts/projects`,
      mapFormDataToApi(payload)
    );
    return mapResponseToFormData(data);
  },
  async putProject(payload: UpdateProjectDef): Promise<ProjectDef> {
    const { data } = await api.put<ApiProjectResponse>(
      `/crafthunts/projects/${payload.id}`,
      mapFormDataToApi(payload)
    );
    return mapResponseToFormData(data);
  },
  removeProject(id: string) {
    return api.delete<void>(`/crafthunts/projects/${id}`);
  },
};
