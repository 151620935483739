import {
  ArrowRightOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  EyeOutlined,
  FilePdfOutlined,
  MailOutlined,
  PhoneOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  Alert,
  App,
  Button,
  Col,
  Collapse,
  Flex,
  Image,
  Modal,
  Row,
  Space,
  Spin,
  Splitter,
  Typography,
} from "antd";
import { useState } from "react";
import { useLocalStorage } from "react-use";
import { HeaderContact } from "@app/components/pages/Candidates/components/CandidateDrawer/components/HeaderInfo/components/HeaderContact/HeaderContact";
import { queryKeysSA } from "@app/features/super-admin/api/queryKeys";
import { useCandidateSA } from "@app/features/super-admin/api/super-admin.candidate.api";
import { ApprovalDecisionModal } from "@app/features/super-admin/components/SuperAdminStuff/components/ApplicationModal/ApprovalDecisionModal";
import {
  CompanyCommentForm,
  UserCommentForm,
} from "@app/features/super-admin/components/SuperAdminStuff/components/ApplicationModal/CommentForms";
import { useApplicationModal } from "@app/features/super-admin/components/SuperAdminStuff/components/ApplicationModal/useApplicationModal";
import type { ApprovalState } from "@app/features/super-admin/components/SuperAdminStuff/components/Approvals/Approvals";
import { useJobModal } from "@app/features/super-admin/components/SuperAdminStuff/components/Jobs/JobModal/JobModal";
import { USER_MODAL_TAB_KEYS } from "@app/features/super-admin/components/SuperAdminStuff/components/Users/components/UserModal/UserModal";
import { useUserModalGlobal } from "@app/features/super-admin/components/SuperAdminStuff/components/Users/components/UserModal/UserModalGlobal";
import { CandidateSADef } from "@app/features/super-admin/types/super-admin.candidate.types";
import { FileDef } from "@app/features/super-admin/types/super-admin.user.types";
import { ECandidateApprovalAction, ECandidateStatus } from "@app/types/candidate.types";
import { AISummary } from "./AISummary";
import { Certificates } from "./Certificates";
import { JobCard } from "./JobCard";

export const InfoTab = ({ candidateId }: { candidateId?: string | null }) => {
  const queryClient = useQueryClient();
  const { setJobId } = useJobModal();
  const { setApplicationId } = useApplicationModal();
  const [pdfFile, setPdfFile] = useState<FileDef | null>(null);
  const { openUserModal } = useUserModalGlobal();
  const app = App.useApp();
  const [approvalState, setApprovalState] = useState<ApprovalState>({
    candidate: null as null | CandidateSADef,
    action: ECandidateApprovalAction.APPROVE,
  });
  const [sizes, setSizes] = useLocalStorage<(number | string)[]>("sa-approval-details-splitter", [
    "70%",
    "30%",
  ]);

  const onApprove = () => {
    queryClient.invalidateQueries({
      queryKey: queryKeysSA.all,
    });
  };

  const candidateQuery = useCandidateSA({
    id: candidateId,
  });

  const deletedUser = Boolean(candidateQuery.data?.workerProfile.deletedAt);

  const documents = (candidateQuery.data?.workerProfile.medias ?? []).map((media) => {
    const fileName = (media.filename ?? "").toLowerCase();
    const isImage =
      fileName.endsWith("jpeg") ||
      fileName.endsWith("jpg") ||
      fileName.endsWith("png") ||
      fileName.endsWith("webp");
    const isPdf = fileName.endsWith("pdf");

    return {
      ...media,
      mediaType: isImage ? ("image" as const) : isPdf ? ("pdf" as const) : ("other" as const),
    };
  });

  const closeApprovalModal = () => {
    setApprovalState((state) => ({ ...state, candidate: null }));
  };

  if (candidateQuery.isLoading) {
    return (
      <Flex justify="center" align="center" style={{ height: "50dvh" }}>
        <Spin />
      </Flex>
    );
  }

  return (
    <>
      <Splitter
        onResize={setSizes}
        style={{
          gap: "16px",
        }}
      >
        <Splitter.Panel size={sizes?.[0]} min="20%" max="70%">
          {candidateQuery.isError && <Alert message="Sorry, something went wrong." type="error" />}
          {candidateQuery.data && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Flex wrap gap="small" justify="space-between" align="center">
                {candidateQuery.data.status === ECandidateStatus.NEEDS_APPROVAL && (
                  <Flex gap="small">
                    <Button
                      size="large"
                      disabled={deletedUser}
                      onClick={() => {
                        setApprovalState({
                          candidate: candidateQuery.data,
                          action: ECandidateApprovalAction.APPROVE,
                        });
                      }}
                    >
                      ✅ Approve
                    </Button>
                    <Button
                      size="large"
                      disabled={deletedUser}
                      onClick={() => {
                        setApprovalState({
                          candidate: candidateQuery.data,
                          action: ECandidateApprovalAction.REJECT,
                        });
                      }}
                    >
                      ❌ Reject
                    </Button>
                  </Flex>
                )}

                <Flex gap="large" wrap>
                  {candidateQuery.data.workerProfile.phone && (
                    <HeaderContact
                      icon={PhoneOutlined}
                      href={`tel:${candidateQuery.data.workerProfile.phone}`}
                      label={candidateQuery.data.workerProfile.phone}
                    />
                  )}
                  {candidateQuery.data.workerProfile.email && (
                    <HeaderContact
                      icon={MailOutlined}
                      href={`mailto:${candidateQuery.data.workerProfile.email}`}
                      label={candidateQuery.data.workerProfile.email}
                    />
                  )}
                </Flex>
              </Flex>
              <div
                style={{
                  overflow: "hidden",
                }}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <CompanyCommentForm companyId={candidateQuery.data.crafthuntCompany.id} />
                  </Col>
                  <Col span={12}>
                    <UserCommentForm userId={candidateQuery.data.workerProfile.id} />
                  </Col>
                </Row>
                <Flex vertical gap="middle">
                  <Certificates workerProfileId={candidateQuery.data.workerProfile.id} />
                  <AISummary candidate={candidateQuery.data} />
                </Flex>
              </div>
              <Space direction="vertical" style={{ width: "100%", marginTop: 8 }}>
                <Flex gap="small" align="center">
                  <UnorderedListOutlined style={{ fontSize: 20 }} />
                  <Typography.Title level={5} style={{ marginBottom: 0 }}>
                    Job applications{" "}
                    {candidateQuery.data && <>({candidateQuery.data.jobAds.length})</>}
                  </Typography.Title>
                </Flex>
                {candidateQuery.data.jobAds.map((jobAd) => (
                  <JobCard
                    key={jobAd.id}
                    jobId={jobAd.id}
                    title={jobAd.title}
                    candidate={candidateQuery.data}
                    onJobOpen={() => setJobId(jobAd.id)}
                  />
                ))}
              </Space>
            </Space>
          )}
        </Splitter.Panel>
        <Splitter.Panel size={sizes?.[1]} min="20%" max="70%">
          <Space direction="vertical" style={{ width: "100%" }}>
            <Flex gap="small" align="center">
              <FilePdfOutlined style={{ fontSize: 20 }} />
              <Typography.Title level={5} style={{ marginBottom: 0 }}>
                Documents
              </Typography.Title>
            </Flex>
            {documents.length === 0 && <Alert message="No documents uploaded" />}
            {documents.length > 0 && (
              <Space size="middle" direction="vertical" style={{ width: "100%" }}>
                <Collapse
                  size="small"
                  ghost
                  defaultActiveKey={documents
                    .filter((media) => media.mediaType !== "other")
                    .map((media) => media.id)}
                  items={documents.map((media) => ({
                    key: media.id,
                    collapsible: media.mediaType === "other" ? "disabled" : undefined,
                    label: (
                      <Flex gap="small" align="center">
                        <Typography.Text>{media.filename}</Typography.Text>
                        {media.mediaType === "pdf" && (
                          <Button
                            size="small"
                            style={{
                              flexShrink: 0,
                            }}
                            icon={<EyeOutlined />}
                            onClick={(e) => {
                              e.stopPropagation();
                              setPdfFile(media);
                            }}
                          />
                        )}
                        {media.mediaType === "other" && (
                          <Button
                            size="small"
                            style={{
                              flexShrink: 0,
                            }}
                            icon={<DownloadOutlined />}
                            href={media.url}
                            target="_blank"
                          />
                        )}
                      </Flex>
                    ),
                    styles: {
                      header: {
                        paddingLeft: 0,
                        paddingRight: 0,
                      },
                      body: {
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingTop: 0,
                      },
                    },
                    children: {
                      image: <Image src={media.url} />,
                      pdf: (
                        <object
                          width="100%"
                          style={{
                            height: "auto",
                            aspectRatio: "1/1.5",
                          }}
                          data={media.url}
                          type="application/pdf"
                        />
                      ),
                      other: null,
                    }[media.mediaType],
                  }))}
                />
              </Space>
            )}
          </Space>
        </Splitter.Panel>
      </Splitter>
      <Modal
        open={Boolean(pdfFile)}
        width="100%"
        footer={null}
        style={{ top: 20 }}
        onCancel={() => setPdfFile(null)}
        title={pdfFile?.filename}
      >
        {pdfFile && (
          <object
            width="100%"
            style={{
              height: "80dvh",
            }}
            data={pdfFile.url}
            type="application/pdf"
          />
        )}
      </Modal>
      <ApprovalDecisionModal
        key={"approval" + approvalState.candidate?.id}
        {...approvalState}
        onSuccess={() => {
          app.modal.confirm({
            title: "Do you want to recommend this candidate to another company?",
            okType: "primary",
            okButtonProps: {
              icon: <ArrowRightOutlined />,
            },
            okText: "Recommend",
            cancelText: "No thanks",
            cancelButtonProps: {
              icon: <CloseCircleOutlined />,
            },
            onOk() {
              if (approvalState.candidate) {
                openUserModal({
                  userId: approvalState.candidate.workerProfile.id,
                  tab: USER_MODAL_TAB_KEYS.RECOMMENDATIONS,
                });
              }
            },
          });

          if (approvalState.action === ECandidateApprovalAction.REJECT) {
            setApplicationId(null);
          }

          closeApprovalModal();
        }}
        onCancel={closeApprovalModal}
        onSettled={onApprove}
      />
    </>
  );
};
