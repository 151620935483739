import { CheckOutlined, CloseOutlined, CopyOutlined, DownloadOutlined } from "@ant-design/icons";
import { App, Col, Divider, Form, Input, Modal, Row, Switch, Tooltip, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import step3 from "@app/assets/step3_400x400.png";
import { Button } from "@app/components/ui/Button/Button";
import { Label } from "@app/components/ui/Label/Label";
import { triggerAnalyticsEvent } from "@app/helpers/analytics.helper";
import { convertLangForAnalytics } from "@app/helpers/language.helper";
import { useAccountSetupProgress } from "@app/hooks/useAccountSetupProgress";
import { useLiveURL } from "@app/hooks/useLiveUrl";
import { Dispatch, RootState } from "@app/store/store";
import { ApprovalStatusEnum } from "@app/types/company-info.types";

type EmailForm = {
  email: string;
};

type AddLinkToWebsiteModalProps = {
  open: boolean;
  close: () => void;
};

export const AddLinkToWebsiteModal = ({ open, close }: AddLinkToWebsiteModalProps) => {
  const { message } = App.useApp();
  const existingCompanyInfo = useSelector((state: RootState) => state.companyInfo.companyInfo);
  const userInfo = useSelector((state: RootState) => state.auth);
  const [form] = useForm<EmailForm>();
  const email = Form.useWatch("email", form);
  const { t, i18n } = useTranslation();
  const liveURL = useLiveURL();
  const { hasWebsiteLink } = useAccountSetupProgress();
  const dispatch = useDispatch<Dispatch>();

  const showCopiedLinkMessage = () => {
    try {
      navigator.clipboard.writeText(liveURL);
      message.success(t("Link copied!"), 0.5);
    } catch {
      message.error(`${t("Could not copy link")}: ${liveURL}`, 10);
    }
  };

  const handleOnClose = () => {
    // using timeout so transition is finished before resetting fields
    setTimeout(() => form.resetFields(), 100);
    close();
  };

  const onSendEmail = (values: EmailForm) => {
    triggerAnalyticsEvent("sendIconsToDeveloper", {
      fromFirstName: userInfo.firstName,
      fromLastName: userInfo.lastName,
      fromCompanyName: existingCompanyInfo.name,
      receiverEmail: values.email,
      linkToCompanyPage: liveURL,
      language: convertLangForAnalytics(i18n.language),
    });
    message.success(t("Information sent!"), 4);
    // using timeout so modal doesn't close instantly
    setTimeout(() => handleOnClose(), 350);
  };

  const downloadZips = () => {
    window.open("https://crafthunt.app/static/social_icons_Crafthunt.zip");
  };

  return (
    <Modal
      cancelButtonProps={{ style: { display: "none", pointerEvents: "none" } }}
      width={1000}
      style={{ paddingBottom: 100 }}
      title={t("Your Crafthunt company profile")}
      centered
      open={open}
      onOk={handleOnClose}
      onCancel={handleOnClose}
      okText={t("Done")}
      okButtonProps={{ size: "large" }}
      destroyOnClose
    >
      <Row justify="start" gutter={32}>
        <Col xs={24}>
          <Typography.Title level={5}>
            {t(
              "In order to be approved, you need to add a link to your company website that points to your Crafthunt company profile."
            )}
          </Typography.Title>
        </Col>
        <Divider />
        <Col xs={14}>
          <Typography.Text style={{ whiteSpace: "pre-line" }}>
            {t(
              "Not sure how to do this, or is it not your responsibility? \nEnter the email of the person that can add this, and we will send them all they need."
            )}
          </Typography.Text>
          <Row>
            <Col xs={18} style={{ marginTop: 16, marginBottom: 32 }}>
              <Form form={form} onFinish={onSendEmail} data-hs-do-not-collect="true">
                <Input.Group compact style={{ display: "flex" }}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        whitespace: true,
                        message: t("Invalid email"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={"example@example.com"}
                      size="large"
                      style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    />
                  </Form.Item>
                  <Button disabled={!email} htmlType="submit" type="primary">
                    {t("Send")}
                  </Button>
                </Input.Group>
              </Form>
            </Col>
          </Row>
          <Typography.Text>{t("Media")}</Typography.Text>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Input.Group compact>
                <Input
                  style={{ width: "calc(100% - 50px)" }}
                  defaultValue={liveURL}
                  value={liveURL}
                  size="large"
                />
                <Tooltip title={t("Copy link")}>
                  <Button onClick={() => showCopiedLinkMessage()} icon={<CopyOutlined />} />
                </Tooltip>
              </Input.Group>
            </Col>
            <Col xs={24}>
              <Button onClick={() => downloadZips()}>
                <DownloadOutlined />
                {t("Download Crafthunt icons")}
              </Button>
            </Col>
            {existingCompanyInfo.approvalStatus !== ApprovalStatusEnum.APPROVED && (
              <>
                <Divider />
                <Col xs={24}>
                  <Label htmlFor="has-website-link">
                    {t("I have added the Crafthunt logo to my website")}
                  </Label>
                  <Switch
                    id="has-website-link"
                    checked={hasWebsiteLink}
                    onChange={dispatch.companyData.updateHasWebsiteLink}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>
        <Col xs={10}>
          <img width={"100%"} src={step3} />
        </Col>
      </Row>
    </Modal>
  );
};
