import { useMutation } from "@tanstack/react-query";
import { App, Form, Input, Modal, Radio, Select, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { InlineSwitch } from "@app/components/ui/InlineSwitch/InlineSwitch";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { approveCandidateSA } from "@app/features/super-admin/api/super-admin.candidate.api";
import type { ApprovalState } from "@app/features/super-admin/components/SuperAdminStuff/components/Approvals/Approvals";
import { ESuperAdminCandidateRejectionReason } from "@app/features/super-admin/types/super-admin.candidate.types";
import { ECandidateApprovalAction } from "@app/types/candidate.types";
import { launchConfetti, randomInRange } from "./approvals.helper";

type Props = ApprovalState & {
  onSettled: () => void;
  onSuccess: () => void;
  onCancel: () => void;
};

export const ApprovalDecisionModal = ({
  candidate,
  action,
  onSettled,
  onSuccess,
  onCancel,
}: Props) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [form] = useForm<{
    rejectionReason: ESuperAdminCandidateRejectionReason;
    rejectionMessage: string;
    description: string;
    isVisibleToCompany: boolean;
  }>();
  const deletedUser = Boolean(candidate?.workerProfile.deletedAt);
  const approveOrReject = useMutation({
    mutationFn: (_vars: { x: number; y: number }) => {
      const formValues = form.getFieldsValue();

      return approveCandidateSA(
        action === ECandidateApprovalAction.APPROVE
          ? {
              candidateId: candidate?.id || "",
              action,
              description: formValues.description,
              isVisibleToCompany: formValues.isVisibleToCompany,
            }
          : {
              candidateId: candidate?.id || "",
              action,
              description: formValues.description,
              rejectionReason: formValues.rejectionReason,
              rejectionMessage: formValues.rejectionMessage,
              isVisibleToCompany: formValues.isVisibleToCompany,
            }
      );
    },
    onSettled: () => {
      onSettled();
    },
    onSuccess: (_data, { x, y }) => {
      message.success(
        action === ECandidateApprovalAction.APPROVE
          ? "Candidate has been approved."
          : "Candidate has been rejected."
      );

      if (action === ECandidateApprovalAction.APPROVE) {
        launchConfetti(x, y);
        setTimeout(() => launchConfetti(x, y), randomInRange(200, 300));
        setTimeout(() => launchConfetti(x, y), randomInRange(400, 500));
      }

      onSuccess();
    },
  });

  const rejectionMessageTemplates = [
    {
      title: "Default",
      reason: t("candidate-approval-rejection", {
        companyName: candidate?.crafthuntCompany.name,
        jobTitle: candidate?.jobAds[0]?.title,
      }),
    },
    {
      title: "Alternative",
      reason: t("candidate-approval-rejection-alt", {
        companyName: candidate?.crafthuntCompany.name,
        jobTitle: candidate?.jobAds[0]?.title,
      }),
    },
  ];

  return (
    <Form
      form={form}
      initialValues={{
        rejectionReason: null,
        rejectionMessage: t("candidate-approval-rejection", {
          companyName: candidate?.crafthuntCompany.name,
          jobTitle: candidate?.jobAds[0]?.title,
        }),
        description: "",
        isVisibleToCompany: false,
      }}
      data-hs-do-not-collect="true"
      disabled={deletedUser}
    >
      <Modal
        open={Boolean(candidate)}
        title={`Are you sure you want to ${
          action === ECandidateApprovalAction.APPROVE ? "approve" : "reject"
        } the candidate?`}
        okText={action === ECandidateApprovalAction.APPROVE ? "Approve" : "Reject"}
        okType={action === ECandidateApprovalAction.APPROVE ? "primary" : "danger"}
        cancelText="Close"
        confirmLoading={approveOrReject.isPending}
        onOk={async (e) => {
          const x =
            (e.currentTarget.getBoundingClientRect().left + e.currentTarget.offsetWidth / 2) /
            window.innerWidth;
          const y =
            (e.currentTarget.getBoundingClientRect().top + e.currentTarget.offsetHeight / 2) /
            window.innerHeight;

          try {
            await form.validateFields();

            approveOrReject.mutate({ x, y });
          } catch {
            return;
          }
        }}
        onCancel={onCancel}
      >
        <Space direction="vertical">
          {action === ECandidateApprovalAction.REJECT && (
            <>
              <Form.Item label="Rejection template" style={{ marginBottom: 0 }}>
                <Radio.Group
                  defaultValue={rejectionMessageTemplates[0].reason}
                  onChange={(e) => {
                    form.setFieldsValue({ rejectionMessage: e.target.value });
                  }}
                >
                  {rejectionMessageTemplates.map((item) => (
                    <Radio.Button key={item.title} value={item.reason}>
                      {item.title}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="rejectionMessage"
                label="Enter rejection message to the candidate:"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    type: "string",
                    required: true,
                    whitespace: true,
                    message: t("Required"),
                  },
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 4, maxRows: 10 }} />
              </Form.Item>
              <Form.Item
                label="Select rejection reason:"
                name="rejectionReason"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: t("Required"),
                  },
                ]}
              >
                <SelectAutocomplete sortAlphabetically={false}>
                  {Object.values(ESuperAdminCandidateRejectionReason).map((reason) => {
                    return (
                      <Select.Option key={reason} value={reason} label={t(reason)}>
                        {t(reason)}
                      </Select.Option>
                    );
                  })}
                </SelectAutocomplete>
              </Form.Item>
            </>
          )}
          <Form.Item
            name="description"
            label="Enter insights and comments about the candidate here:"
            wrapperCol={{ span: 24 }}
            labelCol={{ span: 24 }}
            rules={[
              {
                type: "string",
                required: true,
                whitespace: true,
                message: t("Required"),
              },
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
          </Form.Item>
          {action === ECandidateApprovalAction.APPROVE && (
            <Form.Item name="isVisibleToCompany" valuePropName="checked">
              <InlineSwitch label="Show note to company" />
            </Form.Item>
          )}
        </Space>
      </Modal>
    </Form>
  );
};
