import { AddressDef } from "@app/types/address.types";
import {
  CompanyCandidateStatusDef,
  ECandidateApprovalAction,
  ECandidateMethod,
  ECandidateRejectionReason,
  ECandidateStatus,
} from "@app/types/candidate.types";
import { PaginationRequestDef, PaginationResponseDef } from "@app/types/pagination.types";
import { FileDef } from "./super-admin.user.types";

export interface CandidatesSAColumnsDataDef {
  key: string;
  isScrapedJob: JSX.Element;
  companyName: JSX.Element;
  jobAds: {
    id: string;
    title: string;
    slug: string;
    fullSlug: string;
  }[];
  jobName: JSX.Element | string;
  candidateName: JSX.Element | string;
  status: string;
  method: string;
  createdAt: JSX.Element;
  operation: JSX.Element;
}

export interface CandidateSADef {
  id: string;
  AIDescriptionSummary?: {
    "con-arguments"?: string[];
    "pro-arguments"?: string[];
    job_match_confidence?: number;
    prime_candidate_score?: number;
    is_recommended_candidate?: boolean;
    prime_candidate_score_reason?: string;
  } | null;
  companyCandidateStatus?: CompanyCandidateStatusDef;
  jobAds: [
    {
      id: string;
      title: string;
      slug: string;
      fullSlug: string;
    }
  ];
  workerProfile: {
    id: string;
    address?: AddressDef | null;
    firstName: string;
    lastName: string;
    description?: string;
    slug: string;
    phone?: string;
    email?: string;
    isVerified: boolean;
    isAnonymous: boolean;
    deletedAt: string | null;
    medias: FileDef[];
    jobTitle?: {
      category: string;
      categoryId: string;
      id: string;
      name: string;
    } | null;
    profileTrades: {
      category: string;
      categoryId: string;
      id: string;
      name: string;
    }[];
  };
  crafthuntCompany: {
    id: string;
    name: string;
    slug: string;
    isClaimed: boolean;
  };
  isCrafthuntRecommended: boolean;
  internalNote: string | null;
  method: ECandidateMethod;
  createdAt: string;
  status: ECandidateStatus;
}

export type GetCandidatesSAParamsDef = PaginationRequestDef & {
  companyId?: string;
  workerProfileId?: string;
  status?: ECandidateStatus;
  excludedStatuses?: ECandidateStatus[];
};

export type CandidatesSAWithPaginationDef = PaginationResponseDef<CandidateSADef>;

export type GetCandidateApprovalSAParamsDef = PaginationRequestDef & {
  workerProfileId?: string;
  startDate?: string;
  endDate?: string;
};

export type CandidateApprovalSADef = {
  id: string;
  action: ECandidateApprovalAction;
  rejectionReason?: ESuperAdminCandidateRejectionReason;
  description: string;
  candidate: CandidateSADef;
  candidateId: string;
  superAdminId: string;
  createdAt: string;
};

export type CandidateApprovalsSAWithPaginationDef = PaginationResponseDef<CandidateApprovalSADef>;

export const ESuperAdminCandidateRejectionReason = {
  ...ECandidateRejectionReason,
  APPLIED_TO_SCRAPED_JOB: "rejection-reason-applied-to-scraped-job",
} as const;
export type ESuperAdminCandidateRejectionReason =
  (typeof ESuperAdminCandidateRejectionReason)[keyof typeof ESuperAdminCandidateRejectionReason];
