import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  QrcodeOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Grid } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import step1 from "@app/assets/step1_400x400.png";
import step2 from "@app/assets/step2_400x400.png";
import step3 from "@app/assets/step3_400x400.png";
import step4 from "@app/assets/step4_400x400.png";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { AddLinkToWebsiteModal } from "@ui/AddLinkToWebsiteModal/AddLinkToWebsiteModal";
import { DownloadAppModal } from "./DownloadAppModal/DownloadAppModal";
import { FillOutInfoModal } from "./FillOutInfoModal";

const { useBreakpoint } = Grid;

export const CTACards = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const screens = useBreakpoint();

  const isMobile = !screens.md;

  const [fillOutInfoModalOpen, setFillOutInfoModalOpen] = useState(false);
  const [downloadAppModalOpen, setDownloadAppModalOpen] = useState(false);
  const [addLinkToWebsiteModalOpen, setAddLinkToWebsiteModalOpen] = useState(false);

  const items = [
    {
      title: t("Fill out your information"),
      description: t("Provide some details about your company to make your profile stand out"),
      icon: InfoCircleOutlined,
      onClick: () => {
        setFillOutInfoModalOpen(true);
      },
      image: step1,
    },
    {
      title: t("Download the app"),
      description: t("Login using your account and join the community in the Crafthunt app"),
      icon: QrcodeOutlined,
      onClick: () => {
        setDownloadAppModalOpen(true);
      },
      image: step2,
    },
    {
      title: t("Add your profile to your website"),
      description: t("Add a link to your Crafthunt profile to your company website."),
      icon: UsergroupAddOutlined,
      onClick: () => {
        setAddLinkToWebsiteModalOpen(true);
      },
      image: step3,
    },
    {
      title: t("Submit for review"),
      description: t("When you're ready, ask us to review your profile so you can get approved"),
      icon: CheckCircleOutlined,
      onClick: () => {
        navigate(RoutePaths.SUBMIT_FOR_REVIEW);
      },
      image: step4,
    },
  ];

  const closeModals = () => {
    setDownloadAppModalOpen(false);
    setAddLinkToWebsiteModalOpen(false);
    setFillOutInfoModalOpen(false);
  };

  return (
    <Row gutter={[40, 40]}>
      {items.map((item) => (
        <Col key={item.title} xs={24} md={12}>
          <Card onClick={() => item.onClick()} hoverable cover={<img src={item.image} />}>
            <Card.Meta
              title={item.title}
              description={item.description}
              avatar={<item.icon style={{ fontSize: isMobile ? "16px" : "35px" }} />}
            />
          </Card>
        </Col>
      ))}
      <DownloadAppModal close={closeModals} open={downloadAppModalOpen} />
      <FillOutInfoModal close={closeModals} open={fillOutInfoModalOpen} />
      <AddLinkToWebsiteModal close={closeModals} open={addLinkToWebsiteModalOpen} />
    </Row>
  );
};
