import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, FormInstance, Input, Row } from "antd";
import { useTranslation } from "react-i18next";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { TagSimilarityFormDef } from "@app/features/super-admin/types/super-admin.tag-similarity.types";

type SimilarTagListFormProps = {
  label: string;
  name: string;
  options?: { value: string; label: string }[];
  placeholder: string;
  form: FormInstance;
  onBlur: (fields?: TagSimilarityFormDef) => void;
  onRemove: (fields?: TagSimilarityFormDef) => void;
};

export const SimilarTagListForm = ({
  label,
  name,
  options,
  placeholder,
  form,
  onBlur,
  onRemove,
}: SimilarTagListFormProps) => {
  const { t } = useTranslation();

  return (
    <Form.Item label={label} name={name}>
      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name: fieldName, ...restField }) => {
              const fieldValue = form.getFieldValue([name, fieldName]);
              return (
                <Row key={key} gutter={4}>
                  <Col span={16}>
                    <Form.Item
                      {...restField}
                      name={[fieldName, "similarTagId"]}
                      rules={[{ required: true, message: "Missing tag" }]}
                    >
                      <SelectAutocomplete
                        disabled={fieldValue?.id}
                        placeholder={placeholder}
                        options={options}
                        onBlur={() => onBlur(fieldValue)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      {...restField}
                      name={[fieldName, "weight"]}
                      rules={[{ required: true, message: "Missing weight" }]}
                    >
                      <Input
                        placeholder={t("Weight")}
                        size="large"
                        onBlur={() => onBlur(fieldValue)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <MinusCircleOutlined
                      onClick={() => {
                        onRemove(fieldValue);
                        remove(fieldName);
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                {t("Add")}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  );
};
