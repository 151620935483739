import { Card, Row, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./AverageResponseTime.module.scss";

export const AverageResponseTime = () => {
  const { t } = useTranslation();

  /**
   * This is to fake the average response time,
   * If you edit this function you also need to edit the chat package one
   * @returns a random number between 24 and 72, with a slight variance day to day
   */
  function getRandomHours(): number {
    // Get today's date as a string
    const today = new Date().toISOString().split("T")[0];
    // Simple hash function to generate a number based on the date string
    const hash = Array.from(today).reduce((acc, char) => {
      return acc + char.charCodeAt(0);
    }, 0);
    // Generate a base random number between 24 and 72, using the hash
    const baseHours = 24 + (hash % 49); // 49 is 72 - 24 to ensure the range is between 24 and 72
    // Add a bit of variance for each day, keeping it close to the previous day
    const variance = (hash % 5) - 2; // 2, -1, 0, +1, or +2 to add a slight change and not completely change the average day to day
    return baseHours + variance;
  }

  return (
    <Card className={styles.responseTimeCard}>
      <Tooltip title={t("average-response-time-tooltip")}>
        <Row>
          <Typography.Text strong className={styles.cardHeader}>
            {t("average-response-time-title")}
          </Typography.Text>
        </Row>
        <Row>
          <Typography.Title level={4}>
            {t("average-response-time-value", { 0: getRandomHours() })}
          </Typography.Title>
        </Row>
      </Tooltip>
    </Card>
  );
};
