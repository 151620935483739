import { Alert, Col, Row, Spin, Statistic } from "antd";
import { useTranslation } from "react-i18next";
import {
  useUserCertificatesSA,
  useUserStatsSA,
} from "@app/features/super-admin/api/super-admin.user.api";
import { ETagCategory } from "@app/types/tags.types";

export const Certificates = ({ workerProfileId }: { workerProfileId: string }) => {
  const { t } = useTranslation();
  const statsQuery = useUserStatsSA({
    workerProfileId,
  });
  const certificatesQuery = useUserCertificatesSA({
    workerProfileId,
  });

  const degreeCertificates =
    certificatesQuery.data?.filter(
      (certificate) => certificate.type?.category === ETagCategory.DEGREE
    ) ?? [];
  const drivingLicenses =
    certificatesQuery.data?.filter(
      (certificate) => certificate.type?.category === ETagCategory.DRIVING_LICENCE
    ) ?? [];

  const data = [
    {
      title: "Degree",
      value:
        degreeCertificates.length > 0
          ? `✅ ${degreeCertificates.map((c) => t(c.title)).join(", ")}`
          : "❌",
    },
    {
      title: "Driver License",
      value:
        drivingLicenses.length > 0
          ? `✅ ${drivingLicenses.map((c) => t(c.title)).join(", ")}`
          : "❌",
    },
    {
      title: "German Speaker",
      value: statsQuery.data?.data.languages.includes("de") ? "✅" : "❌",
    },
  ];

  return (
    <>
      {statsQuery.isLoading || (certificatesQuery.isLoading && <Spin />)}
      {certificatesQuery.isError && <Alert message="Failed to load certificates" type="error" />}
      {statsQuery.isError && <Alert message="Failed to load stats" type="error" />}
      {statsQuery.data && certificatesQuery.data && (
        <Row gutter={16}>
          {data.map((item, index) => (
            <Col key={index} span={8}>
              <Statistic
                title={
                  <span
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {item.title}
                  </span>
                }
                valueStyle={{
                  fontSize: "16px",
                }}
                value={item.value}
              />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};
