import { createModel } from "@rematch/core";
import api from "@app/api/trades.api";
import {
  CompanyTradeDef,
  CreateCompanyTradeDef,
  UpdateCompanyTradeDef,
} from "@app/types/trades.types";
import { RootModel } from "./models";

export const trades = createModel<RootModel>()({
  state: {
    companyTrades: [] as CompanyTradeDef[],
  },
  reducers: {
    // company
    addTrade: (state, trade: CompanyTradeDef) => {
      state.companyTrades.push(trade);
      return state;
    },
    removeTrade: (state, tradeIndex: number) => {
      state.companyTrades.splice(tradeIndex, 1);
      return state;
    },
    updateTrade: (state, payload: { trade: CompanyTradeDef; index: number }) => {
      state.companyTrades.splice(payload.index, 1, payload.trade);
      return state;
    },
    addCompanyTrades: (state, payload: CompanyTradeDef[]) => {
      state.companyTrades = payload;
      return state;
    },
  },
  effects: (dispatch) => ({
    getCompanyTrades: async () => {
      try {
        const result = await api.getCompanyTrades();
        dispatch.trades.addCompanyTrades(result);
      } catch (e) {
        console.error(e);
      }
    },
    removeTradeAction: async (payload: string, rootState) => {
      const tradeIndex = rootState.trades.companyTrades.findIndex((i) => i.id === payload);
      if (tradeIndex > -1) {
        await api.removeTrade(payload);
        dispatch.trades.removeTrade(tradeIndex);
      }
    },
    addTradeAction: async (payload: CreateCompanyTradeDef) => {
      const result = await api.createTrade(payload);
      dispatch.trades.addTrade(result);
    },
    updateTradeAction: async (payload: UpdateCompanyTradeDef, rootState) => {
      const tradeIndex = rootState.trades.companyTrades.findIndex((i) => i.id === payload.id);
      if (tradeIndex > -1) {
        const result = await api.updateTrade(payload);
        dispatch.trades.updateTrade({ trade: result, index: tradeIndex });
      }
    },
  }),
});
