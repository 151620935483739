import { ETagCategory } from "@app/types/tags.types";

// If a user has selected a Commercial Role, then show the alternative name of the trades
// Example: "Carpenter" -> "Carpentry"
export const isCommercial = (category: ETagCategory | undefined) => {
  return category === ETagCategory.COMMERCIAL;
};

export const getTradeName = (name: string, category?: ETagCategory | undefined) => {
  return isCommercial(category) ? `${name}-alt` : name;
};
