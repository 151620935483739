import { useMutation } from "@tanstack/react-query";
import { Alert, Form, Input, Spin, App } from "antd";
import { useMemo } from "react";
import { updateUserSA, useUserSA } from "@app/features/super-admin/api/super-admin.user.api";

export const UserForm = ({ userId }: { userId: string }) => {
  const [form] = Form.useForm<typeof initialValues>();
  const { message } = App.useApp();

  const userQuery = useUserSA({
    userId,
  });

  const deletedUser = Boolean(userQuery.data?.deletedAt);

  const initialValues = useMemo(() => {
    return {
      internalComment: userQuery.data?.internalComment ?? "",
    };
  }, []);

  const updateUser = useMutation({
    mutationFn: updateUserSA,
    onSuccess: () => {
      message.success("Comment saved");
    },
    onSettled: () => {
      userQuery.refetch();
    },
  });

  return (
    <>
      {userQuery.isLoading && <Spin />}
      {userQuery.error && <Alert type="error" message="Failed to load user data" />}
      {initialValues && (
        <Form
          form={form}
          data-hs-do-not-collect="true"
          layout="vertical"
          initialValues={initialValues}
          style={{
            width: "100%",
          }}
          onFinish={(values) => {
            updateUser.mutate({ id: userId, internalComment: values.internalComment });
          }}
          disabled={deletedUser}
        >
          <Form.Item label="Internal comment" name="internalComment">
            <Input.TextArea
              placeholder="Enter..."
              size="large"
              autoSize={{
                minRows: 6,
                maxRows: 6,
              }}
              style={{
                fontSize: "14px",
              }}
              onBlur={() => {
                form.submit();
              }}
            />
          </Form.Item>
        </Form>
      )}
    </>
  );
};
