import { Input, InputRef } from "antd";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput, { Value } from "react-phone-number-input";
import cz from "react-phone-number-input/locale/cz.json";
import de from "react-phone-number-input/locale/de.json";
import es from "react-phone-number-input/locale/es.json";
import fr from "react-phone-number-input/locale/fr.json";
import it from "react-phone-number-input/locale/it.json";
import pl from "react-phone-number-input/locale/pl.json";
import ptBR from "react-phone-number-input/locale/pt-BR.json";
import { LANGUAGES_PHONE } from "@app/constants/languages.constants";
import { getDefaultCountry } from "@app/helpers/language.helper";
import "react-phone-number-input/style.css";

type PhoneNumberInputProps = {
  value?: string;
  onChange?: (value: Value) => void;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
};

const MyInput = forwardRef((props, ref) => {
  const inputRef = useRef<InputRef>(null);

  useImperativeHandle(
    ref,
    () => {
      return inputRef.current ? inputRef.current.input : null;
    },
    []
  );
  return <Input {...props} ref={inputRef} />;
});

export const PhoneNumberInput = ({
  value,
  onChange,
  placeholder,
  name,
  disabled,
}: PhoneNumberInputProps) => {
  const { i18n } = useTranslation();

  const handleOnChange = (newValue: Value) => {
    onChange?.(newValue);
  };

  const getLabels = () => {
    switch (i18n.language.toLowerCase()) {
      case "de":
        return de;
      case "pt_br":
        return ptBR;
      case "pl":
        return pl;
      case "it":
        return it;
      case "es":
        return es;
      case "cs":
        return cz;
      case "fr":
        return fr;
      default:
        return undefined;
    }
  };

  return (
    <PhoneInput
      defaultCountry={getDefaultCountry(i18n.language)}
      countries={LANGUAGES_PHONE}
      labels={getLabels()}
      international
      inputComponent={MyInput}
      countryCallingCodeEditable={false}
      value={value}
      onChange={handleOnChange}
      placeholder={placeholder}
      numberInputProps={{
        size: "large",
      }}
      name={name}
      disabled={disabled}
    />
  );
};
