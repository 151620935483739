import { App, FormInstance } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getIntegrationStatusMapping } from "@app/api/integration-status.api";
import { getJobAd } from "@app/api/job-ads.api";
import { getJobQuestionsByJobAdId } from "@app/api/job-questions.api";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { ModelNames } from "@app/store/models/models";
import { RootState } from "@app/store/store";
import { EJobAdStatus, JobAdDef, JobAdFormDef } from "@app/types/job-ads.types";
import { JobQuestionsFormDef } from "@app/types/job-questions.types";

export const useFetchJobAdById = (
  id: string | undefined,
  jobAdForm: FormInstance<JobAdFormDef>,
  questionsForm: FormInstance<JobQuestionsFormDef>,
  isCreateNewJobAd: boolean,
  isDuplicateJobAd: boolean
) => {
  const { message } = App.useApp();
  const hasIntegrations = useSelector(
    (state: RootState) => state.companyInfo.companyInfo.hasIntegrations
  );
  const [jobAdData, setJobAdData] = useState<JobAdDef>();
  const [isFetchingData, setIsFetchingData] = useState(!isCreateNewJobAd);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAndSetJobAdById = async (id: string) => {
      // Fetch job ad
      const fetchedJobAd = await getJobAd(id);
      if (isDuplicateJobAd) {
        fetchedJobAd.id = "";
        fetchedJobAd.slug = "";
        fetchedJobAd.unpublishedAt = null;
        fetchedJobAd.status = EJobAdStatus.DRAFT;
      }
      setJobAdData(fetchedJobAd);
      jobAdForm.setFieldsValue(fetchedJobAd);
      questionsForm.setFieldsValue({
        documentsRequired: fetchedJobAd.documentsRequired,
      });
    };

    const fetchAndSetJobQuestions = async (jobAdId: string) => {
      // Fetch questions for job ad
      const fetchedJobQuestions = await getJobQuestionsByJobAdId(jobAdId);
      if (isDuplicateJobAd) {
        fetchedJobQuestions.forEach((item) => {
          item.id = undefined;
        });
      }
      questionsForm.setFieldsValue({
        questions: fetchedJobQuestions,
      });
    };

    const fetchAndSetATSIntegrationStatusMapping = async (jobAdId: string) => {
      // Fetch integration status mapping
      const integrationStatusMapping = await getIntegrationStatusMapping({
        jobAdId,
      });
      if (integrationStatusMapping?.length) {
        jobAdForm.setFieldsValue({
          integrationStatusMapId: integrationStatusMapping[0].integrationStageId,
          integrationStatusMap: integrationStatusMapping[0],
        });
      }
    };

    const fetchAndSetJobAdFormsByJobAdId = async (jobAdId: string) => {
      try {
        await Promise.all([
          fetchAndSetJobAdById(jobAdId),
          fetchAndSetJobQuestions(jobAdId),
          hasIntegrations ? fetchAndSetATSIntegrationStatusMapping(jobAdId) : undefined,
        ]);
      } catch {
        message.error({ content: t("Sorry, something went wrong."), key: ModelNames.JOB_ADS });
        navigate(RoutePaths.JOB_ADS, { replace: true });
      } finally {
        setIsFetchingData(false);
      }
    };
    if (typeof id === "string" && (!isCreateNewJobAd || isDuplicateJobAd)) {
      fetchAndSetJobAdFormsByJobAdId(id);
    }
  }, [id, isDuplicateJobAd]);

  return { jobAdData, setJobAdData, isFetchingData };
};
