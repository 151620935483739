import { QuestionCircleOutlined } from "@ant-design/icons";
import { Descriptions, Flex, Spin, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import {
  useJobQuestionAnswerSA,
  useJobsQuestionsSA,
} from "@app/features/super-admin/api/super-admin.job.api";
import { JobAnswerDef } from "@app/types/job-answers.types";
import { EJobQuestionAnswerType, EJobQuestionType } from "@app/types/job-questions.types";

export const ScreeningQuestions = ({
  jobAdId,
  workerProfileId,
}: {
  jobAdId: string;
  workerProfileId: string;
}) => {
  const questionsQuery = useJobsQuestionsSA({
    jobAdId,
    workerProfileId,
    withAnswers: true,
  });

  return (
    <Flex vertical gap="small">
      <Flex gap="small" align="center">
        <QuestionCircleOutlined style={{ fontSize: 20 }} />
        <Typography.Text strong>
          Screening questions {questionsQuery.data && <>({questionsQuery.data.length})</>}
        </Typography.Text>
      </Flex>
      {questionsQuery.data?.length !== 0 && (
        <Descriptions
          bordered
          size="small"
          column={1}
          layout="vertical"
          items={questionsQuery.data?.map((question) => ({
            key: question.id,
            label: (
              <Flex wrap align="center">
                {question.title}
                <Tag
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  {question.answerType === EJobQuestionAnswerType.OPTION && (
                    <>{question.answerChoice}-</>
                  )}
                  {question.answerType}
                </Tag>
                {question.type === EJobQuestionType.REQUIRED && <Tag>required</Tag>}
              </Flex>
            ),
            children: (
              <Answer
                questionId={question.id}
                answerType={question.answerType}
                workerProfileId={workerProfileId}
              />
            ),
          }))}
        />
      )}
    </Flex>
  );
};

const Answer = ({
  questionId,
  answerType,
  workerProfileId,
}: {
  questionId: string;
  answerType: string;
  workerProfileId: string;
}) => {
  const { t } = useTranslation();
  const answerQuery = useJobQuestionAnswerSA({
    questionId,
    workerProfileId,
  });

  const getAnswerText = (answer: JobAnswerDef) => {
    if (answerType === EJobQuestionAnswerType.OPTION) {
      if (answer.options.length === 0) {
        return "No answer";
      }
      return (
        <>
          {answer.options.map((option) => (
            <div key={option.id}> {option.text}</div>
          ))}
        </>
      );
    }
    return answer.answer;
  };

  if (answerQuery.isLoading) {
    return <Spin />;
  }

  return (
    <div>
      {!answerQuery.data && (
        <Typography.Text type="secondary">{t("chat-no-answer")}</Typography.Text>
      )}
      {answerQuery.data && getAnswerText(answerQuery.data)}
    </div>
  );
};
