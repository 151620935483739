import { UniqueIdentifier } from "@dnd-kit/core";
import { Badge, Spin, Typography } from "antd";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CandidateStatusIcon } from "@app/components/pages/Candidates/components/CandidateStatusIcon/CandidateStatusIcon";
import { RootState } from "@app/store/store";
import { ECandidateStatus } from "@app/types/candidate.types";
import styles from "./ContainerHeader.module.scss";

type ContainerHeaderProps = {
  containerId: UniqueIdentifier;
};

export const ContainerHeader = memo(({ containerId }: ContainerHeaderProps) => {
  const { t } = useTranslation();
  const candidateStatuses = useSelector((state: RootState) => state.candidates.candidateStatuses);
  const candidatesCounter = useSelector((state: RootState) => state.candidates.candidatesCounter);
  const candidateStatusesLoading = useSelector(
    (state: RootState) => state.candidates.candidateStatusesLoading
  );
  const candidateStatus = candidateStatuses.find((item) => item.id === containerId);
  const loading = candidateStatusesLoading[containerId] || false;

  if (!candidateStatus) {
    return null;
  }

  return (
    <>
      <Typography.Title level={5} style={{ margin: 0 }}>
        {candidateStatus.status === ECandidateStatus.CUSTOM
          ? candidateStatus.name
          : t(candidateStatus.name)}
        <Badge
          style={{ marginLeft: 8, color: "black", background: "white", fontWeight: 800 }}
          count={candidatesCounter[candidateStatus.id]}
          overflowCount={9999}
        />
      </Typography.Title>

      <div className={styles.icon}>
        {loading ? <Spin /> : <CandidateStatusIcon candidateStatusName={containerId as string} />}
      </div>
    </>
  );
});
