import {
  ApiCompanyBenefitResponse,
  Benefit,
  IncomingBenefit,
  PostCompanyBenefit,
  SavedCompanyBenefit,
  UpdateCompanyBenefit,
} from "@app/types/company-data.types";
import { api } from "./api";

const BENEFITS_URL = "/crafthunts/benefits";
const COMPANY_BENEFITS_URL = "/crafthunts/company_benefits";

export default {
  /** Benefits */
  async getBenefits(): Promise<Benefit[]> {
    const response = await api.get<IncomingBenefit[]>(BENEFITS_URL);
    return response.data.map(
      (item) =>
        ({
          id: item.id,
          title: item.title,
          imageUrl: item.imageUrl,
        } as Benefit)
    );
  },

  /** Company Benefits */
  async getCompanyBenefits(): Promise<SavedCompanyBenefit[]> {
    const response = await api.get<ApiCompanyBenefitResponse[]>(COMPANY_BENEFITS_URL);
    return response.data.map((i) => ({
      id: i.id,
      benefitId: i.benefitId,
      description: i.description,
      isActive: i.isActive,
    }));
  },
  async addCompanyBenefit(payload: PostCompanyBenefit): Promise<SavedCompanyBenefit> {
    const response = await api.post<ApiCompanyBenefitResponse>(`${COMPANY_BENEFITS_URL}`, payload);
    return {
      id: response.data.id,
      benefitId: response.data.benefitId,
      description: response.data.description,
      isActive: response.data.isActive,
    };
  },
  async updateCompanyBenefit(payload: UpdateCompanyBenefit): Promise<SavedCompanyBenefit> {
    const response = await api.put<ApiCompanyBenefitResponse>(
      `${COMPANY_BENEFITS_URL}/${payload.id}`,
      payload
    );
    return {
      id: response.data.id,
      benefitId: response.data.benefitId,
      description: response.data.description,
      isActive: response.data.isActive,
    };
  },
};
