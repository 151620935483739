import { createModel } from "@rematch/core";
import { api } from "@app/api/api";
import { generateCVApiCached } from "@app/api/generated-cv.api";
import * as authApi from "@app/features/auth/api/auth.api";
import { AUTH_TOKEN_KEY } from "@app/features/auth/constants/auth.constants";
import { getBearerToken, parseJwt } from "@app/features/auth/helpers/auth.helper";
import { onLogin } from "@app/features/auth/helpers/auth.on-login.helper";
import {
  ICompanyClaimPayload,
  IPasswordChangePayload,
  LocalForgatPasswordForm,
  LocalLoginForm,
} from "@app/features/auth/types/auth.types";
import { disableAnalytics, resetAnalytics, triggerIdentify } from "@app/helpers/analytics.helper";
import { RootModel } from "@app/store/models/models";

export const auth = createModel<RootModel>()({
  state: {
    token: localStorage.getItem(AUTH_TOKEN_KEY) as string | null,
    parsedToken: parseJwt(localStorage.getItem(AUTH_TOKEN_KEY) as string),
    loggedIn: false,
    email: null as string | null,
    fullName: null as string | null,
    firstName: null as string | null,
    lastName: null as string | null,
    magicLink: null as string | null,
  },
  reducers: {
    setToken: (state, payload: string) => {
      localStorage.setItem(AUTH_TOKEN_KEY, payload);
      const parsed = parseJwt(payload);
      if (!parsed) {
        return;
      }
      // Disable analytics when super admin logs in as another company
      if (parsed.isSuperAdmin) {
        disableAnalytics();
      } else {
        triggerIdentify(parsed.id, {
          email: parsed.email,
          phone: parsed.phone,
          companyName: parsed.company.name,
        });
      }
      return {
        ...state,
        token: payload,
        parsedToken: parsed,
        loggedIn: true,
        email: parsed.email,
        fullName: `${parsed.firstName} ${parsed.lastName}`,
        firstName: parsed.firstName,
        lastName: parsed.lastName,
      };
    },
    removeToken: (state) => {
      localStorage.removeItem(AUTH_TOKEN_KEY);
      localStorage.removeItem("super-token");
      delete api.defaults.headers.common.Authorization;
      delete generateCVApiCached.defaults.headers.common.Authorization;
      return {
        ...state,
        token: null,
        superToken: null,
        loggedIn: false,
        email: null,
      };
    },
    setMagicLink: (state, payload: string) => ({
      ...state,
      magicLink: payload,
    }),
  },
  effects: (dispatch) => ({
    setTokenData: async (payload: string) => {
      api.defaults.headers.common.Authorization = getBearerToken(payload);
      generateCVApiCached.defaults.headers.common.Authorization = getBearerToken(payload);
      const parsed = parseJwt(payload);
      if (!parsed) {
        return;
      }
      const companyId = parsed.company.id;
      dispatch.companyData.setCompanyId(companyId);
      await onLogin(dispatch, parsed);
      dispatch.auth.setToken(payload);
    },
    login: async (payload: LocalLoginForm) => {
      try {
        const { data } = await authApi.login(payload.email, payload.password);
        const parsedToken = parseJwt(data.accessToken);
        if (!parsedToken) {
          throw new Error("Invalid token");
        }
        if (!parsedToken.isCrafthuntCompanyAdmin) {
          throw new Error("Not a company admin");
        }
        await dispatch.auth.setTokenData(data.accessToken);
      } catch (e) {
        console.error(e);
        dispatch.auth.removeToken();
        throw e;
      }
    },
    claim: async (payload: ICompanyClaimPayload) => {
      try {
        await authApi.claim(payload);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    forgotPassword: async (payload: LocalForgatPasswordForm) => {
      try {
        await authApi.forgotPassword(payload.email);
        return true;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    refreshToken: async (token: string) => {
      try {
        const result = await authApi.refreshToken(token);
        await dispatch.auth.setTokenData(result.data.accessToken);
      } catch (e) {
        console.error(e);
        dispatch.auth.removeToken();
        throw e;
      }
    },
    getMagicLink: async () => {
      try {
        const result = await authApi.getMagicLink();
        dispatch.auth.setMagicLink(result.data.message);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    logout: async () => {
      dispatch.auth.removeToken();
      // this 2 states need to be cleared on logout otherwise the current state value will be shown to the next company you login.
      // the component wont be visible for not super admin user but the superAdmin state will remain so needs to be cleared.
      dispatch.superAdmin.clearAdminData();
      // the component wont be visible for not paid job ads user but state will remain so needs to be cleared.
      // the jobAds state will be updated when the company visit job ad page if the company has paid for it.
      dispatch.jobAds.clearAllJobAdAction();
      resetAnalytics();
    },
    changePassword: async (payload: IPasswordChangePayload, state) => {
      try {
        const token = state.auth.token;
        if (token?.length) {
          const parsedToken = parseJwt(token);
          if (!parsedToken) {
            return;
          }
          await authApi.changePassword(parsedToken.id, payload);
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  }),
});
