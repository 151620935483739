import { InputStatus } from "antd/lib/_util/statusUtils";
import { AddressDef, CoordinateDef } from "./address.types";

export interface CompanyInfoType {
  id: string;
  name: string;
  description: string;
  address: AddressDef;
  coverImage: string | null;
  coordinates: CoordinateDef;
  logo: string | null;
  approvalStatus: ApprovalStatusEnum;
  hasPaidForJobAds: boolean;
  hasIntegrations: boolean;
  lang: string;
  companyIndustriesId: string[];
}

export type InfoFieldsStatusType = Record<string, InputStatus>;
export interface UpdateApprovalStatus {
  id: string;
  approvalStatus: ApprovalStatusEnum;
}

export enum ApprovalStatusEnum {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
  NOT_STARTED = "not-started",
}

export interface ApiCompanyInfoResponse {
  id: string;
  slug: string;
  name: string;
  description: string;
  lang: string;
  image: string | null;
  coverImage: string | null;
  address: AddressDef | null;
  coordinate: CoordinateDef | null;
  approvalStatus: ApprovalStatusEnum;
  hasPaidForJobAds: boolean;
  hasIntegrations: boolean;
  companyIndustriesId: string[];
  createdAt: string;
  updatedAt: string;
}

export interface CompanyBioInputType {
  type: string;
  sourceLang: string;
}

export interface GenerateCompanyBioResponse {
  data: string;
}
