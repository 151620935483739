import { Alert, Col, Row, Typography } from "antd";
import moment from "moment";
import { DateFormats } from "@app/constants/date.constants";
import {
  ActivityLogDef,
  IActivityLogContentNote,
} from "@app/features/super-admin/types/super-admin.activity-log.types";

export const ActivityLogNote = ({ activityLog }: { activityLog: ActivityLogDef }) => {
  const data = activityLog.content as IActivityLogContentNote;
  return (
    <Alert
      type="success"
      key={activityLog.id}
      message={
        <Row wrap={false}>
          <Col flex="1">
            <Typography.Text style={{ whiteSpace: "pre-line" }}>{data.message}</Typography.Text>
            <div>
              <Typography.Text
                type="secondary"
                style={{
                  fontSize: "12px",
                }}
              >
                {moment(activityLog.createdAt).format(DateFormats.FULL_DATE_TIME)}
                {activityLog.superAdmin?.firstName ? ` - ${activityLog.superAdmin.firstName}` : ""}
              </Typography.Text>
            </div>
          </Col>
        </Row>
      }
    />
  );
};
