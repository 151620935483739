import { useQuery } from "@tanstack/react-query";
import {
  CertificateDef,
  CreateCertificateDef,
  CreateHighlightDef,
  CreateTimelineDef,
  EditCertificateDef,
  EditTimelineDef,
  EditUserDef,
  EditUserStatsDef,
  GetUserRelevantJobsDef,
  GetUsersSAParamsDef,
  HighlightDef,
  HighlightWithPaginationDef,
  TimelineDef,
  UserJobsWithPaginationDef,
  UserStatsResponseDef,
  UsersSAResponseDef,
  UsersSAWithPaginationDef,
} from "@app/features/super-admin/types/super-admin.user.types";
import { queryKeysSA } from "./queryKeys";
import { deleteCached, getCached, postCached, putCached, superAdminApi } from "./super-admin.api";

// USERS
export const getUsersSA = (params: GetUsersSAParamsDef) => {
  return superAdminApi.get<UsersSAWithPaginationDef>("/super_admin/users", {
    params,
  });
};
const getUserSA = ({ id, ...params }: { id: string; lang?: string }) => {
  return superAdminApi.get<UsersSAResponseDef>(`/super_admin/users/${id}`, {
    params,
  });
};
export const updateUserSA = (payload: Partial<EditUserDef>) => {
  return superAdminApi.put<UsersSAResponseDef>(`/worker_profiles/info/${payload.id}`, payload);
};

// TIMELINE
export const getUserTimelineSA = (workerProfileId: string) => {
  return getCached<TimelineDef[]>(
    "/crafthunts/timelines" + workerProfileId,
    "/crafthunts/timelines",
    {
      workerProfileId,
    }
  );
};
export const postUserTimelineSA = (workerProfileId: string, data: CreateTimelineDef) => {
  return postCached<TimelineDef>(
    "/crafthunts/timelines" + workerProfileId,
    "/crafthunts/timelines",
    {
      workerProfileId,
      ...data,
    }
  );
};
export const editUserTimelineSA = (id: string, workerProfileId: string, data: EditTimelineDef) => {
  return putCached<TimelineDef>(
    "/crafthunts/timelines" + workerProfileId,
    `/crafthunts/timelines/${id}`,
    {
      workerProfileId,
      ...data,
    }
  );
};
export const deleteUserTimelineSA = (id: string, workerProfileId: string) => {
  return deleteCached("/crafthunts/timelines" + workerProfileId, `/crafthunts/timelines/${id}`, {
    workerProfileId,
  });
};

// CERTIFICATES
export const getUserCertificatesSA = (workerProfileId: string) => {
  return getCached<CertificateDef[]>(
    "/profile_certificates" + workerProfileId,
    "/profile_certificates",
    {
      workerProfileId,
    }
  );
};
export const postUserCertificateSA = (workerProfileId: string, data: CreateCertificateDef) => {
  return postCached<CertificateDef>(
    "/profile_certificates" + workerProfileId,
    "/profile_certificates",
    {
      workerProfileId,
      ...data,
    }
  );
};
export const editUserCertificateSA = (
  id: string,
  workerProfileId: string,
  data: EditCertificateDef
) => {
  return putCached<CertificateDef>(
    "/profile_certificates" + workerProfileId,
    `/profile_certificates/${id}`,
    {
      workerProfileId,
      ...data,
    }
  );
};
export const deleteUserCertificateSA = (id: string, workerProfileId: string) => {
  return deleteCached("/profile_certificates" + workerProfileId, `/profile_certificates/${id}`, {
    workerProfileId,
  });
};
// HIGHLIGHTS
export const getUserHighlightsSA = (workerProfileId: string) => {
  return getCached<HighlightWithPaginationDef>(
    "/crafthunts/images" + workerProfileId,
    "/crafthunts/images",
    {
      workerProfileId,
    }
  );
};
export const postUserHighlightSA = (workerProfileId: string, data: CreateHighlightDef) => {
  return postCached<HighlightDef>("/crafthunts/images" + workerProfileId, "/crafthunts/images", {
    profileId: workerProfileId,
    ...data,
  });
};
export const deleteUserHighlightSA = (id: string, workerProfileId: string) => {
  return deleteCached("/crafthunts/images" + workerProfileId, `/crafthunts/images/${id}`, {
    workerProfileId,
  });
};
// STATS
export const getUserStatsSA = (workerProfileId: string) => {
  return getCached<UserStatsResponseDef>(
    "/crafthunts/stats" + workerProfileId,
    "/crafthunts/stats",
    { workerProfileId }
  );
};
export const editUserStatsSA = (data: EditUserStatsDef) => {
  return putCached<UserStatsResponseDef>(
    "/crafthunts/stats" + data.profileId,
    `/crafthunts/stats/${data.id}`,
    data
  );
};

const getUserRelevantJobsSA = (params: GetUserRelevantJobsDef) => {
  return superAdminApi.get<UserJobsWithPaginationDef>("/crafthunts/job_ads", {
    params,
  });
};

export const useUserRelevantJobsSA = (params: GetUserRelevantJobsDef) =>
  useQuery({
    queryKey: [...queryKeysSA.lists(), "relevant-jobs", params] as const,
    queryFn: ({ queryKey }) => getUserRelevantJobsSA(queryKey[3]).then(({ data }) => data),
    refetchInterval: 60000,
  });

export const useUserSA = (params: { userId?: string | null; lang?: string }) =>
  useQuery({
    enabled: Boolean(params.userId),
    queryKey: [...queryKeysSA.all, "user", params] as const,
    queryFn: ({ queryKey }) =>
      getUserSA({
        id: queryKey[2].userId ?? "",
        lang: params.lang,
      }).then(({ data }) => data),
  });

export const useUsersSA = (params: GetUsersSAParamsDef) =>
  useQuery({
    queryKey: [...queryKeysSA.lists(), "users", params] as const,
    queryFn: ({ queryKey }) => getUsersSA(queryKey[3]).then(({ data }) => data),
  });

export const useUserCertificatesSA = (params: { workerProfileId?: string }) =>
  useQuery({
    enabled: Boolean(params.workerProfileId),
    queryKey: [...queryKeysSA.all, "certificates", params] as const,
    queryFn: ({ queryKey }) =>
      superAdminApi
        .get<CertificateDef[]>("/profile_certificates", {
          params: queryKey[2],
        })
        .then(({ data }) => data),
  });

export const useUserStatsSA = (params: { workerProfileId?: string }) =>
  useQuery({
    enabled: Boolean(params.workerProfileId),
    queryKey: [...queryKeysSA.all, "stats", params] as const,
    queryFn: ({ queryKey }) =>
      superAdminApi
        .get<UserStatsResponseDef>("/crafthunts/stats", {
          params: queryKey[2],
        })
        .then(({ data }) => data),
  });
