import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Alert, Form, Modal, Tabs, App } from "antd";
import { useForm } from "antd/lib/form/Form";
import { parseAsString, useQueryState } from "nuqs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { queryKeysSA } from "@app/features/super-admin/api/queryKeys";
import {
  updateCompanySettingsSA,
  useCompanySA,
} from "@app/features/super-admin/api/super-admin.company.api";
import {
  CompanyFormSADef,
  ECompanyAdvertisement,
} from "@app/features/super-admin/types/super-admin.company.types";
import { CompanyAdvancedForm } from "./components/CompanyAdvancedForm/CompanyAdvancedForm";
import { CompanyAdvertisements } from "./components/CompanyAdvertisements/CompanyAdvertisements";
import { CompanyForm } from "./components/CompanyForm/CompanyForm";

export const useCompanyModal = () => {
  const [companyId, setCompanyId] = useQueryState("companyId", parseAsString);

  return {
    companyId,
    setCompanyId,
  };
};

enum TAB_KEYS {
  INFO = "info",
  ADVERTISEMENTS = "advertisements",
  ADVANCED = "advanced",
}

export const CompanyModal = () => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [form] = useForm<CompanyFormSADef>();
  const [activeTab, setActiveTab] = useState<TAB_KEYS>(TAB_KEYS.INFO);

  const queryClient = useQueryClient();
  const { companyId, setCompanyId } = useCompanyModal();

  const companyQuery = useCompanySA({
    companyId,
  });

  const company = companyQuery.data;

  const initialValues = {
    ...company,
    enabledAdvertisements:
      company?.enabledAdvertisements.reduce((acc, item) => ({ ...acc, [item]: true }), {}) || {},
  };

  const changeCompanySettings = useMutation({
    mutationFn: () => {
      const formValues = form.getFieldsValue();
      // Transform the object of enabledAdvertisements to array of enabled ones
      const enabledAdvertisements = Object.entries(formValues.enabledAdvertisements)
        .filter(([, value]) => value)
        .map(([key]) => key as ECompanyAdvertisement);

      return company
        ? updateCompanySettingsSA({ ...company, ...formValues, enabledAdvertisements })
        : Promise.reject();
    },
    onSuccess: () => {
      message.success("Company settings updated");
      queryClient.invalidateQueries({
        queryKey: queryKeysSA.all,
      });
      setCompanyId(null);
    },
  });

  useEffect(() => {
    if (company) {
      form.resetFields();
    }
  }, [JSON.stringify(company)]);

  const onChangeTab = (key: string) => {
    setActiveTab(key as TAB_KEYS);
  };

  const handleOnCancel = () => {
    setCompanyId(null);
  };

  const handleReset = () => {
    form.resetFields();
    setActiveTab(TAB_KEYS.INFO);
  };

  return (
    <Modal
      title={company?.name ?? "Company"}
      open={Boolean(companyId)}
      onCancel={handleOnCancel}
      onOk={() => changeCompanySettings.mutate()}
      okButtonProps={{ size: "large" }}
      cancelButtonProps={{ size: "large" }}
      forceRender
      destroyOnClose
      afterClose={handleReset}
    >
      {companyQuery.isLoading && <LoadingSpinner />}
      {companyQuery.isError && <Alert message={t("Sorry, something went wrong.")} type="error" />}
      {company && (
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          labelAlign="right"
          form={form}
          initialValues={initialValues}
          data-hs-do-not-collect="true"
        >
          {changeCompanySettings.isPending && <LoadingSpinner />}

          <Tabs
            activeKey={activeTab}
            onChange={onChangeTab}
            items={[
              {
                key: TAB_KEYS.INFO,
                label: t("Info"),
                children: <CompanyForm company={company} />,
                forceRender: true,
                destroyInactiveTabPane: false,
              },
              {
                key: TAB_KEYS.ADVERTISEMENTS,
                label: "Advertisements",
                children: <CompanyAdvertisements />,
                forceRender: true,
                destroyInactiveTabPane: false,
              },
              {
                key: TAB_KEYS.ADVANCED,
                label: "Advanced",
                children: <CompanyAdvancedForm />,
                forceRender: true,
                destroyInactiveTabPane: false,
              },
            ]}
          />
        </Form>
      )}
    </Modal>
  );
};
