import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ENV } from "@app/constants/env";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { RootState } from "@app/store/store";

export const useLiveURL = () => {
  const { i18n } = useTranslation();
  const slug = useSelector((state: RootState) => state.companyInfo.slug);
  return `${ENV.WEB_URL}/${convertLangForBackend(i18n.language)}/companies/${slug}`;
};
