import { UploadFileDef } from "@app/features/super-admin/types/super-admin.files.types";
import { FileDef } from "@app/features/super-admin/types/super-admin.user.types";

export const mapFilesToApi = (files: UploadFileDef[]): FileDef[] => {
  return files.map(
    (file) =>
      ({
        id: file.uid,
        url: file.url,
        filename: file.name,
        type: file.type,
      } as FileDef)
  );
};
export const mapApiFilesToForm = (files: FileDef[]): UploadFileDef[] => {
  return files.map((file) => ({
    uid: file.id,
    url: file.url,
    name: file.filename || file.url,
    type: file.type,
    createdAt: file.createdAt,
  }));
};
