import axios from "axios";
import { setupCache } from "axios-cache-interceptor";
import { GeneratedCVDef } from "@app/types/generated-cv.types";
import { apiConfig } from "./api";

export const generateCVApiCached = setupCache(axios.create(apiConfig), {
  // add the methods we want to cache
  methods: ["post"],
});

export const generateCV = (lang: string, workerProfileId: string) => {
  return generateCVApiCached.post<GeneratedCVDef>(
    `/crafthunts/cv_generator`,
    { lang, workerProfileId },
    {
      id: lang + workerProfileId,
    }
  );
};
