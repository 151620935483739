import { INVALID_DOMAINS } from "@app/constants/validation.constants";

export const mapUndefinedToNull = <T>(data: T): T => {
  if (data === null || typeof data === "undefined") {
    return null as T;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((data as any).constructor.name === "Object" || Array.isArray(data)) {
    const copyOfData = (Array.isArray(data) ? [...data] : { ...data }) as T;
    for (const key in copyOfData) {
      copyOfData[key] = mapUndefinedToNull(copyOfData[key]);
    }
    return copyOfData;
  }
  return data;
};

export const isInvalidCompanyEmail = (email?: string) => {
  // Get the company domain
  const domain = email?.split("@")?.[1] || "";
  // Check if domain is a generic personal provider
  return domain && INVALID_DOMAINS.includes(domain);
};
