import { Col, Form, Input, Row, App } from "antd";
import { Rule } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AddressPicker, EAddressPickerType } from "@app/components/ui/AddressPicker/AddressPicker";
import { MomentDatePicker } from "@app/components/ui/DatePicker";
import { FormModal } from "@app/components/ui/FormModal/FormModal";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { DateFormats } from "@app/constants/date.constants";
import {
  editUserCertificateSA,
  postUserCertificateSA,
} from "@app/features/super-admin/api/super-admin.user.api";
import { mapCertificateToApi } from "@app/features/super-admin/helpers/super-admin.helper";
import {
  CertificateFormDef,
  CreateCertificateDef,
  EditCertificateDef,
} from "@app/features/super-admin/types/super-admin.user.types";
import { ApiFormErrorDef, getErrorMessages } from "@app/helpers/error.helper";
import { RootState } from "@app/store/store";

type CertificateModalProps = {
  open: boolean;
  workerProfileId: string;
  selectedCertificate?: CertificateFormDef;
  onSave: () => void;
  onCancel: () => void;
};

export const CertificateModal = ({
  open,
  workerProfileId,
  selectedCertificate,
  onSave,
  onCancel,
}: CertificateModalProps) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [form] = useForm<CertificateFormDef>();
  const certificateTags = useSelector((state: RootState) => state.tags.certificates);

  const editMode = !!selectedCertificate?.id;
  const requiredRule: Rule = { required: true, whitespace: true, message: t("required") };
  const certificateTagsChoices = certificateTags.map((i) => ({
    value: i.id,
    text: t(i.name),
  }));

  useEffect(() => {
    form.resetFields();
  }, [JSON.stringify(selectedCertificate)]);

  const onSubmitForm = async (values: CertificateFormDef) => {
    const certificateTag = certificateTags.find((item) => item.id === values.typeId);
    if (!certificateTag) {
      message.error({
        content: "missing tag",
        key: "user-certificates",
        duration: 10,
      });
      return;
    }
    try {
      const certificateData = mapCertificateToApi({
        ...values,
        title: certificateTag.name,
      });
      if (selectedCertificate?.id) {
        await editUserCertificateSA(
          selectedCertificate.id,
          workerProfileId,
          certificateData as EditCertificateDef
        );
      } else {
        await postUserCertificateSA(workerProfileId, certificateData as CreateCertificateDef);
      }
      message.success({ content: t("Saved!"), key: "user-certificates" });
      onSave();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error({
          content: getErrorMessages(error as ApiFormErrorDef),
          key: "user-certificates",
          duration: 10,
        });
      }
    }
  };

  return (
    <FormModal
      title={t(editMode ? "Edit" : "Add")}
      open={open}
      onFinish={onSubmitForm}
      onCancel={onCancel}
      form={form}
      initialValues={selectedCertificate}
      id="certificate-form"
    >
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item label={t("Title")} name={"typeId"} rules={[requiredRule]}>
            <SelectAutocomplete
              placeholder={t("Select")}
              options={certificateTagsChoices.map((item) => ({
                value: item.value,
                label: item.text,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("Description")} name={"description"}>
            <Input.TextArea
              size="large"
              autoSize={{ minRows: 4, maxRows: 8 }}
              style={{ width: "100%" }}
              placeholder={t("Enter...")}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("Country")} name={"address"}>
            <AddressPicker type={EAddressPickerType.COUNTRY} disableMap />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("Issue date")} name="issueDate">
            <MomentDatePicker
              picker="month"
              size="large"
              format={DateFormats.M_Y}
              allowClear
              placeholder={DateFormats.M_Y}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
    </FormModal>
  );
};
