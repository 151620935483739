import { Moment } from "moment-timezone";
import { AddressDef, AddressObjDef, CoordinateDef } from "@app/types/address.types";
import { ECandidateMethod, ECandidateStatus, IGeoSearchAddress } from "@app/types/candidate.types";
import { JobAdDef } from "@app/types/job-ads.types";
import {
  OrderByRequestDef,
  PaginationRequestDef,
  PaginationResponseDef,
} from "@app/types/pagination.types";
import { CertificateTagDef, JobTitleTagDef, TradeTagDef } from "@app/types/tags.types";
import { UploadFileDef } from "./super-admin.files.types";

export enum DocumentTypeEnum {
  CV = "cv",
  VISA = "visa",
  IMAGE = "image",
}

export enum ExternalLinkTypeEnum {
  DOMAIN = "domain",
  YOUTUBE = "youtube",
  INSTAGRAM = "instagram",
  FACEBOOK = "facebook",
  LINKEDIN = "linkedin",
  TIKTOK = "tiktok",
  KUNUNU = "kununu",
  XING = "xing",
}

export enum EUserType {
  COMPANY_ADMIN_USER = "company-admin-user",
  NORMAL_USER = "normal-user",
}

export type FileDef = {
  id: string;
  url: string;
  type: DocumentTypeEnum;
  filename?: string;
  createdAt: string;
};

export type ExternalLinkDef = {
  id: string;
  value: string;
  type: ExternalLinkTypeEnum;
};

export interface UsersSAResponseDef {
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
  slug: string | null;
  anonymousSlug: string | null;
  description: string | null;
  description_original?: string | null;
  jobTitleId: string | null;
  profileTradesId: string[];
  profileIndustriesId: string[];
  isReviewed: boolean;
  isVerified: boolean;
  isCompanyAdmin: boolean;
  isAnonymous: boolean;
  isTemporary: boolean;
  isLocked: boolean;
  getScheduledJobRecommendations: boolean;
  address: AddressDef | null;
  coordinate: CoordinateDef | null;
  geoSearchAddress: IGeoSearchAddress | null;
  birthday: string | null;
  internalComment: string | null;
  medias: FileDef[];
  externalLinks: ExternalLinkDef[];
  distance?: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  indeedEmails: string[];
  referralLink: string | null;
  picture: string | null;
}

export type AdminUserFormDef = {
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
  isReviewed: boolean;
};

export type UserFormDef = {
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
  description: string | null;
  jobTitleId: string | null;
  profileTradesId: string[];
  profileIndustriesId: string[];
  isReviewed: boolean;
  isVerified: boolean;
  isAnonymous: boolean;
  isTemporary: boolean;
  isLocked: boolean;
  getScheduledJobRecommendations: boolean;
  isCompanyAdmin: boolean;
  address: AddressObjDef | null;
  geoSearchAddress: AddressObjDef | null;
  birthday: Moment | null;
  internalComment: string | null;
  medias: UploadFileDef[];
  externalLinks: ExternalLinkDef[];
  indeedEmails: string[];
  referralLink: string | null;
  picture: string | null;
  slug?: string | null;
  anonymousSlug?: string | null;
  updatedAt: string;
  deletedAt?: string | null;
};
export type UserAdvancedFormDef = {
  isTemporary: boolean;
  isLocked: boolean;
  getScheduledJobRecommendations: boolean;
};
export type EditUserDef = {
  id: string;
  lang: string;
  medias: FileDef[];
  address: AddressDef | null;
  coordinate: CoordinateDef | null;
  geoSearchAddress: IGeoSearchAddress | null;
  birthday: string | null;
} & Omit<Partial<UserFormDef>, "id" | "address" | "geoSearchAddress" | "medias" | "birthday">;

export type GetUsersSAParamsDef = PaginationRequestDef &
  OrderByRequestDef & {
    keyword?: string;
    tradeIds?: string[];
    jobTitleIds?: string[];
    coordinates?: string;
    userType?: EUserType;
    startDate?: string;
    endDate?: string;
  };

export type UsersSAWithPaginationDef = PaginationResponseDef<UsersSAResponseDef>;

// TIMELINE
interface TimelineCompanyDef {
  id: string;
  name: string;
  logo?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  slug?: string;
}

export interface TimelineDef {
  id: string;
  jobTitle?: JobTitleTagDef | null;
  jobTitleId?: string;
  trades?: TradeTagDef[] | null;
  companyInfo: TimelineCompanyDef;
  startDate: string;
  endDate: string;
  profileId: string;
  createdAt: string;
  updatedAt: string;
  address: AddressDef | null;
  coordinate: CoordinateDef | null;
}

export interface TimelineFormDef {
  id?: string;
  companyId?: { value: string; label: string };
  jobTitleId?: string;
  tradesId?: string[] | null;
  startDate: Moment;
  endDate?: Moment | null;
  address: AddressObjDef | null;
}

export interface CreateTimelineDef {
  companyId: string;
  jobTitleId?: string;
  tradesId?: string[] | null;
  startDate: string;
  endDate?: string | null;
  address?: AddressDef | null;
  coordinate?: CoordinateDef | null;
}
export interface EditTimelineDef extends CreateTimelineDef {
  id: string;
}

// CERTIFICATES
export type CertificateDef = {
  id: string;
  title: string;
  typeId: string;
  type: CertificateTagDef | null;
  description?: string | null;
  issueDate?: string | null;
  address: AddressDef | null;
  coordinate: CoordinateDef | null;
};
export type CertificateFormDef = {
  id?: string;
  title: string;
  typeId: string;
  description?: string | null;
  issueDate: Moment | null;
  address: AddressObjDef | null;
};

export type CreateCertificateDef = {
  title: string;
  typeId: string;
  description?: string | null;
  issueDate?: string | null;
  address?: AddressDef | null;
  coordinate?: CoordinateDef | null;
};
export type EditCertificateDef = {
  id: string;
} & Partial<CreateCertificateDef>;

// HIGHLIGHTS
export type HighlightDef = {
  id: string;
  url: string;
  profileId: string;
};
export type HighlightWithPaginationDef = PaginationResponseDef<HighlightDef>;
export type HighlightFormDef = {
  id: string;
  images: UploadFileDef[];
};
export type CreateHighlightDef = {
  url: string;
};

// STATS
export enum ECraftsmanStatTypes {
  AVG_CREW_STAT = "average-crew-stat",
  EMPLOYED_IN_CONSTRUCTION_STAT = "employeed-in-construction-stat",
  AVG_PROJ_DIS_STAT = "average-project-distance-stat",
  AVG_TIME_IN_COMPANY_STAT = "average-time-in-company-stat",
  WORKED_PROJ_STAT = "worked-project-stat",
  AVG_SIZE_STAT = "average-size-stat",
  AVG_DURATION_STAT = "average-duration-stat",
  SPOKEN_LANGUAGES_STAT = "spoken-languages-stat",
}
export enum EStatUnits {
  YEAR_UNIT = "year-unit",
  EMPLOYEE_UNIT = "employee-unit",
  KM_UNIT = "km-unit",
  NO_DATA_UNIT = "no-data-unit",
  PROJECT_UNIT = "project-unit",
  MONTH_UNIT = "month-unit",
  DAY_UNIT = "day-unit",
  CURRENCY_UNIT = "currency-unit",
  LANGUAGES_UNIT = "",
}
export const StatUnitMap: Record<ECraftsmanStatTypes, EStatUnits> = Object.freeze({
  [ECraftsmanStatTypes.AVG_CREW_STAT]: EStatUnits.EMPLOYEE_UNIT,
  [ECraftsmanStatTypes.EMPLOYED_IN_CONSTRUCTION_STAT]: EStatUnits.YEAR_UNIT,
  [ECraftsmanStatTypes.AVG_PROJ_DIS_STAT]: EStatUnits.KM_UNIT,
  [ECraftsmanStatTypes.AVG_TIME_IN_COMPANY_STAT]: EStatUnits.YEAR_UNIT,
  [ECraftsmanStatTypes.WORKED_PROJ_STAT]: EStatUnits.PROJECT_UNIT,
  [ECraftsmanStatTypes.AVG_SIZE_STAT]: EStatUnits.EMPLOYEE_UNIT,
  [ECraftsmanStatTypes.AVG_DURATION_STAT]: EStatUnits.MONTH_UNIT,
  [ECraftsmanStatTypes.SPOKEN_LANGUAGES_STAT]: EStatUnits.LANGUAGES_UNIT,
});
export const ALL_STATS_WITH_NUMBERS = [
  {
    name: "projectDuration",
    label: ECraftsmanStatTypes.AVG_DURATION_STAT,
  },
  {
    name: "totalFinishedProject",
    label: ECraftsmanStatTypes.WORKED_PROJ_STAT,
  },
  {
    name: "drivingAvgDistance",
    label: ECraftsmanStatTypes.AVG_PROJ_DIS_STAT,
  },
  {
    name: "crewAvgSize",
    label: ECraftsmanStatTypes.AVG_CREW_STAT,
  },
  {
    name: "companyExperience",
    label: ECraftsmanStatTypes.AVG_TIME_IN_COMPANY_STAT,
  },
  {
    label: ECraftsmanStatTypes.EMPLOYED_IN_CONSTRUCTION_STAT,
    name: "workExperience",
  },
  {
    label: ECraftsmanStatTypes.AVG_SIZE_STAT,
    name: "companyAvgSize",
  },
];
export type UserStatsDef = {
  id: string;
  projectDuration: number;
  totalFinishedProject: number;
  drivingAvgDistance: number;
  crewAvgSize: number;
  companyExperience: number;
  workExperience: number;
  companyAvgSize: number;
  languages: string[];
};
export type UserStatsResponseDef = {
  id: string;
  key: string;
  profileId: string;
  data: {
    projectDuration: string;
    totalFinishedProject: string;
    drivingAvgDistance: string;
    crewAvgSize: string;
    companyExperience: string;
    workExperience: string;
    companyAvgSize: string;
    languages: string[];
  };
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};
export type UserStatsFormDef = {
  projectDuration: number | null;
  totalFinishedProject: number | null;
  drivingAvgDistance: number | null;
  crewAvgSize: number | null;
  companyExperience: number | null;
  workExperience: number | null;
  companyAvgSize: number | null;
  languages: string[];
};
export type EditUserStatsDef = {
  id: string;
  profileId: string;
} & UserStatsDef;

// RELEVANT JOBS
export type MatchScoreMetadataDef = {
  trades: string[] | null;
  tradesScore: number;
  roles: string[] | null;
  rolesScore: number;
  isCompanyClaimed: boolean;
  isCompanyClaimedScore: number;
  distanceKm: number | null;
  distanceScore: number;
};
export enum EJobAdWorkerProfileEngagementType {
  APPLIED = "applied",
  CAMPAIGN_RECOMMENDED = "campaign-recommended",
  APP_RECOMMENDED = "app-recommended",
  MANUALLY_RECOMMENDED = "manually-recommended",
}
export type JobMatchScoreDef = {
  candidateMethod: ECandidateMethod | null;
  candidateStatus: ECandidateStatus | null;
  engagementType: EJobAdWorkerProfileEngagementType | null;
  score: number;
  matchScoreMetadata: MatchScoreMetadataDef | null;
};
export type UserJobsDef = JobAdDef &
  JobMatchScoreDef & {
    crafthuntCompany: {
      id: string;
      name: string;
    };
  };
export type GetUserRelevantJobsDef = PaginationRequestDef & {
  workerProfileId: string;
};
export type UserJobsWithPaginationDef = PaginationResponseDef<UserJobsDef>;
