import { getCountryDataList } from "countries-list";

// The languages that are available for the required languages for a job ad
export const SPOKEN_LANGUAGES = [
  "en",
  "de",
  "pl",
  "ro",
  "es",
  "fr",
  "da",
  "it",
  "nl",
  "pt-pt",
  "pt-br",
  "uk",
  "lb",
  "sl",
  "bg",
  "bs",
  "cs",
  "el",
  "et",
  "fi",
  "hr",
  "hu",
  "hy",
  "is",
  "lt",
  "lv",
  "nb",
  "ru",
  "sk",
  "sq",
  "sv",
  "tr",
  "ar",
  "fa",
];

export const getCountryCodeByCountry = (countryName: string) => {
  return getCountryDataList().find(
    (country) =>
      country.name.toLowerCase() === countryName.toLowerCase() ||
      country.native.toLowerCase() === countryName.toLowerCase()
  )?.iso2;
};

// The countries that are available for the required countries for a job ad
export const ALLOWED_COUNTRIES_FOR_JOB = getCountryDataList()
  .filter((country) => country.continent === "EU" || country.iso2 === "TR")
  .map((country) => country.iso2);
