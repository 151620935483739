import type { AnalyticsSnippet } from "@segment/analytics-next";
import { MutationCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { App as AntdApp, ConfigProvider, ThemeConfig } from "antd";
import { getAuth, signInAnonymously } from "firebase/auth";
import posthog, { PostHogConfig } from "posthog-js";
import { PropsWithChildren, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { useMount } from "react-use";
import { MainLayout } from "./components/layouts/MainLayout/MainLayout";
import { NothingToSeeHere } from "./components/pages/404";
import { AuthLayout } from "./components/pages/Auth/AuthLayout";
import { FullPageSpinner } from "./components/ui/FullPageSpinner/FullPageSpinner";
import { ENV, isProd } from "./constants/env";
import { ALL_ROUTES_FLATTEN } from "./features/routes/constants/routes.constants";
import { RoutePaths } from "./features/routes/types/routes.types";
import { getMomentLocale } from "./helpers/moment-locale.helper";
import { useDisableWebpackErrors } from "./hooks/useDisableWebpackErrors";
import { useLoadTranslations } from "./hooks/useLoadTranslations";
import { usePageViews } from "./hooks/usePageViews";
import { Dispatch, RootState } from "./store/store";
import "./styles/global.scss";
import "./translations/i18n";

import "antd/dist/reset.css";

const theme: ThemeConfig = {
  token: {
    colorPrimary: "#f59e0b",
    colorText: "#000000",
    colorLink: "#1890ff",
    colorSuccess: "#52c41a",
    colorWarning: "#faad14",
    colorError: "#f5222d",
    colorInfo: "#f59e0b",
    fontSize: 16,
    fontSizeSM: 12,
    colorTextHeading: "rgba(0, 0, 0, 0.85)",
    colorTextDisabled: "rgba(0, 0, 0, 0.25)",
    borderRadius: 8,
    colorBorder: "#d9d9d9",
  },
  components: {
    Menu: {
      darkSubMenuItemBg: "#001529",
      itemHeight: 45,
    },
  },
};

declare global {
  interface Window {
    analytics: AnalyticsSnippet & PostHogConfig["segment"];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any;
  }
}

function App() {
  usePageViews();
  useDisableWebpackErrors();
  const { t, i18n } = useTranslation();
  const token = useSelector((state: RootState) => state.auth.token);
  const parsedToken = useSelector((state: RootState) => state.auth.parsedToken);
  const superToken = useSelector((state: RootState) => state.superAdmin.superToken);
  const parsedSuperToken = useSelector((state: RootState) => state.superAdmin.parsedSuperToken);
  const [loading, setLoading] = useState(true);
  const flatRoutes = ALL_ROUTES_FLATTEN.filter((route) => route.component);
  const { loadTranslations } = useLoadTranslations();
  const dispatch = useDispatch<Dispatch>();

  useMount(() => {
    const firebaseSignIn = async () => {
      const auth = getAuth();
      try {
        await signInAnonymously(auth);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    firebaseSignIn();
  });

  useMount(() => {
    if (isProd() && (!parsedToken?.isSuperAdmin || !superToken)) {
      window.analytics?.ready(() => {
        posthog.init(ENV.POSTHOG_API_KEY, {
          api_host: "https://eu.i.posthog.com",
          segment: window.analytics,
          capture_pageview: false,
        });
      });
    }
  });

  useMount(() => {
    loadTranslations();

    function disableBrowserTranslation() {
      document?.querySelector("[data-translate]")?.remove();
    }
    window.addEventListener("load", disableBrowserTranslation);
  });

  useMount(() => {
    if (superToken) {
      dispatch.superAdmin.setSuperAdminToken(superToken);
    }
  });

  return (
    <ConfigProvider locale={getMomentLocale(i18n.language)} theme={theme}>
      <AntdApp>
        <ReactQueryProvider>
          <Helmet htmlAttributes={{ lang: i18n.language }}>
            <title>{t("Crafthunt Admin")}</title>
            <meta name="description" content={t("Update your Crafthunt company data")} />
          </Helmet>
          {loading ? (
            <FullPageSpinner />
          ) : (
            <Routes>
              <Route path={RoutePaths.LOGIN} element={<AuthLayout />} />
              <Route path={RoutePaths.SIGN_UP} element={<AuthLayout />} />
              <Route path={RoutePaths.FORGOT_PASSWORD} element={<AuthLayout />} />
              <Route path={flatRoutes[0].key} element={<MainLayout />}>
                {flatRoutes.map((route) => {
                  if (route.key === RoutePaths.SUPER_ADMIN_STUFF) {
                    return (
                      <Route
                        key={route.key}
                        path={route.key}
                        element={
                          (parsedToken?.isSuperAdmin || parsedSuperToken) && route.component ? (
                            <route.component />
                          ) : (
                            <NothingToSeeHere />
                          )
                        }
                      />
                    );
                  }
                  return (
                    <Route
                      key={route.key}
                      path={route.key}
                      element={route.component && <route.component />}
                    />
                  );
                })}
              </Route>
              <Route
                path=""
                key="empty"
                element={<Navigate to={token ? RoutePaths.DASHBOARD : RoutePaths.LOGIN} />}
              />
              <Route
                path="*"
                key="*"
                element={
                  <div
                    style={{
                      position: "fixed",
                      top: "40%",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    <NothingToSeeHere />
                  </div>
                }
              />
            </Routes>
          )}
        </ReactQueryProvider>
      </AntdApp>
    </ConfigProvider>
  );
}

const ReactQueryProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const app = AntdApp.useApp();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({
          onError: (error, _variables, _context, mutation) => {
            if (!mutation.options.onError) {
              app.message.error(t("Sorry, something went wrong."));
            }

            console.error(error);
          },
        }),
      })
  );

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default App;
