import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  SelectAutocomplete,
  SelectAutocompleteProps,
} from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { RootState } from "@app/store/store";

export const TradesFilter = (props: SelectAutocompleteProps) => {
  const { t } = useTranslation();
  const availableTradesTags = useSelector((state: RootState) => state.tags.trades);
  const tradesChoices = availableTradesTags.map((i) => ({
    value: i.id,
    label: t(i.name),
  }));

  return (
    <SelectAutocomplete
      mode="multiple"
      placeholder={t("Trades")}
      options={tradesChoices}
      allowClear
      {...props}
    />
  );
};
