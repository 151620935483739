import { GoogleMap, GoogleMapProps, Marker } from "@react-google-maps/api";
import { useCallback, useState } from "react";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { useMap } from "@app/features/map/context/MapContext";

type MapProps = {
  address?: string;
  pin?: google.maps.LatLng | google.maps.LatLngLiteral;
} & GoogleMapProps;

const DEFAULT_CENTER: google.maps.LatLngLiteral = {
  lat: 52.52000659999999,
  lng: 13.404954,
};
const DEFAULT_ZOOM = 4;

function Map({ address, pin, zoom, ...rest }: MapProps) {
  const mapApi = useMap();
  const [position, setPosition] = useState<google.maps.LatLng>();

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    if (!address) {
      return;
    }
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        address,
      },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results?.length) {
          setPosition(results[0].geometry.location);
          map.setCenter(results[0].geometry.location);
          map.setZoom(11);
        }
      }
    );
  }, []);

  if (!mapApi.isLoaded) {
    return <LoadingSpinner />;
  }

  return (
    <GoogleMap
      mapContainerStyle={{
        height: 400,
      }}
      center={pin || DEFAULT_CENTER}
      zoom={DEFAULT_ZOOM}
      onLoad={onLoad}
      options={{
        ...(zoom ? { zoom } : undefined),
        disableDefaultUI: true,
        zoomControl: true,
      }}
      {...rest}
    >
      {pin && <Marker position={pin} />}
      {!pin && position && <Marker position={position} />}
    </GoogleMap>
  );
}

export default Map;
