export enum ECompanyIntegrationSoftware {
  PERSONIO = "personio",
  TEAMTAILOR = "teamtailor",
  DVINCI = "dvinci",
}

export enum EKomboFeature {
  JOBS = "jobs",
  JOB_POSTING = "job-posting",
  SCREENING_QUESTIONS = "screening-questions",
  APPLICATIONS = "applications",
  APPLICATION_STAGES = "application-stages",
  CANDIDATES = "candidates",
  INTERVIEWS = "interviews",
  CREATE_CANDIDATE = "create-candidate",
  CREATE_APPLICATION = "create-application",
  MOVE_APPLICATION_TO_STAGE = "move-application-to-stage",
  ADD_NOTE_TO_APPLICATION = "add-note-to-application",
  ADD_RESULT_LINK_TO_CANDIDATE = "add-result-link-to-candidate",
  ADD_RESULT_LINK_TO_APPLICATION = "add-result-link-to-application",
  ADD_ATTACHMENT_TO_CANDIDATE = "add-attachment-to-candidate",
  ADD_ATTACHMENT_TO_APPLICATION = "add-attachment-to-application",
  TAGS = "tags",
  CANDIDATE_TAGS = "candidate-tags",
  ADD_TAG_TO_CANDIDATE = "add-tag-to-candidate",
  REMOVE_TAG_FROM_CANDIDATE = "remove-tag-from-candidate",
  USERS = "users",
  OFFERS = "offers",
  JOB_HIRING_TEAM_MEMBERS = "job-hiring-team-members",
  JOB_SPECIFIC_APPLICATION_STAGES = "job-specific-application-stages",
  JOB_SPECIFIC_SCREENING_QUESTIONS = "job-specific-screening-questions",
  INTERVIEWING_TEAM_MEMBERS = "interviewing-team-members",
  SOURCE_WRITING = "source-writing",
  SYNC_ONLY_CREATED_APPLICATIONS = "sync-only-created-applications",
  CONNECTION_GUIDE = "connection-guide",
  CREATE_CANDIDATE_FULL_ATTACHMENT_RETRY = "create-candidate-full-attachment-retry",
  OAUTH = "oauth",
  API_PASSTHROUGH = "api-passthrough",
  SCOPE_TESTING = "scope-testing",
  DELTA_SYNCS = "delta-syncs",
  REJECTION_REASONS = "rejection-reasons",
  REJECT_APPLICATION = "reject-application",
  GET_APPLICATION_ATTACHMENTS = "get-application-attachments",
  GET_CANDIDATE_ATTACHMENTS = "get-candidate-attachments",
  UPDATE_FIELDS_ON_CANDIDATE = "update-fields-on-candidate",
  WEBHOOK_SUBSCRIPTIONS = "webhook-subscriptions",
}

export enum ECompanyIntegrationSyncFeatures {
  JOBS = "jobs",
}

export interface CompanyIntegrationDef {
  id: string;
  integrationId: string;
  integrationLastSyncDate: string;
  software: ECompanyIntegrationSoftware;
  features?: EKomboFeature[];
  syncFeatures: ECompanyIntegrationSyncFeatures[];
  createdAt: string;
  updatedAt: string;
}
