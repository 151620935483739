import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import { MdDescription, MdImage, MdPictureAsPdf } from "react-icons/md";
import { Label } from "@app/components/ui/Label/Label";
import { downloadFile } from "@app/helpers/file.helper";
import { ProfileDocumentDef } from "@app/types/candidate.types";
import { Button } from "@ui/Button/Button";
import styles from "./Documents.module.scss";
import { DocumentItem } from "./components/DocumentItem";
import { GenerateCV } from "./components/GenerateCV/GenerateCV";

type DocumentsProps = {
  documents: ProfileDocumentDef[];
  candidateUserId: string;
};

const MAX_VISIBLE_DOCS = 3;

export const Documents = ({ documents, candidateUserId }: DocumentsProps) => {
  const { t } = useTranslation();
  const [showAllDocs, setShowAllDocs] = useState(false);

  const getFileIcon = (doc: ProfileDocumentDef) => {
    const extension = (doc.filename?.split(".").pop() || "").toLowerCase();
    const extensionToIcon: Record<string, IconType> = {
      pdf: MdPictureAsPdf,
      png: MdImage,
      jpg: MdImage,
      jpeg: MdImage,
      gif: MdImage,
    };
    return extensionToIcon[extension] || MdDescription;
  };

  const handleShowAllDocs = () => {
    setShowAllDocs(!showAllDocs);
  };

  const visibleDocs = documents.slice(0, MAX_VISIBLE_DOCS);
  const hiddenDocs = documents.slice(MAX_VISIBLE_DOCS);
  const docs = [...visibleDocs, ...(showAllDocs ? hiddenDocs : [])];

  return (
    <div>
      <Label>{t("chat-documents")}</Label>
      <ul className={styles.list}>
        <li>
          <GenerateCV candidateUserId={candidateUserId} />
        </li>

        {docs.map((doc) => (
          <li key={doc.id} className={styles.documentItem}>
            <DocumentItem
              doc={doc}
              onDownload={() => downloadFile(doc.url, doc.filename)}
              icon={getFileIcon(doc)}
            />
          </li>
        ))}
      </ul>
      {hiddenDocs.length > 0 && (
        <Button onClick={handleShowAllDocs} className={styles.viewButton} type="link" size="small">
          {showAllDocs ? t("chat-show-less") : `${t("chat-show-all")} (${documents.length})`}
        </Button>
      )}
    </div>
  );
};
