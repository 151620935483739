import { ENV } from "@app/constants/env";
import { ITranslationResponse, WeblateProjects } from "@app/types/translations.types";
import { api } from "./api";

export const getTranslations = async (
  project: WeblateProjects,
  lang: string,
  since?: Date
): Promise<ITranslationResponse> => {
  const response = await api.get<ITranslationResponse>("/crafthunts/translations", {
    headers: {
      "x-access-token": ENV.API_KEY,
      Authorization: "",
    },
    params: {
      project,
      lang,
      since: since?.toISOString(),
    },
  });
  return response.data;
};
