import { PaginationRequestDef, PaginationResponseDef } from "./pagination.types";

export enum ECompanyIntegrationSyncStatus {
  PROCESSING = "processing",
  FAILED = "failed",
  SUCCESS = "success",
}

export interface CompanyIntegrationSyncDef {
  id: string;
  status: ECompanyIntegrationSyncStatus;
  totalProcessed: number;
  totalFailed: number;
  failedIntegrationJobAdIds: string[];
  totalToProcess: number;
  companyIntegrationId: string;
}

export type CompanyIntegrationSyncWithPaginationDef =
  PaginationResponseDef<CompanyIntegrationSyncDef>;

export type CompanyIntegrationSyncRequestDef = PaginationRequestDef & {
  companyIntegrationId: string;
};
