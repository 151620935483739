type LogoProps = {
  width?: number;
  iconOnly?: boolean;
};

export const Logo = ({ width, iconOnly }: LogoProps) => {
  if (iconOnly) {
    return (
      <svg width={20} viewBox="0 0 46.35 80" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 68.387h46.363v-13.51H19.331l27.032-28.913V6.666L0 54.876v13.511z"
          clipRule="evenodd"
          fill="#F59E0B"
          fillRule="evenodd"
        ></path>
      </svg>
    );
  }
  return (
    <svg width={width} viewBox="0 0 446 80" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 68.387h46.363v-13.51H19.331l27.032-28.913V6.666L0 54.876v13.511z"
        clipRule="evenodd"
        fill="#F59E0B"
        fillRule="evenodd"
      ></path>
      <path
        d="M89.444 27.796c2.232 0 4.154.682 5.766 2.046 1.55 1.364 2.604 3.224 3.162 5.642l9.734-3.224c-1.488-4.34-3.782-7.626-7.006-9.92-3.224-2.356-7.068-3.472-11.594-3.472-3.1 0-5.952.558-8.556 1.736-2.666 1.116-4.96 2.852-6.882 5.084-1.86 2.046-3.224 4.464-4.154 7.192-.992 2.728-1.426 5.704-1.426 8.866 0 3.224.434 6.2 1.426 8.928.93 2.728 2.294 5.146 4.154 7.192 1.922 2.232 4.216 3.968 6.882 5.084 2.604 1.178 5.456 1.736 8.556 1.736 4.526 0 8.37-1.116 11.594-3.472 3.224-2.294 5.518-5.58 7.006-9.92l-9.734-3.224c-.558 2.418-1.612 4.278-3.162 5.642-1.612 1.364-3.534 1.984-5.766 1.984-3.162 0-5.704-1.24-7.688-3.844-1.984-2.542-2.976-5.89-2.976-10.044 0-4.216.992-7.564 2.976-10.106 1.984-2.604 4.526-3.906 7.688-3.906zm38.429 20.398 9.672 15.81h11.842l-10.726-16.926c3.038-.992 5.394-2.666 7.006-4.96 1.612-2.232 2.418-5.022 2.418-8.308 0-4.526-1.488-8.06-4.464-10.54s-7.192-3.782-12.524-3.782h-18.786v44.516h10.354v-15.81h5.208zm-5.208-19.964h7.874c2.356 0 4.216.496 5.456 1.488s1.922 2.418 1.922 4.278-.682 3.286-1.922 4.278-3.1 1.488-5.456 1.488h-7.874V28.23zm27.777 35.774h10.23l3.224-8.928h17.484l3.224 8.928h10.478l-16.368-44.516h-11.842l-16.43 44.516zm27.776-17.484h-11.222l5.58-15.562 5.642 15.562zm31.025.62h17.484v-8.68h-17.484V28.354h21.018v-8.866h-31.186v44.516h10.168V47.14zm61.007-27.652h-37.572v8.866h13.64v35.65h10.354v-35.65h13.578v-8.866zm32.967 17.36h-18.104v-17.36h-10.354v44.516h10.354V45.652h18.104v18.352h10.354V19.488h-10.354v17.36zm35.602 27.838c5.89 0 10.478-1.612 13.764-4.898 3.286-3.224 4.96-7.812 4.96-13.764V19.488h-10.354v26.288c0 3.224-.744 5.704-2.17 7.378s-3.472 2.48-6.2 2.48-4.836-.806-6.262-2.48c-1.488-1.674-2.17-4.154-2.17-7.378V19.488h-10.292v26.536c0 5.952 1.612 10.54 4.898 13.764 3.286 3.286 7.874 4.898 13.826 4.898zm53.828-45.198v27.218l-18.228-27.218h-10.354v44.516h9.92V35.298l19.592 28.706h8.99V19.488h-9.92zm51.978 0h-37.572v8.866h13.64v35.65h10.354v-35.65h13.578v-8.866z"
        fill="#F59E0B"
      ></path>
    </svg>
  );
};
