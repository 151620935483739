import { Country } from "react-phone-number-input";
import { mapLocaleToPhoneInputCountry } from "@app/helpers/language.helper";

export const ALL_LANGUAGES = [
  { value: "de", name: "Deutsch", flagIcon: "DE" },
  { value: "en", name: "English", flagIcon: "GB" },
  { value: "pl", name: "Polska", flagIcon: "PL" },
  { value: "ro", name: "Română", flagIcon: "RO" },
  { value: "es", name: "Español", flagIcon: "ES" },
  { value: "fr", name: "Français", flagIcon: "FR" },
  { value: "it", name: "Italiano", flagIcon: "IT" },
  { value: "el", name: "Ελληνική", flagIcon: "EL" },
  { value: "cs", name: "Česky", flagIcon: "CZ" },
  { value: "da", name: "Dansk", flagIcon: "DK" },
  { value: "pt_BR", name: "Portuguese (Brazil)", flagIcon: "BR" },
];

export const LANGUAGES_PHONE = [...ALL_LANGUAGES, { value: "ch" }, { value: "at" }, { value: "be" }]
  .map((lang) => mapLocaleToPhoneInputCountry(lang.value))
  .filter(Boolean) as Country[];
