import confetti from "canvas-confetti";

export function randomInRange(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export function launchConfetti(x: number, y: number) {
  return confetti({
    angle: randomInRange(55, 125),
    spread: randomInRange(50, 70),
    particleCount: randomInRange(50, 100),
    origin: { x, y },
    zIndex: 2000,
  });
}
