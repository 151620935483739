import { App, Badge, Button, Col, Input, Row, Space, Table, TableColumnsType } from "antd";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/es/table/interface";
import { FilterDropdownProps } from "antd/lib/table/interface";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AddressPicker, EAddressPickerType } from "@app/components/ui/AddressPicker/AddressPicker";
import { MomentDatePicker } from "@app/components/ui/DatePicker";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { DateFormats } from "@app/constants/date.constants";
import { ENV } from "@app/constants/env";
import { useUsersSA } from "@app/features/super-admin/api/super-admin.user.api";
import { RolesFilter } from "@app/features/super-admin/components/SuperAdminStuff/components/Filters/RolesFilter/RolesFilter";
import { TradesFilter } from "@app/features/super-admin/components/SuperAdminStuff/components/Filters/TradesFilter/TradesFilter";
import { SUPER_ADMIN_PAGE_SIZE } from "@app/features/super-admin/constants/super-admin.constants";
import {
  EUserType,
  UsersSAResponseDef,
} from "@app/features/super-admin/types/super-admin.user.types";
import { AddressObjDef } from "@app/types/address.types";
import { OrderByDef } from "@app/types/pagination.types";
import { MagicLinkButton } from "./components/MagicLinkButton";
import { useUserModalGlobal } from "./components/UserModal/UserModalGlobal";

const { Search } = Input;

const LINK_KEY = "link";

export const Users = () => {
  const { t } = useTranslation();
  const { message, modal } = App.useApp();
  // table params
  const [keyword, setKeyword] = useState("");
  const [selectedTradeIds, setSelectedTradeIds] = useState([]);
  const [selectedJobTitleIds, setSelectedJobTitleIds] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState(EUserType.NORMAL_USER);
  const [selectedCoordinates, setSelectedCoordinates] = useState("");
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState<OrderByDef[]>([]);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  // filter data
  const userTypeChoices = Object.values(EUserType).map((userType) => ({
    value: userType,
    label: EUserType.COMPANY_ADMIN_USER === userType ? "Company Admins" : "Normal Users",
  }));
  // modals
  const { openUserModal } = useUserModalGlobal();

  const viewingNormalUsers = selectedUserType === EUserType.NORMAL_USER;

  const usersQuery = useUsersSA({
    keyword,
    tradeIds: viewingNormalUsers ? selectedTradeIds : undefined,
    jobTitleIds: viewingNormalUsers ? selectedJobTitleIds : undefined,
    coordinates: viewingNormalUsers ? selectedCoordinates : undefined,
    userType: selectedUserType,
    limit: SUPER_ADMIN_PAGE_SIZE,
    offset: page,
    orderBy,
    startDate,
    endDate,
  });

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    if (typeof pagination.current === "number") {
      setPage(pagination.current - 1);
    }
    if (filters && filters["createdAt"] && filters["createdAt"].length === 2) {
      const startDate = moment(filters["createdAt"][0].toString()).startOf("day").toISOString();
      const endDate = moment(filters["createdAt"][1].toString()).startOf("day").toISOString();
      setStartDate(startDate);
      setEndDate(endDate);
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
    if (sorter) {
      const sorts = Array.isArray(sorter) ? sorter : [sorter];
      const newOrderBy = sorts
        .filter((sort) => sort.field != null)
        .map((sort) => ({
          fieldName: sort.field?.toString(),
          order: sort.order === "ascend" ? "ASC" : "DESC",
        }));

      setOrderBy(newOrderBy as OrderByDef[]);
    }
  };

  const handleAddressChange = (address: AddressObjDef | null) => {
    const newCoords = address?.coordinates
      ? `${address.coordinates.lat},${address.coordinates.lng}`
      : "";

    if (newCoords !== selectedCoordinates) {
      setPage(0);
    }

    setSelectedCoordinates(newCoords);
  };

  const copyToClipboard = (value: string) => {
    try {
      navigator.clipboard.writeText(value);
      message.success({ content: t("Link copied!"), key: LINK_KEY, duration: 0.5 });
    } catch {
      modal.error({
        title: "Something went wrong, here is the raw link",
        content: value,
      });
      message.destroy(LINK_KEY);
    }
  };

  const createdAtFilter = ({ setSelectedKeys, selectedKeys, confirm }: FilterDropdownProps) => {
    return (
      <MomentDatePicker.RangePicker
        autoFocus
        allowEmpty={[true, true]}
        format={DateFormats.D_M_Y}
        value={selectedKeys[0] ? [moment(selectedKeys[0]), moment(selectedKeys[1])] : null}
        ranges={{
          Today: [moment(), moment()],
          "This week": [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
          "Last week": [
            moment().subtract(1, "week").startOf("isoWeek"),
            moment().subtract(1, "week").endOf("isoWeek"),
          ],
          "This month": [moment().startOf("month"), moment().endOf("month")],
          "Last month": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
        }}
        onChange={(dates) => {
          setSelectedKeys(
            dates && dates[0] && dates[1] ? [dates[0].toISOString(), dates[1].toISOString()] : []
          );
          if (!dates || (dates && dates[0] && dates[1])) {
            confirm();
          }
        }}
        onOk={() => {
          confirm();
        }}
        onBlur={() => {
          confirm();
        }}
      />
    );
  };

  const generatedDataNormalUsers = (data: UsersSAResponseDef[]) => {
    return data.map((item) => {
      return {
        key: item.id,
        name: (
          <a onClick={() => openUserModal({ userId: item.id })}>
            {[item.firstName, item.lastName].filter(Boolean).join(" ")}
          </a>
        ),
        email: item.email || "",
        phone: item.phone || "",
        links: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <MagicLinkButton userId={item.id}>Magic</MagicLinkButton>
            <Button
              onClick={() => copyToClipboard(`${ENV.WEB_URL}/craftsmen/${item.slug}`)}
              type="link"
            >
              Profile
            </Button>
            <Button
              onClick={() => copyToClipboard(`${ENV.WEB_URL}/craftsmen/${item.anonymousSlug}`)}
              type="link"
            >
              Anonymous
            </Button>
          </div>
        ),
        isVerified: (
          <Badge
            key="isVerified"
            status={item.isVerified ? "success" : "default"}
            text={t(item.isVerified ? "Yes" : "No")}
          />
        ),
        distance:
          typeof item.distance === "number"
            ? `${item.distance.toFixed(1).replace(".0", "")} km`
            : "-",
        createdAt: moment(item.createdAt).format(DateFormats.FULL_DATE_TIME),
        operation: <a onClick={() => openUserModal({ userId: item.id })}>{t("Edit")}</a>,
      };
    });
  };
  const columnsNormalUsers: TableColumnsType<ReturnType<typeof generatedDataNormalUsers>[number]> =
    [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        width: 220,
      },
      { title: t("Email"), dataIndex: "email", key: "email", width: 150 },
      { title: t("Mobile phone"), dataIndex: "phone", key: "phone", width: 160 },
      {
        title: "Links",
        dataIndex: "links",
        key: "links",
        width: 150,
        align: "center",
      },
      { title: t("Verified"), dataIndex: "isVerified", key: "isVerified", width: 110 },
      { title: t("distance-label"), dataIndex: "distance", key: "distance", width: 150 },
      {
        title: t("Created"),
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: true,
        filterDropdown: createdAtFilter,
      },
      { title: t("Actions"), dataIndex: "operation", key: "operation", fixed: "right", width: 120 },
    ];

  const generatedDataCompanyAdmins = (data: UsersSAResponseDef[]) => {
    return data.map((item) => {
      return {
        key: item.id,
        name: [item.firstName, item.lastName].filter(Boolean).join(" "),
        email: item.email || "",
        phone: item.phone || "",
        links: <MagicLinkButton userId={item.id}>Magic</MagicLinkButton>,
        createdAt: moment(item.createdAt).format(DateFormats.FULL_DATE_TIME),
        operation: (
          <Space size="middle">
            <a onClick={() => openUserModal({ userId: item.id })}>{t("Edit")}</a>
          </Space>
        ),
      };
    });
  };
  const columnsCompanyAdmins: TableColumnsType<
    ReturnType<typeof generatedDataCompanyAdmins>[number]
  > = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: 220,
    },
    { title: t("Email"), dataIndex: "email", key: "email", width: 150 },
    { title: t("Mobile phone"), dataIndex: "phone", key: "phone", width: 160 },
    {
      title: "Links",
      dataIndex: "links",
      key: "links",
      width: 150,
      align: "center",
    },
    { title: t("Created"), dataIndex: "createdAt", key: "createdAt" },
    { title: t("Actions"), dataIndex: "operation", key: "operation", fixed: "right", width: 100 },
  ];

  return (
    <>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Row gutter={[16, 16]} align="middle">
          <Col span={16}>
            <Search
              placeholder={t("Search for id, name, email, phone, slug, anonymousSlug and city")}
              enterButton={t("Search")}
              size="large"
              onSearch={(value) => {
                setKeyword(value);
                setPage(0);
              }}
              allowClear
            />
          </Col>
          <Col span={8}>
            <SelectAutocomplete
              value={selectedUserType}
              placeholder={"User Type"}
              options={userTypeChoices}
              onChange={(value) => {
                setPage(0);
                setSelectedUserType(value);
              }}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} align="middle">
          <Col span={8}>
            <AddressPicker
              type={EAddressPickerType.CITY}
              disableMap
              onChange={handleAddressChange}
              disabled={!viewingNormalUsers}
            />
          </Col>
          <Col span={8}>
            <RolesFilter
              onChange={(value) => {
                setPage(0);
                setSelectedJobTitleIds(value);
              }}
              disabled={!viewingNormalUsers}
            />
          </Col>
          <Col span={8}>
            <TradesFilter
              onChange={(value) => {
                setPage(0);
                setSelectedTradeIds(value);
              }}
              disabled={!viewingNormalUsers}
            />
          </Col>
        </Row>
        {viewingNormalUsers ? (
          <Table
            style={{ width: "100%" }}
            columns={columnsNormalUsers}
            dataSource={generatedDataNormalUsers(usersQuery.data?.data ?? [])}
            loading={usersQuery.isLoading}
            pagination={{
              current: page + 1,
              total: usersQuery.data?.count,
              showTotal: (total) => `${t("Total")} ${total} ${t("Users")}`,
              pageSize: SUPER_ADMIN_PAGE_SIZE,
              showSizeChanger: false,
              position: ["bottomLeft"],
            }}
            onChange={handleTableChange}
            scroll={{ x: 1260 }}
          />
        ) : (
          <Table
            style={{ width: "100%" }}
            columns={columnsCompanyAdmins}
            dataSource={generatedDataCompanyAdmins(usersQuery.data?.data ?? [])}
            loading={usersQuery.isLoading}
            pagination={{
              current: page + 1,
              total: usersQuery.data?.count,
              showTotal: (total) => `${t("Total")} ${total} Company Admins`,
              pageSize: SUPER_ADMIN_PAGE_SIZE,
              showSizeChanger: false,
              position: ["bottomLeft"],
            }}
            onChange={handleTableChange}
            scroll={{ x: 1000 }}
          />
        )}
      </Space>
    </>
  );
};
