import { Table, TableColumnsType, TablePaginationConfig, Tag, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMoment from "react-moment";
import { DateFormats } from "@app/constants/date.constants";
import { useCandidateApprovalsSA } from "@app/features/super-admin/api/super-admin.approvals.api";
import { useCompanyModal } from "@app/features/super-admin/components/SuperAdminStuff/components/Companies/components/CompanyModal";
import { useJobModal } from "@app/features/super-admin/components/SuperAdminStuff/components/Jobs/JobModal/JobModal";
import { SUPER_ADMIN_PAGE_SIZE } from "@app/features/super-admin/constants/super-admin.constants";
import { CandidateApprovalSADef } from "@app/features/super-admin/types/super-admin.candidate.types";
import { ECandidateApprovalAction } from "@app/types/candidate.types";

const PAGE_SIZE = SUPER_ADMIN_PAGE_SIZE;

type ApprovalHistoryProps = {
  workerProfileId?: string;
};

export const ApprovalHistory = ({ workerProfileId }: ApprovalHistoryProps) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const { setCompanyId } = useCompanyModal();
  const { setJobId } = useJobModal();

  const approvalsQuery = useCandidateApprovalsSA({
    workerProfileId,
    limit: PAGE_SIZE,
    offset: currentPage,
  });

  const handlePageChange = async (pagination: TablePaginationConfig) => {
    if (typeof pagination.current === "number") {
      setCurrentPage(pagination.current - 1);
    }
  };

  const generatedData = (data: CandidateApprovalSADef[]) => {
    return data.map((item) => ({
      key: item.id,
      companyName: (
        <a onClick={() => setCompanyId(item.candidate.crafthuntCompany.id)}>
          {item.candidate.crafthuntCompany.name}
        </a>
      ),
      jobAds: item.candidate.jobAds,
      jobName:
        item.candidate.jobAds.length === 1 ? (
          <a onClick={() => setJobId(item.candidate.jobAds[0].id)}>
            {item.candidate.jobAds[0].title}
          </a>
        ) : item.candidate.jobAds.length > 1 ? (
          ` ${item.candidate.jobAds.length} ${t("Job ads")}`
        ) : (
          ""
        ),
      status:
        item.action === ECandidateApprovalAction.APPROVE ? (
          <Tag color="green">Approved</Tag>
        ) : (
          <Tag color="volcano">Rejected</Tag>
        ),
      reason: item.rejectionReason && t(item.rejectionReason),
      createdAt: <ReactMoment format={DateFormats.FULL_DATE_TIME}>{item.createdAt}</ReactMoment>,
      description: item.description,
    }));
  };

  const columns: TableColumnsType<ReturnType<typeof generatedData>[number]> = [
    {
      title: t("Company name"),
      dataIndex: "companyName",
      key: "companyName",
      ellipsis: true,
      width: 250,
    },
    { title: t("Job ads"), dataIndex: "jobName", key: "jobName", ellipsis: true, width: 250 },
    { title: t("Status"), dataIndex: "status", key: "status", ellipsis: true, width: 120 },
    { title: "Rejection reason", dataIndex: "reason", key: "reason" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: t("Created at"), dataIndex: "createdAt", key: "createdAt", width: 200 },
  ];

  return (
    <Table
      style={{ width: "100%" }}
      loading={approvalsQuery.isLoading}
      columns={columns}
      scroll={{ x: 1270 }}
      dataSource={generatedData(approvalsQuery.data?.data ?? [])}
      expandable={{
        expandedRowRender: (record) => (
          <>
            <Typography.Text>{t("Job ads")}</Typography.Text>
            <ul>
              {record.jobAds.map((job) => (
                <li key={job.id}>
                  <a onClick={() => setJobId(job.id)}>{job.title}</a>
                </li>
              ))}
            </ul>
          </>
        ),
        rowExpandable: (record) => record.jobAds.length > 1,
      }}
      pagination={{
        pageSize: PAGE_SIZE,
        showSizeChanger: false,
        position: ["bottomLeft"],
        total: approvalsQuery.data?.count,
      }}
      onChange={handlePageChange}
    />
  );
};
