import Icon from "@ant-design/icons";
import { Button, Col, Row, Space, Tooltip, Typography } from "antd";
import clsx from "clsx";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { MdCancel, MdMessage, MdOpenInFull, MdStar } from "react-icons/md";
import { Link } from "react-router-dom";
import { CandidateName } from "@app/components/pages/Candidates/components/CandidateName/CandidateName";
import { VerifiedBadge } from "@app/components/ui/VerifiedBadge";
import { Colors } from "@app/constants/colors.constants";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { CandidateDef, ECandidateStatus } from "@app/types/candidate.types";
import styles from "./CandidateCardContent.module.scss";
import { CandidateUrgencyTag } from "./components/CandidateUrgencyTag";

type CandidateCardContentProps = {
  candidate: CandidateDef;
  showJobName?: boolean;
  onClick: (candidate: CandidateDef) => void;
};

export const CandidateCardContent = memo(
  ({ candidate, onClick, showJobName }: CandidateCardContentProps) => {
    const { t } = useTranslation();

    const isInRecommendedStage =
      candidate.companyCandidateStatus?.status === ECandidateStatus.RECOMMENDED;

    return (
      <div onClick={() => onClick(candidate)}>
        <Tooltip title={t("open")}>
          <Button
            className={styles.expand}
            onClick={() => onClick(candidate)}
            type="text"
            icon={<Icon component={MdOpenInFull} style={{ color: Colors.primary }} />}
          />
        </Tooltip>

        <CandidateName
          candidate={candidate}
          paddingRight
          shouldTruncate
          textStyle={styles.smallText}
        />

        {!!showJobName && !!candidate.jobAds.length && (
          <div style={{ marginBottom: "16px", marginTop: "16px" }}>
            <Typography.Text
              key="job-ad-name"
              type="secondary"
              className={clsx(styles.smallText, styles.truncate)}
            >
              {isInRecommendedStage ? t("recommended-for") : t("applied-to")}:{" "}
              {candidate.jobAds.at(0)?.title}
            </Typography.Text>
          </div>
        )}

        <div style={{ marginBottom: "16px", marginTop: "16px" }}>
          {candidate.workerProfile.isVerified && (
            <Space align="center">
              <VerifiedBadge />
              <Typography.Text key="verified" className={styles.smallText}>
                {t("verified-by-crafthunt")}
              </Typography.Text>
            </Space>
          )}
          {!!candidate.revealIdentity && (
            <>
              {!!candidate.workerProfile.medias?.length && (
                <Space align="center">
                  <Icon component={IoIosCheckmarkCircle} style={{ color: Colors.green }} />
                  <Typography.Text key="cv" className={styles.smallText}>
                    {t("documents-available")}
                  </Typography.Text>
                </Space>
              )}
              {!candidate.workerProfile.medias?.length && (
                <Space align="center">
                  <Icon component={MdCancel} style={{ color: Colors.red }} />
                  <Typography.Text key="cv" className={styles.smallText}>
                    {t("documents-missing")}
                  </Typography.Text>
                </Space>
              )}
            </>
          )}
          {candidate.isCrafthuntRecommended && !isInRecommendedStage && (
            <Space align="center">
              <Icon component={MdStar} style={{ color: Colors.primary }} />
              <Typography.Text key="recommended" className={styles.smallText}>
                {t("recommended-by-crafthunt")}
              </Typography.Text>
            </Space>
          )}
        </div>

        <Row justify="space-between" align="bottom" wrap={false}>
          <Col>
            <CandidateUrgencyTag candidate={candidate} />
          </Col>
          <Col>
            <Tooltip title={t("chat")}>
              <Link to={RoutePaths.CHAT + `?candidateId=${candidate.id}`}>
                <Button icon={<Icon component={MdMessage} style={{ color: Colors.primary }} />} />
              </Link>
            </Tooltip>
          </Col>
        </Row>
      </div>
    );
  }
);
