import { Badge, Tooltip } from "antd";
import styles from "./StatusBadge.module.scss";

type StatusBadgeProps = {
  status: string;
  isSuccess: boolean;
};

export const StatusBadge = ({ status, isSuccess }: StatusBadgeProps) => {
  return (
    <Tooltip title={status}>
      <Badge key="status" status={isSuccess ? "success" : "warning"} className={styles.badge} />
    </Tooltip>
  );
};
