import { DeleteOutlined } from "@ant-design/icons";
import {
  Card,
  Checkbox,
  Col,
  Form,
  FormItemProps,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import _omit from "lodash/omit";
import moment from "moment";
import React, { ReactNode, useEffect, useState } from "react";
import isEqual from "react-fast-compare";
import { useTranslation } from "react-i18next";
import { AddressPicker } from "@app/components/ui/AddressPicker/AddressPicker";
import { Button } from "@app/components/ui/Button/Button";
import { MomentDatePicker } from "@app/components/ui/DatePicker";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { Uploader } from "@app/components/ui/Uploader/Uploader";
import { DateFormats } from "@app/constants/date.constants";
import { useUnsavedPrompt } from "@app/hooks/useUnsavedPrompt";
import {
  ConditionalFieldRequirement,
  RemovableCardFormDatePickerInput,
  RemovableCardFormInputOptions,
  RemovableCardFormInputTypes,
  RemovableCardFormNumberInput,
  RemovableCardFormSelectInput,
  RemovableCardFormStringInput,
} from "./RemovableCardForm.types";

export interface RemovableCardFormProps<T> {
  onImagesChange?: (i: string[]) => void;
  options: RemovableCardFormInputOptions<T>[];
  initialValues: T;
  onChange?: (i: T) => void;
  onRemove?: (i: T) => void;
  onSave: (i: T) => void;
  width?: string;
  height?: string;
  conditionallyRequiredFields?: ConditionalFieldRequirement<T>[];
  noRemoveButton?: boolean;
  noSaveButton?: boolean;
  title?: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RemovableCardForm = <T extends { [key: string | number]: any }>(
  props: RemovableCardFormProps<T>
) => {
  const { t } = useTranslation();
  const [form] = useForm<T>();
  const [hasChangedValues, setHasChangedValues] = useState(false);
  const [formValues, setFormValues] = useState(props.initialValues);
  const [savedFormValues, setSavedFormValues] = useState(props.initialValues);

  // Validated indicates that the value of "valid" is up to date. Validation is async
  const [valid, setValid] = useState(false);
  const [validated, setValidated] = useState(false);
  useUnsavedPrompt(
    t("You have unsaved changes, are you sure you want to leave?"),
    hasChangedValues
  );

  function emit(save = false) {
    if (valid && validated) {
      const item: T = {
        ...savedFormValues,
        ...form.getFieldsValue(),
      };

      props.onChange?.(item);
      if (save || (props.noSaveButton && hasChangedValues)) {
        props.onSave(item);
        setSavedFormValues(item);
        setHasChangedValues(false);
      }
    }
  }

  useEffect(() => {
    emit();
  }, [valid, validated]);

  useEffect(() => {
    async function validate() {
      try {
        await form.validateFields();
        if (!valid) setValid(true);
      } catch {
        if (valid) setValid(false);
      }
      setValidated(true);
    }

    if (hasChangedValues) {
      validate();
    }
  }, [hasChangedValues, formValues]);

  function getRules(item: RemovableCardFormInputOptions<T>): FormItemProps["rules"] {
    const rules: FormItemProps["rules"] = [...(item.rules ?? [])];
    if (item.required) {
      rules.push({
        required: true,
        message: t("Required field"),
      });
    } else if (props.conditionallyRequiredFields && props.conditionallyRequiredFields.length >= 2) {
      // Situation where at least one of several fields must be used, e.g. email or phone
      const atLeastOneFieldFilled =
        props.conditionallyRequiredFields
          .map((i) => {
            // @ts-expect-error type error
            return !!form.getFieldValue(`${String(i.propertyName)}`);
          })
          .filter((i) => i).length > 0;
      const currentIsOneOfThoseFields = props.conditionallyRequiredFields
        .map((i) => i.propertyName)
        .includes(item.value);
      if (!atLeastOneFieldFilled && currentIsOneOfThoseFields && hasChangedValues) {
        const lastFieldIndex = props.conditionallyRequiredFields.length - 1;
        if (lastFieldIndex > 1) {
          // We have enough words that we need commas
          const requiredFieldsMessage = props.conditionallyRequiredFields
            .map((i) => t(i.displayName))
            .filter((i, index) => index !== lastFieldIndex)
            .join(", ");
          const message = `${requiredFieldsMessage}, ${t("or")} ${t(
            props.conditionallyRequiredFields[lastFieldIndex].displayName
          )} ${t("required")}`;
          rules.push({
            required: true,
            message,
          });
        } else {
          rules.push({
            required: true,
            message: `${t(props.conditionallyRequiredFields[0].displayName)} ${t("or")} ${t(
              props.conditionallyRequiredFields[1].displayName
            )} ${t("required")}`,
          });
        }
      }
    }
    if (item.inputType === RemovableCardFormInputTypes.select) {
      if (item.required && (item as RemovableCardFormSelectInput<T>).isMultiple) {
        return [
          {
            type: "array",
            min: 1,
            message: t("At least 1 required"),
          },
        ];
      }
    }
    if (item.inputType === RemovableCardFormInputTypes.emailField) {
      rules.push({
        type: "email",
        message: t("Email is invalid"),
      });
    }

    if (
      item.inputType === RemovableCardFormInputTypes.numberTextField &&
      !isNaN((item as RemovableCardFormNumberInput<T>).min as number)
    ) {
      rules.push({
        type: "number",
        min: (item as RemovableCardFormNumberInput<T>).min,
        message: `${t("Minimum")} ${(item as RemovableCardFormNumberInput<T>).min}`,
      });
    }
    if (
      item.inputType === RemovableCardFormInputTypes.numberTextField &&
      !isNaN((item as RemovableCardFormNumberInput<T>).max as number)
    ) {
      rules.push({
        type: "number",
        max: (item as RemovableCardFormNumberInput<T>).max,
        message: `${t("Maximum")} ${(item as RemovableCardFormNumberInput<T>).max}`,
      });
    }
    if (item.inputType === RemovableCardFormInputTypes.numberTextField) {
      rules.push({
        validator: (x, value) => {
          const currentValue = `${value}`;
          return new Promise((resolve, reject) => {
            let isDataValid = true;

            if (item.required && value == null) {
              isDataValid = false;
            }

            if (currentValue.includes(",") || currentValue.includes(".")) {
              isDataValid = false;
            }
            if (isDataValid) {
              resolve(value);
            } else {
              reject();
            }
          });
        },
        type: "number",
        message: t("Please enter a valid whole number"),
      });
    }
    if (item.inputType === RemovableCardFormInputTypes.phoneNumberField) {
      rules.push({
        pattern: /^[+]?[(]?[0-9]{1,3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,10}$/im,
        message: t("Invalid phone number"),
      });
    }
    if (item.inputType === RemovableCardFormInputTypes.datePicker) {
      const currentItem = item as RemovableCardFormDatePickerInput<T>;
      rules.push({
        validator: (x, value) => {
          return new Promise((resolve, reject) => {
            let isDateValid = true;

            if (currentItem.shouldBeBefore && formValues[currentItem.shouldBeBefore]) {
              isDateValid = moment(value).isBefore(
                moment(formValues[currentItem.shouldBeBefore]),
                "days"
              );
            }

            if (currentItem.shouldBeAfter && formValues[currentItem.shouldBeAfter]) {
              isDateValid = moment(value).isAfter(
                moment(formValues[currentItem.shouldBeAfter]),
                "days"
              );
            }

            if (isDateValid || !value) {
              resolve(value);
            } else {
              reject(new Error(t("Invalid date")));
            }
          });
        },
        type: "date",
        message: t("Invalid date"),
      });
    }
    return rules;
  }

  const onChange = (i: T) => {
    setValidated(false);
    const original = { ...savedFormValues };

    const current = {
      ...formValues,
      ...i,
    };

    setFormValues({
      ...original,
      ...current,
    });

    const hasInFactChanged = !isEqual(
      _omit(original, ["updatedAt", "createdAt"]),
      _omit(current, ["updatedAt", "createdAt"])
    );

    setHasChangedValues(hasInFactChanged);
  };

  const onImageChange = (i: string[], key: keyof T) => {
    setFormValues({
      ...formValues,
      [key]: i,
    });

    form.setFieldsValue({
      [key]: i,
      // This expects a type that's impossible to create so we use any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any);

    if (props.onImagesChange) {
      props.onImagesChange(i);
    }
  };

  function getInputs(i: RemovableCardFormInputOptions<T>) {
    const input = savedFormValues[i.value as string];

    switch (i.inputType) {
      case RemovableCardFormInputTypes.address: {
        return <AddressPicker disableMap />;
      }
      case RemovableCardFormInputTypes.select: {
        const typedResult = i as RemovableCardFormSelectInput<T>;
        return (
          <SelectAutocomplete
            mode={typedResult.isMultiple ? "multiple" : undefined}
            defaultValue={input}
            size="large"
            placeholder={typedResult.placeholder && t(typedResult.placeholder)}
            sortAlphabetically={typedResult.sortAlphabetically}
            options={typedResult.choices?.map((item) => ({
              value: item.value,
              label: item.text,
            }))}
          />
        );
      }
      case RemovableCardFormInputTypes.textArea: {
        const typedResult = i as RemovableCardFormStringInput<T>;
        return (
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 5 }}
            autoComplete="off"
            size="large"
            placeholder={typedResult.placeholder && t(typedResult.placeholder)}
          />
        );
      }
      case RemovableCardFormInputTypes.textField:
      case RemovableCardFormInputTypes.emailField:
      case RemovableCardFormInputTypes.phoneNumberField: {
        const typedResult = i as RemovableCardFormStringInput<T>;
        if (typeof input !== "string") {
          // eslint-disable-next-line no-console
          console.warn(
            `RemovableCardForm: You passed "${String(
              typedResult.value
            )}" as the value to a text field, but that isn't a string in your object.`
          );
        }
        return (
          <Input
            autoComplete="off"
            size="large"
            placeholder={typedResult.placeholder ? t(typedResult.placeholder) : undefined}
          />
        );
      }
      case RemovableCardFormInputTypes.numberTextField: {
        const typedResult = i as RemovableCardFormNumberInput<T>;
        return (
          <InputNumber
            type="number"
            size="large"
            style={{ width: "100%" }}
            placeholder={typedResult.placeholder ? t(typedResult.placeholder) : undefined}
            defaultValue={input}
          />
        );
      }
      case RemovableCardFormInputTypes.datePicker: {
        const typedResult = i as RemovableCardFormDatePickerInput<T>;
        if (typedResult.required && !moment.isMoment(moment(input))) {
          // eslint-disable-next-line no-console
          console.warn(
            `RemovableCardForm: You passed "${String(
              typedResult.value
            )}" as the value to a date picker, but that isn't a Moment in your object.`
          );
        }
        return (
          <MomentDatePicker
            size="large"
            format={DateFormats.D_M_Y}
            defaultPickerValue={input || ""}
            placeholder={typedResult.placeholder ? t(typedResult.placeholder) : undefined}
            style={{ width: "100%" }}
          />
        );
      }
      case RemovableCardFormInputTypes.uploader:
        return (
          <Uploader
            multiple
            setHasChangedValues={setHasChangedValues}
            existingMediaArray={input}
            onImagesChange={(img) => onImageChange(img, i.value)}
          />
        );
      case RemovableCardFormInputTypes.checkbox:
        if (!["true", "false"].includes(input.toString())) {
          // eslint-disable-next-line no-console
          console.warn(
            `RemovableCardForm: You passed "${String(
              i.value
            )}" as the value to a checkbox, but that isn't a boolean in your object.`
          );
        }
        return <Checkbox defaultChecked={input as boolean}>{i.description}</Checkbox>;
      default:
        return <React.Fragment />;
    }
  }

  return (
    <Card
      title={props.title}
      style={{ width: props.width || "100%", height: "100%", overflow: "hidden" }}
    >
      <Form
        initialValues={savedFormValues}
        form={form}
        onValuesChange={onChange}
        onFinish={() => emit(true)}
        data-hs-do-not-collect="true"
      >
        <Row>
          {props.options.map((item) => (
            <React.Fragment key={item.description}>
              {!item.hideLabel && item.inputType !== RemovableCardFormInputTypes.checkbox && (
                <Row
                  justify="space-between"
                  style={{
                    width: "100%",
                  }}
                >
                  <Col>
                    <Typography.Text>{item.description}</Typography.Text>
                  </Col>
                </Row>
              )}
              <Col sm={24}>
                <Form.Item
                  name={item.value as string}
                  hasFeedback={
                    item.inputType === RemovableCardFormInputTypes.address ? false : true
                  }
                  valuePropName={
                    item.inputType === RemovableCardFormInputTypes.checkbox ? "checked" : "value"
                  }
                  rules={getRules(item)}
                >
                  {getInputs(item)}
                </Form.Item>
              </Col>
            </React.Fragment>
          ))}
          <Col span={12}>
            {!props.noRemoveButton && (
              <Popconfirm
                title={t("Are you sure you want to delete this?")}
                okText={t("Yes")}
                cancelText={t("No")}
                onConfirm={() => props.onRemove?.(savedFormValues)}
              >
                <Button type="text" icon={<DeleteOutlined />}>
                  {t("Remove")}
                </Button>
              </Popconfirm>
            )}
          </Col>
          <Col span={12}>
            {!props.noSaveButton && (
              <Form.Item noStyle>
                <Button
                  disabled={!valid || !hasChangedValues}
                  style={{ width: "100%" }}
                  htmlType="submit"
                  type="primary"
                >
                  {t("Save")}
                </Button>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
