import { useQuery } from "@tanstack/react-query";
import {
  CompaniesSAWithPaginationDef,
  CompanyResourceSAUpdateDto,
  CompanySADef,
  CreateCompanySADef,
  CreateCompanySAResponseDef,
  GetCompaniesSAParamsDef,
  LoginAsAdminDef,
  LoginUnclaimCompanyDef,
} from "@app/features/super-admin/types/super-admin.company.types";
import { queryKeysSA } from "./queryKeys";
import { superAdminApi } from "./super-admin.api";

export const getCompaniesSA = (params: GetCompaniesSAParamsDef) => {
  return superAdminApi.get<CompaniesSAWithPaginationDef>("/super_admin/companies", {
    params,
  });
};
export const createCompanySA = (payload: CreateCompanySADef) => {
  return superAdminApi.post<CreateCompanySAResponseDef>("/crafthunts/company_resources", payload);
};
export const loginCompanySA = (payload: LoginAsAdminDef | LoginUnclaimCompanyDef) => {
  return superAdminApi.post<{ accessToken: string }>("/super_admin/auth", {
    ...payload,
    strategy: "switch_user",
  });
};
export const updateCompanySettingsSA = (payload: { id: string } & Partial<CompanySADef>) => {
  return superAdminApi.put<CompanyResourceSAUpdateDto>(
    `/crafthunts/company_resources/${payload.id}`,
    payload
  );
};

export const useCompanySA = ({ companyId }: { companyId?: string | null }) =>
  useQuery({
    enabled: Boolean(companyId),
    queryKey: [...queryKeysSA.all, "companies", companyId] as const,
    queryFn: ({ queryKey }) =>
      getCompaniesSA({ keyword: queryKey[2] ?? undefined }).then(({ data }) => data.data[0]),
  });

export const useCompaniesSA = (params: GetCompaniesSAParamsDef) =>
  useQuery({
    queryKey: [...queryKeysSA.lists(), "companies", params] as const,
    queryFn: ({ queryKey }) => getCompaniesSA(queryKey[3]).then(({ data }) => data),
  });
