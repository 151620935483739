import type { DraggableSyntheticListeners, UniqueIdentifier } from "@dnd-kit/core";
import type { Transform } from "@dnd-kit/utilities";
import { Card } from "antd";
import classNames from "classnames";
import { forwardRef, memo, useEffect } from "react";
import styles from "./Item.module.scss";

export type ItemsProps = {
  dragOverlay?: boolean;
  disabled?: boolean;
  dragging?: boolean;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  value: UniqueIdentifier;
  renderItem?(value: UniqueIdentifier): React.ReactNode;
};

const ForwardItem = forwardRef<HTMLLIElement, ItemsProps>(function Item(
  {
    dragOverlay,
    dragging,
    disabled,
    fadeIn,
    index,
    listeners,
    renderItem,
    sorting,
    style,
    transition,
    transform,
    value,
    wrapperStyle,
    ...props
  },
  ref
) {
  useEffect(() => {
    if (!dragOverlay) {
      return;
    }
    document.body.style.cursor = "grabbing";
    return () => {
      document.body.style.cursor = "";
    };
  }, [dragOverlay]);

  return (
    <li
      className={classNames(
        styles.Wrapper,
        fadeIn && styles.fadeIn,
        sorting && styles.sorting,
        dragOverlay && styles.dragOverlay
      )}
      style={
        {
          ...wrapperStyle,
          transition: [transition, wrapperStyle?.transition].filter(Boolean).join(", "),
          "--translate-x": transform ? `${Math.round(transform.x)}px` : undefined,
          "--translate-y": transform ? `${Math.round(transform.y)}px` : undefined,
          "--scale-x": transform?.scaleX ? `${transform.scaleX}` : undefined,
          "--scale-y": transform?.scaleY ? `${transform.scaleY}` : undefined,
          "--index": index,
        } as React.CSSProperties
      }
      ref={ref}
    >
      <Card
        className={classNames(
          styles.Item,
          dragging && styles.dragging,
          dragOverlay && styles.dragOverlay,
          disabled && styles.disabled
        )}
        style={style}
        data-cypress="draggable-item"
        {...listeners}
        {...props}
        tabIndex={0}
      >
        {typeof renderItem === "function" ? renderItem(value) : value}
      </Card>
    </li>
  );
});

export const Item = memo(ForwardItem);
