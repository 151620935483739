import {
  CheckOutlined,
  ExclamationOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Autocomplete } from "@react-google-maps/api";
import { Input, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMap } from "@app/features/map/context/MapContext";
import { getFullAddress, parseAddress } from "@app/helpers/address.helper";
import { AddressDef, AddressObjDef } from "@app/types/address.types";
import { StateStatusDef } from "@app/types/state-machine.types";

type SimpleAddressPickerProps = {
  value?: AddressObjDef | string;
  address?: AddressDef;
  needsCity?: boolean;
  onChange?: (v: AddressObjDef) => void;
  onStatusUpdate?: (status: StateStatusDef) => void;
};

export const SimpleAddressPicker = ({
  value,
  address,
  needsCity = true,
  onChange,
  onStatusUpdate,
}: SimpleAddressPickerProps) => {
  const { t } = useTranslation();
  const mapApi = useMap();
  const initialFullAddress =
    typeof value === "string" ? value : getFullAddress(value?.address || address);
  const newFullAddressRef = useRef<string>(initialFullAddress);
  const timerRef = useRef<number>();
  const [search, setSearch] = useState<string>(initialFullAddress);
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();
  const [status, setStatus] = useState<StateStatusDef>("initial");

  useEffect(() => {
    if (status) {
      onStatusUpdate?.(status);
    }
  }, [status]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        window.clearTimeout(timerRef.current);
      }
    };
  }, []);

  const onPlaceChanged = () => {
    const place = autocomplete?.getPlace();
    if (place?.geometry?.location && place.address_components) {
      const newPosition = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      const parsed = parseAddress(place.address_components, newPosition);
      const parsedFullAddress = getFullAddress(parsed.address);
      if (parsedFullAddress !== newFullAddressRef.current) {
        newFullAddressRef.current = parsedFullAddress;
        setSearch(parsedFullAddress);
        if (!needsCity || parsed.address.city) {
          onChange?.(parsed);
          setStatus("success");
        } else {
          setStatus("error");
        }
      }
    }
  };

  const getStatusIcon = () => {
    switch (status) {
      case "error":
        return <ExclamationOutlined style={{ color: "#f5222d" }} />;
      case "loading":
        return <LoadingOutlined />;
      case "success":
        return <CheckOutlined style={{ color: "green" }} />;
      default:
        return <SearchOutlined />;
    }
  };

  const resetSearch = () => {
    setTimeout(() => {
      setSearch(newFullAddressRef.current);
    }, 100);
  };

  if (!mapApi.isLoaded) {
    return null;
  }

  return (
    <Autocomplete
      onLoad={setAutocomplete}
      onPlaceChanged={onPlaceChanged}
      fields={["geometry", "address_components"]}
      types={["geocode"]}
    >
      <>
        <Input
          formNoValidate
          suffix={getStatusIcon()}
          type="text"
          placeholder={t("Search for address...")}
          size="large"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          onBlur={resetSearch}
        />
        {status === "error" && (
          <Typography.Text type="danger">{t("Address needs city")}</Typography.Text>
        )}
      </>
    </Autocomplete>
  );
};
