import { createModel } from "@rematch/core";
import * as api from "@app/api/job-ads.api";
import { CreateJobAdDef, DeleteJobAdDef, JobAdDef, UpdateJobAdDef } from "@app/types/job-ads.types";
import { PaginationDef, PaginationRequestDef } from "@app/types/pagination.types";
import { RootModel } from "./models";

export const jobAds = createModel<RootModel>()({
  state: {
    jobAds: [] as JobAdDef[],
    pagination: {} as PaginationDef,
  },
  reducers: {
    addJobAds: (state, payload: JobAdDef[]) => {
      state.jobAds = payload;
      return state;
    },
    addPagination: (state, payload: PaginationDef) => {
      state.pagination = payload;
      return state;
    },
    addJobAd: (state, jobAd: JobAdDef) => {
      state.jobAds.push(jobAd);
      return state;
    },
    removeJobAds: (state, jobAdIndex: number) => {
      state.jobAds.splice(jobAdIndex, 1);
      return state;
    },
    updateJobAd: (state, payload: { jobAd: JobAdDef; index: number }) => {
      state.jobAds.splice(payload.index, 1, payload.jobAd);
      return state;
    },
  },
  effects: (dispatch) => ({
    getJobAdsAction: async (payload: PaginationRequestDef) => {
      const result = await api.getJobAds(payload);
      dispatch.jobAds.addJobAds(result.data);
      dispatch.jobAds.addPagination({
        count: result.count,
        offset: result.offset,
      });
    },
    removeJobAdAction: async (payload: DeleteJobAdDef, rootState) => {
      await api.removeJobAds(payload.id);
      const jobAdIndex = rootState.jobAds.jobAds.findIndex((i) => i.id === payload.id);
      if (jobAdIndex > -1) {
        dispatch.jobAds.removeJobAds(jobAdIndex);
      }
    },
    addJobAdAction: async (payload: CreateJobAdDef) => {
      const result = await api.postJobAd(payload);
      if (result) {
        dispatch.jobAds.addJobAd(result);
      }
      return result;
    },
    updateJobAdAction: async (payload: UpdateJobAdDef, rootState) => {
      const result = await api.updateJobAd(payload);
      const jobAdIndex = rootState.jobAds.jobAds.findIndex((i) => i.id === payload.id);
      if (result && jobAdIndex > -1) {
        dispatch.jobAds.updateJobAd({ jobAd: result, index: jobAdIndex });
      }
      return result;
    },
    clearAllJobAdAction: () => {
      dispatch.jobAds.addJobAds([]);
      dispatch.jobAds.addPagination({
        count: 0,
        offset: 0,
      });
    },
  }),
});
