import packageJson from "../../package.json";

export const ENV = {
  VERSION: packageJson.version || "",
  ENVIRONMENT: process.env.NODE_ENV ?? "development",

  API_URL: process.env.REACT_APP_API_URL ?? "",
  WEB_URL: process.env.REACT_APP_WEB_URL ?? "",
  API_KEY: process.env.REACT_APP_API_KEY ?? "",

  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY ?? "",
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? "",
  FIREBASE_DB_URL: process.env.REACT_APP_FIREBASE_DB_URL ?? "",
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? "",
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ?? "",
  FIREBASE_MESSAGING_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? "",
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID ?? "",
  FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ?? "",

  FIREBASE_SHORT_LINK_API_KEY: process.env.REACT_APP_FIREBASE_SHORT_LINK_API_KEY ?? "",

  GOOGLE_KEY: process.env.REACT_APP_GOOGLE_KEY ?? "",
  GOOGLE_TAG_MANAGER_KEY: process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY ?? "",

  POSTHOG_API_KEY: process.env.REACT_APP_POSTHOG_API_KEY ?? "",

  ANALYTICS_WRITE_KEY: process.env.REACT_APP_ANALYTICS_WRITE_KEY ?? "",

  IS_PRODUCTION: process.env.REACT_APP_IS_PRODUCTION === "true",
};

export const isDev = () => {
  return ENV.ENVIRONMENT === "development";
};
export const isProd = () => {
  return ENV.IS_PRODUCTION && ENV.ENVIRONMENT === "production";
};
