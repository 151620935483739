import {
  ApiCompanyTradeResponse,
  CompanyTradeDef,
  CreateCompanyTradeDef,
  UpdateCompanyTradeDef,
} from "@app/types/trades.types";
import { api } from "./api";

export default {
  async getCompanyTrades(): Promise<CompanyTradeDef[]> {
    const { data } = await api.get<ApiCompanyTradeResponse[]>(`/crafthunts/company_trades`);
    return data;
  },
  async createTrade(payload: CreateCompanyTradeDef): Promise<CompanyTradeDef> {
    const { data } = await api.post<ApiCompanyTradeResponse>(`/crafthunts/company_trades`, payload);
    return data;
  },
  async updateTrade(payload: UpdateCompanyTradeDef): Promise<CompanyTradeDef> {
    const { data } = await api.put<ApiCompanyTradeResponse>(
      `/crafthunts/company_trades/${payload.id}`,
      payload
    );
    return data;
  },
  removeTrade(id: string) {
    return api.delete<void>(`/crafthunts/company_trades/${id}`);
  },
};
