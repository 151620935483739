import { useQuery } from "@tanstack/react-query";
import {
  ActivityLogDef,
  ActivityLogsWithPaginationDef,
} from "@app/features/super-admin/types/super-admin.activity-log.types";
import { queryKeysSA } from "./queryKeys";
import { superAdminApi } from "./super-admin.api";

export const useActivityLogsSA = (
  params: { workerProfileId: string; limit?: number; offset?: number },
  options?: { enabled?: boolean; refetchInterval?: number }
) =>
  useQuery({
    queryKey: [...queryKeysSA.lists(), "activity-logs", params] as const,
    queryFn: ({ queryKey }) =>
      superAdminApi
        .get<ActivityLogsWithPaginationDef>("super_admin/activity_logs", {
          params: queryKey[3],
        })
        .then(({ data }) => data),
    enabled: options?.enabled,
    refetchInterval: options?.refetchInterval,
  });

export const createActivityLogSA = (params: ActivityLogDef) => {
  return superAdminApi.post("super_admin/activity_logs", params);
};
