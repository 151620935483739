// These are the only img types that our firebase resize extensions supports
const RESIZABLE_IMGS_REGEX = /\.(gif|jpe?g|tiff?|png|webp)/i;

export enum ImageSizeEnum {
  FULL = "",
  LARGE = "_1280x1280",
  MEDIUM = "_800x800",
  SMALL = "_400x400",
  TINY = "_200x200",
  AVATAR = "_80x80",
}

export const getResizedImageUrl = (
  imageUrl: string | undefined,
  imageSize: ImageSizeEnum
): string | undefined => {
  if (!imageUrl || !imageUrl.includes("firebasestorage")) {
    return imageUrl;
  }
  const indexOfFileFormat = imageUrl.search(RESIZABLE_IMGS_REGEX);
  if (indexOfFileFormat != -1) {
    return `${imageUrl.slice(0, indexOfFileFormat)}${imageSize}${imageUrl.slice(
      indexOfFileFormat
    )}`;
  }
  return imageUrl;
};
