import { PhoneFilled } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { LanguagePicker } from "@app/components/ui/LanguagePicker/LanguagePicker";
import { Logo } from "@app/components/ui/Logo/Logo";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { RootState } from "@app/store/store";
import { AuthHero } from "./AuthHero/AuthHero";
import styles from "./AuthLayout.module.scss";
import { ForgotPassword } from "./ForgotPassword/ForgotPassword";
import { Login } from "./Login/Login";
import { Signup } from "./Signup/Signup";

export const AuthLayout = () => {
  const location = useLocation();
  const token = useSelector((state: RootState) => state.auth.token);

  if (token) {
    return <Navigate to={RoutePaths.DASHBOARD} />;
  }

  return (
    <Content className={styles.container}>
      <Row className={styles.row} justify="end">
        <Col span={24} lg={13} xl={13} xxl={14} className={styles.hero}>
          <AuthHero />
        </Col>
        <Col span={24} lg={11} xl={11} xxl={10} className={styles.content}>
          <div className={styles.contentHeader}>
            <div className={styles.logo}>
              <Logo />
            </div>
            <Button
              icon={<PhoneFilled />}
              size="large"
              type="primary"
              href="tel:+4989215427120"
              className={styles.phone}
            >
              +49 89 215427120
            </Button>
            <LanguagePicker />
          </div>
          {
            {
              [RoutePaths.SIGN_UP]: <Signup />,
              [RoutePaths.LOGIN]: <Login />,
              [RoutePaths.FORGOT_PASSWORD]: <ForgotPassword />,
            }[location.pathname]
          }
        </Col>
      </Row>
    </Content>
  );
};
