import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { App, Divider, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMount } from "react-use";
import { AuthHeader } from "@app/components/pages/Auth/AuthHeader/AuthHeader";
import { AuthRedirectButton } from "@app/components/pages/Auth/AuthRedirectButton/AuthRedirectButton";
import { Button } from "@app/components/ui/Button/Button";
import { LocalLoginForm } from "@app/features/auth/types/auth.types";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { RootState, store } from "@app/store/store";

export const Login = () => {
  const [form] = useForm<LocalLoginForm>();
  const { message } = App.useApp();
  const loading = useSelector((state: RootState) => state.loading.models.auth);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dispatch } = store;
  const fromLocation = searchParams.get("from")?.toString() || RoutePaths.DASHBOARD;

  useMount(() => {
    message.destroy();
  });

  const handleFinish = async (values: LocalLoginForm) => {
    try {
      await dispatch.auth.login(values);
      message.success(t("Logged in!"), 10);
      navigate(fromLocation, { replace: true });
    } catch (e) {
      if (e instanceof Error && e.message === "Not a company admin") {
        message.error(t("You're not a company admin"), 10);
      } else {
        message.error(t("Invalid credentials"));
      }
    }
  };

  return (
    <>
      <AuthHeader title={t("Welcome, our talent pool awaits you!")} />
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        requiredMark={false}
        data-hs-do-not-collect="true"
      >
        <Form.Item
          name="email"
          label={t("Email")}
          rules={[
            {
              type: "email",
              required: true,
              whitespace: true,
              message: t("Invalid email"),
            },
          ]}
        >
          <Input prefix={<UserOutlined />} type="text" size="large" />
        </Form.Item>
        <Form.Item
          name="password"
          label={t("Password")}
          rules={[
            {
              required: true,
              whitespace: true,
              message: t("Password is required"),
            },
          ]}
        >
          <Input.Password prefix={<LockOutlined />} size="large" />
        </Form.Item>

        <Form.Item>
          <AuthRedirectButton
            actionText={t("Forgot password?")}
            path={RoutePaths.FORGOT_PASSWORD}
            align="left"
            isInline
          />
        </Form.Item>

        <Form.Item>
          <Button
            id="sign-in-button"
            type="primary"
            htmlType="submit"
            block
            loading={loading}
            size="large"
          >
            {t("Sign in")}
          </Button>
        </Form.Item>
        <Divider />
        <AuthRedirectButton
          text={t("Don't have a company account?")}
          actionText={t("Sign up for free")}
          path={RoutePaths.SIGN_UP}
        />
      </Form>
    </>
  );
};
