import _debounce from "lodash/debounce";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SelectAutocomplete,
  SelectAutocompleteProps,
} from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { useUsersSA } from "@app/features/super-admin/api/super-admin.user.api";

export const UserFilter = (props: SelectAutocompleteProps) => {
  const { t } = useTranslation();
  const [searchUsersQuery, setSearchUsersQuery] = useState<string>("");
  const usersQuery = useUsersSA({
    keyword: searchUsersQuery,
  });
  const userChoices = (usersQuery.data?.data ?? []).map((i) => ({
    value: i.id,
    label: `${i.firstName} ${i.lastName} - ${i.phone || i.email}`,
    id: i.id,
  }));

  const debouncedSearchUser = _debounce((value: string) => {
    setSearchUsersQuery(value);
  }, 300);

  const onSearchUsers = (value: string) => {
    debouncedSearchUser(value);
  };

  return (
    <SelectAutocomplete
      loading={usersQuery.isLoading}
      placeholder={t("Users")}
      options={userChoices}
      onSearch={onSearchUsers}
      allowClear
      {...props}
    />
  );
};
