import { Modal, Tabs } from "antd";
import { parseAsString, useQueryState } from "nuqs";
import { useState } from "react";
import { useJobSA, useJobsQuestionsSA } from "@app/features/super-admin/api/super-admin.job.api";
import { InfoTab } from "./InfoTab";
import { QuestionsTab } from "./QuestionsTab/QuestionsTab";

export const useJobModal = () => {
  const [jobId, setJobId] = useQueryState("jobId", parseAsString);

  return {
    jobId,
    setJobId,
  };
};

enum TAB_KEYS {
  INFO = "info",
  QUESTIONS = "questions",
}

export const JobModal = () => {
  const [activeTab, setActiveTab] = useState(TAB_KEYS.INFO);

  const { jobId, setJobId } = useJobModal();
  const jobQuery = useJobSA({
    jobId,
  });
  const questionsQuery = useJobsQuestionsSA({ jobAdId: jobId });

  const onCancel = () => setJobId(null);

  return (
    <Modal
      width={1000}
      open={Boolean(jobId)}
      title={`Job details: ${jobQuery.data?.title ?? ""}`}
      onCancel={onCancel}
      onOk={onCancel}
    >
      {jobId && (
        <Tabs
          activeKey={activeTab}
          onChange={(tabKey) => setActiveTab(tabKey as TAB_KEYS)}
          items={[
            {
              key: TAB_KEYS.INFO,
              label: "Info",
              children: <InfoTab jobId={jobId} />,
              forceRender: true,
              destroyInactiveTabPane: false,
            },
            {
              key: TAB_KEYS.QUESTIONS,
              label: `Screening questions ${
                questionsQuery.data ? `(${questionsQuery.data.length})` : ""
              }`,
              children: <QuestionsTab jobId={jobId} />,
              forceRender: true,
              destroyInactiveTabPane: false,
            },
          ]}
        />
      )}
    </Modal>
  );
};
