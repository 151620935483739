import { api } from "./api";

export const sendChatMessage = async (
  message: string,
  candidateEntityId: string
): Promise<void> => {
  await api.post<void>("/crafthunts/chat_messages", {
    message,
    candidateEntityId,
  });
};
