import { Moment } from "moment-timezone";
import { AddressDef, AddressObjDef, CoordinateDef } from "./address.types";
import { CandidateDef } from "./candidate.types";
import { ICertificate } from "./certificate.types";
import { UpdateIntegrationStatusMappingDef } from "./integration-status.types";
import { PaginationRequestDef, PaginationResponseDef } from "./pagination.types";
import { JobTitleTagDef, ProjectTypeTagDef, TradeTagDef } from "./tags.types";

export enum EJobTravelType {
  NO_TRAVEL = "no-travel",
  READINESS_FOR_ASSEMBLY = "readiness-for-assembly",
  FEW_DAYS_A_MONTH = "few-days-a-month",
  MULTIPLE_DAYS_A_MONTH = "multiple-days-a-month",
  INTERNATIONAL_TRAVEL = "international-travel",
  WILLINGNESS_TO_TRAVEL_BY_APPOINTMENT = "willingness-to-travel-by-appointment",
  WILLINGNESS_TO_TRAVEL_NATIONWIDE = "willingness-to-travel-nationwide",
}

export enum EJobDuration {
  UNLIMITED = "unlimited",
  PROJECT_BASED = "project-based",
  TEMPORARY = "temporary",
  PART_TIME = "part-time",
  INTERNSHIP = "internship",
}

export enum EJobAdStatus {
  DRAFT = "draft",
  PUBLISHED = "published",
}

export enum ESalaryFrequency {
  HOURLY = "hourly",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export interface JobAdDef {
  id: string;
  slug: string;
  fullSlug: string;
  coverImage: string | null;
  title: string;
  description: string;
  roleId: string;
  tradeId: string;
  salaryMin: number | null;
  salaryMax: number | null;
  currency: string | null;
  salaryFrequency: ESalaryFrequency | null;
  salaryHidden: boolean | null;
  requiredLanguages: string[];
  requiredCountries: string[];
  travel: EJobTravelType | null;
  duration: EJobDuration | null;
  workingHours: number | null;
  status: EJobAdStatus;
  projectTypesId: string[];
  jobStartDate: Moment | null;
  jobEndDate: Moment | null;
  address: AddressDef | null;
  coordinate: CoordinateDef | null;
  useCompanyAddress: boolean;
  documentsRequired: boolean;
  updatedAt: string;
  createdAt: string;
  unpublishedAt: string | null;
  temp_address?: AddressObjDef | null;
  integrationJobAdId?: string | null;
  role?: JobTitleTagDef | null;
  trade?: TradeTagDef | null;
}
export type JobAdFormDef = Omit<
  JobAdDef,
  | "id"
  | "slug"
  | "fullSlug"
  | "updatedAt"
  | "createdAt"
  | "unpublishedAt"
  | "documentsRequired"
  | "role"
  | "trade"
> & {
  integrationStatusMapId?: string | null;
  integrationStatusMap?: UpdateIntegrationStatusMappingDef | null;
};

export type JobAdNavigationState = {
  duplicateJobAdId?: JobAdDef["id"];
};

export type CreateJobAdDef = Omit<
  JobAdDef,
  "id" | "slug" | "fullSlug" | "updatedAt" | "createdAt" | "unpublishedAt" | "role" | "trade"
> & {
  lang: string;
};

export type UpdateJobAdDef = Omit<
  JobAdDef,
  "slug" | "fullSlug" | "updatedAt" | "createdAt" | "unpublishedAt" | "role" | "trade"
> & {
  lang: string;
};

export type DeleteJobAdDef = Pick<JobAdDef, "id">;

export type JobAdsRequestDef = PaginationRequestDef & {
  forSitemap?: boolean;
};
export type JobAdsWithPaginationResponseDef = PaginationResponseDef<IJobAdApiResponse>;
export type JobAdsWithPaginationDef = PaginationResponseDef<JobAdDef>;

type StatDef = {
  id: string;
  name: "language" | "country";
  value: string;
};

export interface IJobAdApiResponse {
  id: string;
  title: string;
  coverImage: string;
  slug: string;
  fullSlug: string;
  description: string;
  status: EJobAdStatus;
  travel: EJobTravelType;
  requiredLanguages: StatDef[] | null;
  requiredCountries: StatDef[] | null;
  duration: EJobDuration;
  workingHours: number | null;
  salaryMin: number | null;
  salaryMax: number | null;
  currency: string | null;
  salaryFrequency: ESalaryFrequency;
  salaryHidden: boolean;
  jobStartDate: string | null;
  jobEndDate: string | null;
  crafthuntCompanyId: string;
  addressId: string | null;
  coordinateId: string | null;
  roleId: string;
  tradeId: string;
  createdAt: string;
  updatedAt: string;
  unpublishedAt: string | null;
  address: {
    country: string;
    city: string;
    street: string;
    postalCode: string;
  } | null;
  coordinate: {
    id: string;
    lat: number;
    lng: number;
  } | null;
  useCompanyAddress: boolean;
  documentsRequired: boolean;
  role: JobTitleTagDef;
  trade: TradeTagDef;
  medias: {
    id: string;
    url: string;
    crafthuntCompanyJobAdId: string;
  }[];
  projectTypes: ProjectTypeTagDef[];
  certificates: ICertificate[];
  candidates: CandidateDef[];
}

export interface IJobAdColumnsDataType {
  key: string;
  status: JSX.Element;
  title: string;
  slug: string;
  roleName: string;
  tradeName: string;
  address: string;
  createdAt: string;
  operation: JSX.Element;
}
