import { VideoCameraAddOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { App, Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  createCompanyVideo,
  deleteCompanyVideo,
  updateCompanyVideo,
  useVideos,
} from "@app/api/videos.api";
import { RemovableCardForm } from "@app/components/ui/RemovableCardForm/RemovableCardForm";
import {
  RemovableCardFormInputOptions,
  RemovableCardFormInputTypes,
} from "@app/components/ui/RemovableCardForm/RemovableCardForm.types";
import { Colors } from "@app/constants/colors.constants";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { RootState } from "@app/store/store";
import { CardLayout } from "@layouts/CardLayout/CardLayout";
import { ContentLayout } from "@layouts/ContentLayout/ContentLayout";

type VideoItemDef = {
  id: string;
  url: string;
  title: string;
  description: string;
};

export const MANDATORY_DATA: string[] = [];

export const CompanyCulture = () => {
  const { t, i18n } = useTranslation();
  const { message } = App.useApp();
  const companyId = useSelector((state: RootState) => state.companyData.companyId);
  const videosQuery = useVideos({
    companyId,
  });

  const messageKey = "saving";
  const showSavingMessage = () => {
    message.loading({
      content: t("Saving..."),
      key: messageKey,
    });
  };
  const onSettled = () => {
    videosQuery.refetch();
    message.success({
      content: t("Saved!"),
      key: messageKey,
      duration: 1,
    });
  };

  const deleteVideo = useMutation({
    mutationFn: deleteCompanyVideo,
    onMutate: showSavingMessage,
    onSettled,
  });
  const createVideo = useMutation({
    mutationFn: createCompanyVideo,
    onMutate: showSavingMessage,
    onSettled,
  });
  const updateVideo = useMutation({
    mutationFn: updateCompanyVideo,
    onMutate: showSavingMessage,
    onSettled,
  });

  const videos = (
    videosQuery.data && videosQuery.data.length > 0
      ? videosQuery.data
      : [
          {
            id: "",
            title: "",
            url: "",
            description: "",
          },
        ]
  ).map((video) => {
    return {
      id: video.id,
      name: "company-culture-video-card-name",
      initialValues: {
        id: video.id,
        title: video.title,
        url: video.url,
        description: video.description ?? "",
      },
      isTemp: !video.id,
      noRemoveButton: true,
      icon: <VideoCameraAddOutlined />,
      options: [
        {
          inputType: RemovableCardFormInputTypes.textField,
          required: true,
          description: t("company-culture-video-url"),
          placeholder: "Enter...",
          value: "url",
          hideLabel: false,
          rules: [
            {
              type: "url",
              required: true,
              whitespace: true,
              message: t("Invalid url"),
              validator: (rule, value) => {
                const youtubeRegex =
                  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
                if (value.match(youtubeRegex)) {
                  return Promise.resolve();
                }
                return Promise.reject(t("Invalid url"));
              },
            },
          ],
        },
        {
          inputType: RemovableCardFormInputTypes.textArea,
          required: false,
          description: t("company-culture-video-description"),
          placeholder: "Enter...",
          value: "description",
          hideLabel: false,
        },
      ] as RemovableCardFormInputOptions<VideoItemDef>[],
    };
  });

  const saveItem = (item: VideoItemDef) => {
    if (item.id) {
      updateVideo.mutate({
        id: item.id,
        title: item.title,
        url: item.url,
        description: item.description,
        lang: i18n.language,
      });
    } else {
      createVideo.mutate({
        title: item.title,
        url: item.url,
        description: item.description,
        lang: i18n.language,
      });
    }
  };

  const removeItem = (video: (typeof videos)[number]) => {
    deleteVideo.mutate(video.id);
  };

  return (
    <ContentLayout
      title={t("Company Culture")}
      description={<Typography.Text>{t("company-culture-page-description")}</Typography.Text>}
      nextRoute={RoutePaths.COMPANY_TRADES}
    >
      <CardLayout
        loading={
          videosQuery.isLoading ||
          deleteVideo.isPending ||
          createVideo.isPending ||
          updateVideo.isPending
        }
        data={videos}
        renderData={(item) => (
          <RemovableCardForm<VideoItemDef>
            initialValues={item.initialValues}
            options={item.options}
            onSave={saveItem}
            onRemove={() => removeItem(item)}
            noRemoveButton={item.isTemp}
            title={
              <Row align="middle" justify="start" style={{ minHeight: 50 }}>
                <Col
                  xs={4}
                  style={{
                    fontSize: "20pt",
                    color: Colors.primary,
                  }}
                >
                  {item.icon}
                </Col>
                <Col xs={20} style={{ paddingLeft: "4px", whiteSpace: "normal" }}>
                  <Typography.Text>
                    {t(item.name)}
                    <Typography.Text type="secondary" italic>
                      {!MANDATORY_DATA.includes(item.name) && ` (${t("Optional")})`}
                    </Typography.Text>
                  </Typography.Text>
                </Col>
              </Row>
            }
          />
        )}
      />
    </ContentLayout>
  );
};
