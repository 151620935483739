import { ArrowDownOutlined, ArrowUpOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, App, Button, Modal, Space, Tabs, TabsProps } from "antd";
import { useForm } from "antd/lib/form/Form";
import clsx from "clsx";
import { parseAsStringEnum, useQueryState } from "nuqs";
import { useEffect, useState } from "react";
import isEqual from "react-fast-compare";
import { useTranslation } from "react-i18next";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { Candidates } from "@app/features/super-admin/components/SuperAdminStuff/components/Candidates/Candidates";
import { RecommendationsTab } from "@app/features/super-admin/components/SuperAdminStuff/components/Users/components/RecommendationsTab/RecommendationsTab";
import { UserFormDef } from "@app/features/super-admin/types/super-admin.user.types";
import { UnpackedArray } from "@app/types/util.types";
import styles from "./UserModal.module.scss";
import { ApprovalHistory } from "./components/ApprovalHistory/ApprovalHistory";
import { CompanyAdminInfoForm } from "./components/CompanyAdminInfoForm/CompanyAdminInfoForm";
import { DashboardTab } from "./components/DashboardTab/DashboardTab";
import { UserAdvancedForm } from "./components/UserAdvancedForm/UserAdvancedForm";
import { UserCertificates } from "./components/UserCertificates/UserCertificates";
import { UserHighlights } from "./components/UserHighlights/UserHighlights";
import { UserInfoForm } from "./components/UserInfoForm/UserInfoForm";
import { UserRelevantJobs } from "./components/UserRelevantJobs/UserRelevantJobs";
import { UserStats } from "./components/UserStats/UserStats";
import { UserTimeline } from "./components/UserTimeline/UserTimeline";

type UserModalProps = {
  open: boolean;
  showUserProgress?: boolean;
  userProgressNext?: () => void;
  userProgressPrev?: () => void;
  userProgressHasNext?: boolean;
  userProgressHasPrev?: boolean;
  selectedUser?: UserFormDef;
  isLoading?: boolean;
  isError?: boolean;
  renderInline?: boolean;
  onSave: () => void;
  onCancel?: () => void;
};

export enum USER_MODAL_TAB_KEYS {
  DASHBOARD = "dashboard",
  INFO = "info",
  TIMELINE = "timeline",
  CERTIFICATE = "certificate",
  HIGHLIGHTS = "highlights",
  RECOMMENDATIONS = "recommendations",
  STATS = "stats",
  APPLICATIONS = "applications",
  RELEVANT_JOBS = "relevant-jobs",
  APPROVAL_HISTORY = "approval-history",
  ADVANCED = "advanced",
}

export const useUserModalTab = ({ defaultTab = USER_MODAL_TAB_KEYS.INFO } = {}) => {
  const [activeTab, setActiveTab] = useQueryState(
    "userModalTab",
    parseAsStringEnum(Object.values(USER_MODAL_TAB_KEYS)).withDefault(defaultTab)
  );

  return {
    activeTab,
    setActiveTab,
  };
};

export const UserModal = ({
  open,
  selectedUser,
  isLoading,
  isError,
  showUserProgress = false,
  userProgressNext = () => null,
  userProgressPrev = () => null,
  userProgressHasNext = false,
  userProgressHasPrev = false,
  renderInline = false,
  onSave,
  onCancel = () => null,
}: UserModalProps) => {
  const { t } = useTranslation();
  const app = App.useApp();
  const defaultTab = showUserProgress ? USER_MODAL_TAB_KEYS.DASHBOARD : USER_MODAL_TAB_KEYS.INFO;
  const { activeTab, setActiveTab } = useUserModalTab({
    defaultTab,
  });
  // forms
  const [form] = useForm<UserFormDef>();
  const [statsHasChangedValues, setStatsHasChangedValues] = useState(false);

  const deletedUser = Boolean(selectedUser?.deletedAt);

  const showConfirmModal = (callback: () => void, isStats = false) => {
    app.modal.confirm({
      title: t(
        isStats
          ? "You have unsaved stats, are you sure you want to discard them?"
          : "You have unsaved changes, are you sure you want to leave?"
      ),
      icon: <ExclamationCircleOutlined />,
      closable: true,
      maskClosable: true,
      cancelText: t("Cancel"),
      onOk: callback,
      onCancel: () => null,
    });
  };

  useEffect(() => {
    if (selectedUser) {
      form.resetFields();
    }
  }, [JSON.stringify(selectedUser)]);

  const handleOnCancel = () => {
    const allValues = form.getFieldsValue(true);

    if (!isEqual(selectedUser, allValues) || statsHasChangedValues) {
      showConfirmModal(onCancel, statsHasChangedValues);
    } else {
      onCancel();
    }
  };

  const handleOnSave = () => {
    onSave();
  };

  const onChangeTab = (key: string) => {
    if (activeTab === USER_MODAL_TAB_KEYS.STATS && statsHasChangedValues) {
      showConfirmModal(() => setActiveTab(key as USER_MODAL_TAB_KEYS), statsHasChangedValues);
    } else {
      setActiveTab(key as USER_MODAL_TAB_KEYS);
    }
  };

  const changeCandidateSettings = async () => {
    if (statsHasChangedValues) {
      showConfirmModal(form.submit, statsHasChangedValues);
    } else {
      form.submit();
    }
  };
  const handleReset = () => {
    form.resetFields();
    setActiveTab(null);
  };

  let tabItems: (UnpackedArray<TabsProps["items"]> & {
    modalWidth?: string | number;
  })[] = [];

  if (selectedUser?.isCompanyAdmin) {
    tabItems = [
      {
        key: USER_MODAL_TAB_KEYS.INFO,
        label: t("Info"),
        children: (
          <CompanyAdminInfoForm form={form} onSave={handleOnSave} selectedUser={selectedUser} />
        ),
        forceRender: true,
        destroyInactiveTabPane: false,
      },
      {
        key: USER_MODAL_TAB_KEYS.ADVANCED,
        label: t("Advanced"),
        children: <UserAdvancedForm selectedUser={selectedUser} />,
        forceRender: false,
        destroyInactiveTabPane: false,
      },
    ];
  } else {
    tabItems = [
      ...(showUserProgress
        ? [
            {
              key: USER_MODAL_TAB_KEYS.DASHBOARD,
              label: "Dashboard",
              children: <DashboardTab key={selectedUser?.id} />,
              forceRender: true,
              destroyInactiveTabPane: false,
              className: renderInline ? styles.scrollableTab : "",
            },
          ]
        : []),
      {
        key: USER_MODAL_TAB_KEYS.INFO,
        label: t("Info"),
        children: (
          <UserInfoForm
            form={form}
            onSave={handleOnSave}
            renderButtons={renderInline}
            changeCandidateSettings={changeCandidateSettings}
            selectedUser={selectedUser}
          />
        ),
        forceRender: true,
        destroyInactiveTabPane: false,
      },
      ...(!deletedUser
        ? [
            {
              key: USER_MODAL_TAB_KEYS.RELEVANT_JOBS,
              label: t("Relevant Jobs"),
              children: selectedUser ? <UserRelevantJobs selectedUser={selectedUser} /> : null,
              forceRender: false,
              destroyInactiveTabPane: true,
              modalWidth: "100%",
            },
          ]
        : []),
      {
        key: USER_MODAL_TAB_KEYS.APPLICATIONS,
        label: t("applications-label"),
        children: <Candidates selectedUser={selectedUser} />,
        forceRender: false,
        destroyInactiveTabPane: true,
        modalWidth: "100%",
      },
      ...(!deletedUser
        ? [
            {
              key: USER_MODAL_TAB_KEYS.RECOMMENDATIONS,
              label: "Recommend",
              children: selectedUser ? (
                <RecommendationsTab renderInline={renderInline} selectedUser={selectedUser} />
              ) : null,
              forceRender: false,
              destroyInactiveTabPane: true,
              modalWidth: "100%",
            },
            {
              key: USER_MODAL_TAB_KEYS.APPROVAL_HISTORY,
              label: t("Approval History"),
              children: <ApprovalHistory workerProfileId={selectedUser?.id} />,
              forceRender: false,
              destroyInactiveTabPane: true,
              modalWidth: "100%",
            },
            {
              key: USER_MODAL_TAB_KEYS.TIMELINE,
              label: t("Timeline"),
              children: <UserTimeline selectedUser={selectedUser} />,
              forceRender: false,
              destroyInactiveTabPane: true,
            },
            {
              key: USER_MODAL_TAB_KEYS.CERTIFICATE,
              label: t("Certificates"),
              children: <UserCertificates selectedUser={selectedUser} />,
              forceRender: false,
              destroyInactiveTabPane: true,
            },
            {
              key: USER_MODAL_TAB_KEYS.HIGHLIGHTS,
              label: t("Site Photos"),
              children: <UserHighlights selectedUser={selectedUser} />,
              forceRender: false,
              destroyInactiveTabPane: true,
            },
            {
              key: USER_MODAL_TAB_KEYS.STATS,
              label: t("Stats"),
              children: (
                <UserStats onChangedValues={setStatsHasChangedValues} selectedUser={selectedUser} />
              ),
              forceRender: false,
              destroyInactiveTabPane: true,
            },
            {
              key: USER_MODAL_TAB_KEYS.ADVANCED,
              label: t("Advanced"),
              children: <UserAdvancedForm selectedUser={selectedUser} />,
              forceRender: false,
              destroyInactiveTabPane: false,
            },
          ]
        : []),
    ];
  }

  const activeTabItem = tabItems.find((item) => item.key === activeTab);

  const nextAndPrevButtons = (
    <Space>
      <Button
        disabled={!userProgressHasPrev}
        icon={<ArrowUpOutlined />}
        onClick={userProgressPrev}
      />
      <Button
        disabled={!userProgressHasNext}
        icon={<ArrowDownOutlined />}
        onClick={userProgressNext}
      />
    </Space>
  );

  if (!selectedUser) {
    tabItems = tabItems.map((item) => ({ ...item, children: null }));
  }

  const content = (
    <>
      {isLoading && <LoadingSpinner />}
      {isError && <Alert message={t("Sorry, something went wrong.")} type="error" />}
      <Tabs
        className={clsx(renderInline && styles.fullHeightTabs)}
        activeKey={activeTab}
        onChange={onChangeTab}
        items={tabItems}
        destroyInactiveTabPane={true}
        tabBarExtraContent={showUserProgress && nextAndPrevButtons}
      />
    </>
  );

  return renderInline ? (
    content
  ) : (
    <Modal
      title={`Edit user: ${selectedUser?.firstName ?? ""} ${selectedUser?.lastName ?? ""}`}
      open={open}
      onCancel={handleOnCancel}
      width={activeTabItem && "modalWidth" in activeTabItem ? activeTabItem.modalWidth : 800}
      onOk={isError ? handleOnCancel : changeCandidateSettings}
      okButtonProps={{ size: "large", disabled: deletedUser }}
      cancelButtonProps={{ size: "large" }}
      forceRender
      destroyOnClose
      afterClose={handleReset}
    >
      {content}
    </Modal>
  );
};
