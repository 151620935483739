import {
  CloseOutlined,
  DollarCircleOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Divider, Modal, Row, Typography } from "antd";
import { Helmet } from "react-helmet";
import { TFunction } from "react-i18next";

const { warning } = Modal;

export const jobAdPaidModal = (t: TFunction<"translation">) => {
  return warning({
    title: (
      <Typography.Title level={5} style={{ margin: 0 }}>
        {t("Get access to thousands of relevant applicants")}
      </Typography.Title>
    ),
    width: 1000,
    maskClosable: true,
    closeIcon: <CloseOutlined />,
    closable: true,
    okButtonProps: { hidden: true },
    style: { top: 20 },
    content: (
      <>
        <Divider />
        <Row gutter={8} wrap={false}>
          <Col flex="auto">
            <Typography.Paragraph>
              {t(
                "On Crafthunt, your Job Ads are exposed to the most relevant construction professionals. Book a call with us to begin publishing your Job Ads as soon as possible!"
              )}
            </Typography.Paragraph>
          </Col>
          <Col flex="0 0 400px">
            <Card bordered={false} style={{ backgroundColor: "#f0f2f5" }}>
              <Row align="middle" gutter={24}>
                <Col span={16}>
                  <Typography.Title level={5} style={{ marginBottom: 4 }}>
                    {t("active-source-paywall-contact-us")}
                  </Typography.Title>
                  <Button
                    href="tel:+4989215427120"
                    type="link"
                    icon={<PhoneOutlined />}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    +49 89 215427120
                  </Button>
                  <Button
                    href="mailto:hello@crafthunt.app"
                    type="link"
                    icon={<MailOutlined />}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    hello@crafthunt.app
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Helmet>
          <script
            type="text/javascript"
            src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
          />
        </Helmet>
        <div>
          <Divider />
          <Typography.Title level={5}>{t("Book an appointment")}</Typography.Title>
          <div
            className="meetings-iframe-container"
            data-src="https://meetings-eu1.hubspot.com/crafthunt/teams-meeting?embed=true"
          />
        </div>
      </>
    ),
    icon: <DollarCircleOutlined />,
  });
};
