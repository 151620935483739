import { useQuery } from "@tanstack/react-query";
import { ETagType, TagDef } from "@app/types/tags.types";
import { queryKeysSA } from "./queryKeys";
import { superAdminApi } from "./super-admin.api";

export const useTagsSA = (
  params: {
    target: ETagType;
    withCount?: boolean;
    withSimilarTags?: boolean;
  },
  options?: { enabled?: boolean }
) =>
  useQuery({
    queryKey: [...queryKeysSA.lists(), "tags", params] as const,
    queryFn: ({ queryKey }) =>
      superAdminApi
        .get<TagDef<(typeof queryKey)[3]["target"]>[]>("crafthunts/tags", {
          params: queryKey[3],
        })
        .then(({ data }) => data),
    enabled: options?.enabled,
  });

export const updateTagSA = (params: {
  id: string;
  definition?: string;
  tradeCategory?: string | null;
}) => {
  return superAdminApi.put(`crafthunts/tags/${params.id}`, params);
};
