import { MdArrowForwardIos } from "react-icons/md";
import styles from "./PageButton.module.scss";

type PageButtonProps = {
  onClick: () => void;
  label: string;
};

export const PageButton = ({ onClick, label }: PageButtonProps) => {
  const handleOnClick = () => {
    onClick();
  };
  return (
    <div className={styles.button} onClick={handleOnClick}>
      <span>{label}</span>
      <MdArrowForwardIos className={styles.icon} />
    </div>
  );
};
