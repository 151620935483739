import { Button, Col, Input, Row, Space, Table, TableColumnsType } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdRefresh } from "react-icons/md";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { recalculateAllMatchScores } from "@app/features/super-admin/api/super-admin.match-score.api";
import { useTagsSA } from "@app/features/super-admin/api/super-admin.tag.api";
import { SUPER_ADMIN_PAGE_SIZE } from "@app/features/super-admin/constants/super-admin.constants";
import { ETagType, TagDef } from "@app/types/tags.types";
import { TagDrawer } from "./components/TagDrawer";

const { Search } = Input;

export const Tags = () => {
  const { t } = useTranslation();
  const [target, setTarget] = useState<ETagType>(ETagType.Crafthunt_Trade);
  const [selectedTag, setSelectedTag] = useState<TagDef<ETagType>>();
  const [loadingMatchScore, setLoadingMatchScore] = useState(false);
  const [showTagDrawer, setShowTagDrawer] = useState(false);
  const [keyword, setKeyword] = useState("");
  const tagsQuery = useTagsSA({
    target,
    withCount: true,
    withSimilarTags: true,
  });
  const [tags, setTags] = useState<TagDef<ETagType>[]>(tagsQuery.data ?? []);

  const tagTypeChoices = [
    {
      value: ETagType.Crafthunt_Trade,
      label: "Trades",
    },
    {
      value: ETagType.Crafthunt_JobTitle,
      label: "Job Titles",
    },
    {
      value: ETagType.Crafthunt_Project_Category,
      label: "Project categories",
    },
    {
      value: ETagType.Crafthunt_Industry,
      label: "Industries",
    },
  ];

  const generatedData = (data: TagDef<ETagType>[]) => {
    return data?.map((item) => ({
      key: item.id,
      translationKey: item.name,
      englishName: t(item.name, { lng: "en" }),
      germanName: t(item.name, { lng: "de" }),
      ...(target === ETagType.Crafthunt_Trade
        ? { alternativeEnglishName: t(`${item.name}-alt`, { lng: "en" }) }
        : {}),
      ...(target === ETagType.Crafthunt_Trade
        ? { alternativeGermanName: t(`${item.name}-alt`, { lng: "de" }) }
        : {}),
      numberOfSimilarTags: item.similarTags?.length,
      usageCount: item.totalCount,
      operation: (
        <a
          onClick={() => {
            setSelectedTag(item);
            setShowTagDrawer(true);
          }}
        >
          {t("Details")}
        </a>
      ),
    }));
  };

  const columns: TableColumnsType<ReturnType<typeof generatedData>[number]> = [
    {
      title: "Translation Key",
      dataIndex: "translationKey",
      key: "translationKey",
      ellipsis: true,
      width: 150,
      sorter: (a, b) => (a.translationKey || "").localeCompare(b.translationKey || ""),
    },
    {
      title: "English",
      dataIndex: "englishName",
      key: "englishName",
      ellipsis: true,
      width: 100,
      sorter: (a, b) => (a.englishName || "").localeCompare(b.englishName || ""),
    },
    {
      title: "German",
      dataIndex: "germanName",
      key: "germanName",
      ellipsis: true,
      width: 100,
      sorter: (a, b) => (a.germanName || "").localeCompare(b.germanName || ""),
    },
    target === ETagType.Crafthunt_Trade
      ? {
          title: "Alt English",
          dataIndex: "alternativeEnglishName",
          key: "alternativeEnglishName",
          ellipsis: true,
          width: 100,
          sorter: (a, b) =>
            (a.alternativeEnglishName || "").localeCompare(b.alternativeEnglishName || ""),
        }
      : { width: 0 },
    target === ETagType.Crafthunt_Trade
      ? {
          title: "Alt German",
          dataIndex: "alternativeGermanName",
          key: "alternativeGermanName",
          ellipsis: true,
          width: 100,
          sorter: (a, b) =>
            (a.alternativeGermanName || "").localeCompare(b.alternativeGermanName || ""),
        }
      : { width: 0 },
    {
      title: "# Similar Tags",
      dataIndex: "numberOfSimilarTags",
      key: "numberOfSimilarTags",
      ellipsis: true,
      width: 80,
      sorter: (a, b) => (a.numberOfSimilarTags || 0) - (b.numberOfSimilarTags || 0),
    },
    {
      title: "Usage Count",
      dataIndex: "usageCount",
      key: "usageCount",
      ellipsis: true,
      width: 80,
      sorter: (a, b) => (a.usageCount || 0) - (b.usageCount || 0),
    },
    {
      title: "Actions",
      dataIndex: "operation",
      key: "operation",
      fixed: "right",
      width: 80,
    },
  ];

  const handleCloseDrawer = () => {
    setSelectedTag(undefined);
    setShowTagDrawer(false);
    tagsQuery.refetch();
  };

  const handleRecalculateMatchScores = async () => {
    if (loadingMatchScore) return;
    setLoadingMatchScore(true);
    try {
      await recalculateAllMatchScores();
      // wait 2 minutes for the match scores to be recalculated
      await new Promise((r) => setTimeout(r, 120000));
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMatchScore(false);
    }
  };

  useEffect(() => {
    if (!keyword) {
      setTags(tagsQuery.data ?? []);
      return;
    }
    const filteredTags = tagsQuery.data?.filter(
      (tag) => tag.name.includes(keyword) || t(tag.name).includes(keyword)
    );
    setTags(filteredTags ?? []);
  }, [keyword, tagsQuery]);

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Row gutter={[16, 16]} align="middle">
        <Col span={12}>
          <Search
            placeholder={t("Search")}
            enterButton={t("Search")}
            size="large"
            onSearch={(value) => {
              setKeyword(value);
            }}
            allowClear
          />
        </Col>
        <Col span={6}>
          <SelectAutocomplete
            value={target}
            placeholder={"Tag Type"}
            options={tagTypeChoices}
            onChange={(value) => {
              setTarget(value);
            }}
          />
        </Col>
        <Col span={6}>
          <Button
            type="primary"
            size="large"
            icon={<MdRefresh />}
            onClick={handleRecalculateMatchScores}
            loading={loadingMatchScore}
          >
            Recalculate Match Scores
          </Button>
        </Col>
      </Row>
      <Table
        style={{ width: "100%" }}
        columns={columns}
        dataSource={generatedData(tags)}
        loading={tagsQuery.isLoading}
        scroll={{ x: target === ETagType.Crafthunt_Trade ? 790 : 590 }}
        pagination={{
          total: tags?.length,
          showTotal: (total) => `${t("Total")} ${total}`,
          pageSize: SUPER_ADMIN_PAGE_SIZE,
          showSizeChanger: false,
          position: ["bottomLeft"],
        }}
      />
      {selectedTag && (
        <TagDrawer
          key={selectedTag.id}
          open={showTagDrawer}
          onClose={handleCloseDrawer}
          tag={selectedTag}
        />
      )}
    </Space>
  );
};
