import { CrafthuntChat } from "@crafthunt-app/crafthunt-web-chat";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { isProd } from "@app/constants/env";
import { useCandidateSA } from "@app/features/super-admin/api/super-admin.candidate.api";
import { firebaseConfig } from "@app/firebaseConfig";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { RootState } from "@app/store/store";

export const ChatTab = ({ candidateId }: { candidateId?: string }) => {
  const superToken = useSelector((state: RootState) => state.superAdmin.superToken);
  const { i18n } = useTranslation();
  const currentTranslations = i18n.getDataByLanguage(i18n.language);

  const candidateQuery = useCandidateSA({
    id: candidateId,
  });

  return (
    <>
      {candidateQuery.isLoading || !superToken || !currentTranslations?.translation ? (
        <LoadingSpinner />
      ) : (
        <div style={{ width: "100%", height: "70vh" }}>
          <CrafthuntChat
            isStaging={!isProd()}
            jwt={superToken}
            firebaseConfig={firebaseConfig}
            translations={currentTranslations.translation}
            candidateId={candidateId}
            lang={convertLangForBackend(i18n.language)}
            viewCandidateChatsAsSuperAdmin
            viewingCandidateWorkerProfileId={candidateQuery.data?.workerProfile.id}
          />
        </div>
      )}
    </>
  );
};
