import { App, Card, Popconfirm, Space } from "antd";
import axios from "axios";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@app/components/ui/Button/Button";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { DateFormats } from "@app/constants/date.constants";
import {
  deleteUserTimelineSA,
  getUserTimelineSA,
} from "@app/features/super-admin/api/super-admin.user.api";
import {
  TimelineDef,
  TimelineFormDef,
  UserFormDef,
} from "@app/features/super-admin/types/super-admin.user.types";
import { ApiFormErrorDef, getErrorMessages } from "@app/helpers/error.helper";
import { getTradeName } from "@app/helpers/tag.helper";
import { TimelineModal } from "./components/TimelineModal/TimelineModal";

type UserTimelineProps = {
  selectedUser?: UserFormDef;
};

export const UserTimeline = ({ selectedUser }: UserTimelineProps) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [loading, setLoading] = useState(true);
  const [timeline, setTimeline] = useState<TimelineDef[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedTimeline, setSelectedTimeline] = useState<TimelineFormDef | undefined>();

  const fetchData = async () => {
    if (selectedUser?.id) {
      setLoading(true);
      try {
        const data = await getUserTimelineSA(selectedUser.id);
        setTimeline(data.data);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOnAdd = () => {
    setOpen(true);
    setSelectedTimeline(undefined);
  };

  const handleOnEdit = (timeline: TimelineDef) => {
    setOpen(true);
    setSelectedTimeline({
      id: timeline.id,
      companyId: timeline.companyInfo
        ? { value: timeline.companyInfo?.id, label: timeline.companyInfo?.name }
        : undefined,
      jobTitleId: timeline.jobTitle?.id,
      tradesId: timeline.trades?.map((trade) => trade.id),
      startDate: moment(timeline.startDate),
      endDate: timeline.endDate ? moment(timeline.endDate) : null,
      address: {
        address: timeline.address || undefined,
        coordinates: timeline.coordinate || undefined,
      },
    });
  };

  const handleOnDelete = async (timeline: TimelineDef) => {
    try {
      await deleteUserTimelineSA(timeline.id, timeline.profileId);
      handleOnSave();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error({
          content: getErrorMessages(error as ApiFormErrorDef),
          key: "user-timeline",
          duration: 10,
        });
      }
    }
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleOnSave = () => {
    handleOnClose();
    fetchData();
  };

  return (
    <div>
      {loading && <LoadingSpinner />}
      <Button onClick={handleOnAdd}>{t("Add")}</Button>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        {timeline.map((item) => (
          <Card
            key={item.id}
            title={item.companyInfo?.name || "⚠️ Missing company! ⚠️"}
            extra={
              <Space>
                <Popconfirm
                  title={t("Are you sure you want to delete this?")}
                  okText={t("Yes")}
                  cancelText={t("No")}
                  onConfirm={() => handleOnDelete(item)}
                >
                  <Button type="text" size="small" danger>
                    {t("Delete")}
                  </Button>
                </Popconfirm>
                <Button type="text" size="small" onClick={() => handleOnEdit(item)}>
                  {t("Edit")}
                </Button>
              </Space>
            }
            size="small"
          >
            {!!item.jobTitle && (
              <div>
                {t("Role")}: {t(item.jobTitle.name)}
              </div>
            )}
            {!!item.trades?.length && (
              <div>
                {t("Trades")}:{" "}
                {item.trades
                  .map((trade) => t(getTradeName(trade.name, item.jobTitle?.category)))
                  .join(", ")}
              </div>
            )}
            {!!item.address && (
              <div>
                {t("Country")}: {item.address.country}
              </div>
            )}
            <div>
              {t("Start date")}: {moment(item.startDate).format(DateFormats.M_Y)}
            </div>
            {!!item.endDate && (
              <div>
                {t("End date")}: {moment(item.endDate).format(DateFormats.M_Y)}
              </div>
            )}
          </Card>
        ))}
      </Space>
      {selectedUser && (
        <TimelineModal
          open={open}
          workerProfileId={selectedUser.id}
          selectedTimeline={selectedTimeline}
          onSave={handleOnSave}
          onCancel={handleOnClose}
        />
      )}
    </div>
  );
};
