import { Alert, Divider } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "@app/components/ui/Button/Button";
import { OnboardingSteps } from "@app/components/ui/OnboardingSteps/OnboardingSteps";
import { useAccountSetupProgress } from "@app/hooks/useAccountSetupProgress";
import { useLiveURL } from "@app/hooks/useLiveUrl";
import { RootState, store } from "@app/store/store";
import { ApprovalStatusEnum } from "@app/types/company-info.types";
import { ContentLayout } from "@layouts/ContentLayout/ContentLayout";

// Several states:
// Finished but not submitted
// Submitted: pending, approved, denied
// Not ready to submit yet.
export const SubmitForReview = () => {
  const { t } = useTranslation();
  const url = useLiveURL();
  const { dispatch } = store;
  const progress = useAccountSetupProgress();
  const [readyForReview, setReadyForReview] = useState(false);
  const existingCompanyInfo = useSelector((state: RootState) => state.companyInfo.companyInfo);
  const [approvalStatus, setApprovalStatus] = useState(existingCompanyInfo.approvalStatus);
  const [clickedResubmit, setClickedResubmit] = useState(false);

  const onSubmit = async () => {
    try {
      await dispatch.companyInfo.updateApprovalStatusAction({
        id: existingCompanyInfo.id,
        approvalStatus: ApprovalStatusEnum.PENDING,
      });
      setApprovalStatus(ApprovalStatusEnum.PENDING);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!Object.values(progress.steps).includes(false)) {
      setReadyForReview(true);
    } else {
      setReadyForReview(false);
    }
  }, [progress]);

  return (
    <ContentLayout
      title={t("Submit for review")}
      footer={
        <a href={url} target="_blank" rel="noreferrer">
          {t("Don't forget to check out your Crafthunt profile live!")}
        </a>
      }
    >
      {
        {
          [ApprovalStatusEnum.APPROVED]: (
            <Alert
              style={{
                width: "100%",
              }}
              message={t("Approved")}
              description={t(
                "Congrats! Your company has been approved and it's now publicly available in the app."
              )}
              type="success"
              showIcon
            />
          ),
          [ApprovalStatusEnum.PENDING]: (
            <>
              <Alert
                style={{
                  width: "100%",
                }}
                message={t("Pending review")}
                description={t(
                  "Your information has been submitted and is pending approval. You will hear back from us within a few days, but typically within 24 hours. If you made some changes during the review process, you can press the resubmit button to submit again when you are ready."
                )}
                type="warning"
                showIcon
              />
              {!readyForReview && <OnboardingSteps />}
              {!clickedResubmit && (
                <div>
                  <Button
                    disabled={!readyForReview}
                    type="primary"
                    size="large"
                    onClick={() => {
                      setClickedResubmit(true);
                      onSubmit();
                    }}
                  >
                    {t("Resubmit")}
                  </Button>
                </div>
              )}
            </>
          ),
          [ApprovalStatusEnum.REJECTED]: (
            <Alert
              style={{
                width: "100%",
              }}
              message={t("Denied")}
              description={t(
                "We've decided not to display your company on Crafthunt yet. Try adding some more information and making sure your profile looks professional. You can always contact us in the chat in the bottom right corner to get help."
              )}
              type="error"
              showIcon
            />
          ),
          [ApprovalStatusEnum.NOT_STARTED]: (
            <>
              <Alert
                style={{
                  width: "100%",
                }}
                message={
                  readyForReview ? t("Submit your information for review") : t("Not just yet!")
                }
                description={
                  readyForReview
                    ? t(
                        "Looks like you're ready to submit your information for review. Click below to request access to the app so you can start finding new craftsmen."
                      )
                    : t(
                        "Looks like you still have some information to fill out. Click on the incomplete sections below and fill out some more information before requesting a review of your profile."
                      )
                }
                type="info"
                showIcon
              />
              <Divider />
              <OnboardingSteps />
              <Button
                disabled={!readyForReview}
                type="primary"
                size="large"
                onClick={() => onSubmit()}
              >
                {t("Submit for review")}
              </Button>
            </>
          ),
        }[approvalStatus]
      }
    </ContentLayout>
  );
};
