import { useMutation } from "@tanstack/react-query";
import { Alert, App, Form, Input, Spin } from "antd";
import { useEffect } from "react";
import {
  updateCompanySettingsSA,
  useCompanySA,
} from "@app/features/super-admin/api/super-admin.company.api";
import { updateUserSA, useUserSA } from "@app/features/super-admin/api/super-admin.user.api";

export const UserCommentForm = ({ userId }: { userId: string }) => {
  const [form] = Form.useForm<{
    internalComment: string;
  }>();
  const { message } = App.useApp();

  const userQuery = useUserSA({
    userId,
  });

  const deletedUser = Boolean(userQuery.data?.deletedAt);

  const updateUser = useMutation({
    mutationFn: updateUserSA,
    onSuccess: () => {
      message.success("Comment saved");
    },
    onSettled: () => {
      userQuery.refetch();
    },
  });

  useEffect(() => {
    if (userQuery.data?.internalComment !== form.getFieldValue("internalComment")) {
      form.resetFields();
    }
  }, [userQuery.data]);

  return (
    <>
      {userQuery.isLoading && <Spin />}
      {userQuery.error && <Alert type="error" message="Failed to load user data" />}
      {userQuery.data && (
        <Form
          form={form}
          layout="vertical"
          name="userInternalComment"
          data-hs-do-not-collect="true"
          initialValues={{
            internalComment: userQuery.data.internalComment ?? "",
          }}
          style={{
            width: "100%",
          }}
          onFinish={(values) => {
            updateUser.mutate({ id: userId, internalComment: values.internalComment });
          }}
          disabled={deletedUser}
        >
          <Form.Item label="Internal comment" name="internalComment">
            <Input.TextArea
              placeholder="Enter..."
              size="large"
              autoSize={{
                minRows: 1,
                maxRows: 6,
              }}
              style={{
                fontSize: "14px",
              }}
              onBlur={() => {
                if (form.getFieldValue("internalComment") !== userQuery.data?.internalComment) {
                  form.submit();
                }
              }}
            />
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export const CompanyCommentForm = ({ companyId }: { companyId: string }) => {
  const [form] = Form.useForm<{
    internalComment: string;
  }>();
  const { message } = App.useApp();

  const companyQuery = useCompanySA({
    companyId,
  });

  const updateCompany = useMutation({
    mutationFn: updateCompanySettingsSA,
    onSuccess: () => {
      message.success("Comment saved");
    },
    onSettled: () => {
      companyQuery.refetch();
    },
  });

  useEffect(() => {
    if (companyQuery.data?.internalComment !== form.getFieldValue("internalComment")) {
      form.resetFields();
    }
  }, [companyQuery.data]);

  const initialValues = {
    internalComment: companyQuery.data?.internalComment ?? "",
  };

  return (
    <>
      {companyQuery.isLoading && <Spin />}
      {companyQuery.error && <Alert type="error" message="Failed to load user data" />}
      {companyQuery.data && (
        <Form
          form={form}
          name="companyInternalComment"
          layout="vertical"
          data-hs-do-not-collect="true"
          initialValues={initialValues}
          style={{
            width: "100%",
          }}
          onFinish={(values) => {
            updateCompany.mutate({
              id: companyQuery.data.id,
              internalComment: values.internalComment,
            });
          }}
        >
          <Form.Item label="Internal company comment" name="internalComment">
            <Input.TextArea
              placeholder="Enter..."
              size="large"
              autoSize={{
                minRows: 1,
                maxRows: 6,
              }}
              style={{
                fontSize: "14px",
              }}
              onBlur={() => {
                if (form.getFieldValue("internalComment") !== companyQuery.data?.internalComment) {
                  form.submit();
                }
              }}
            />
          </Form.Item>
        </Form>
      )}
    </>
  );
};
