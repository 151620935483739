import { useQueryClient } from "@tanstack/react-query";
import { parseAsString, useQueryState } from "nuqs";
import { useMemo } from "react";
import { queryKeysSA } from "@app/features/super-admin/api/queryKeys";
import { useUserSA } from "@app/features/super-admin/api/super-admin.user.api";
import { mapUserToForm } from "@app/features/super-admin/helpers/super-admin.helper";
import { UserModal, USER_MODAL_TAB_KEYS, useUserModalTab } from "./UserModal";

export const useUserModalGlobal = () => {
  const [userId, setUserId] = useQueryState("userId", parseAsString);
  const { setActiveTab } = useUserModalTab();

  return {
    userId,
    setUserId,
    openUserModal: ({ userId, tab }: { userId: string; tab?: USER_MODAL_TAB_KEYS | null }) => {
      setUserId(userId);

      if (tab !== undefined) {
        setActiveTab(tab);
      }
    },
  };
};

export const UserModalGlobal = () => {
  const queryClient = useQueryClient();
  const { userId, setUserId } = useUserModalGlobal();

  const userQuery = useUserSA({
    userId,
  });

  const selectedUser = useMemo(() => {
    return userQuery.data ? mapUserToForm(userQuery.data) : undefined;
  }, [userQuery.data]);

  return (
    <UserModal
      open={Boolean(userId)}
      isError={userQuery.isError}
      isLoading={userQuery.isLoading}
      selectedUser={selectedUser}
      onSave={() => {
        queryClient.invalidateQueries({
          queryKey: queryKeysSA.all,
        });
        setUserId(null);
      }}
      onCancel={() => setUserId(null)}
    />
  );
};
