import { useQuery } from "@tanstack/react-query";
import { queryKeysSA } from "./queryKeys";
import { superAdminApi } from "./super-admin.api";

export const useAdminsSA = () =>
  useQuery({
    staleTime: 1000 * 60 * 60,
    queryKey: [...queryKeysSA.lists(), "admins"] as const,
    queryFn: () => superAdminApi.get<Admin[]>("/super_admin/admins").then(({ data }) => data),
  });

type Admin = {
  id: string;
  firstName: string;
  lastName: string;
  team: string;
};
