import clsx from "clsx";
import { IconBaseProps, IconType } from "react-icons";
import styles from "./Icon.module.scss";

type IconProps = {
  icon: IconType;
} & IconBaseProps;

export const Icon = ({ icon: IconComponent, className, ...rest }: IconProps) => {
  return <IconComponent className={clsx(styles.icon, className)} {...rest} />;
};
