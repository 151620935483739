export enum KeyDataType {
  Employees = "employee-stat",
  EmployeeAge = "avg-employee-age-stat",
  AverageProjectTime = "average-duration-stat",
  AverageDistanceToConstructionProjects = "average-project-distance-stat",
  CompletedConstructionProjects = "completed-projects-stat",
  ActiveConstructionProjects = "active-projects-stat",
  AnnualConstructionVolume = "annual-construction-volume-stat",
}

export enum KeyDataUnits {
  YEAR_UNIT = "year-unit",
  EMPLOYEE_UNIT = "employee-unit",
  KM_UNIT = "km-unit",
  PROJECT_UNIT = "project-unit",
  CURRENCY_UNIT = "currency-unit",
}

export interface KeyCompanyDataForm {
  id: string;
  name: KeyDataType;
  value?: number;
  isTemp?: true;
}

export interface PostKeyCompanyData {
  name: KeyDataType;
  value: number;
}

export type UpdateKeyCompanyData = PostKeyCompanyData & {
  id: string;
};

export interface ApiResponseKeyCompanyData {
  id: string;
  name: KeyDataType;
  value: number;
  unit: KeyDataUnits;
  companyId: string;
  createdAt: string;
  updatedAt: string;
}
