import { Card, Col, Empty, Row, Space } from "antd";
import { parseAsString, useQueryState } from "nuqs";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocalStorage } from "react-use";
import {
  EUserProgressType,
  useUserProgressSA,
  useUsersProgressSA,
} from "@app/features/super-admin/api/super-admin.progress.api";
import { useUserSA } from "@app/features/super-admin/api/super-admin.user.api";
import { UserModal } from "@app/features/super-admin/components/SuperAdminStuff/components/Users/components/UserModal/UserModal";
import { mapUserToForm } from "@app/features/super-admin/helpers/super-admin.helper";
import { RootState } from "@app/store/store";
import { Filters } from "./Filters";
import { Reminders } from "./Reminders";
import { UserList } from "./UserList/UserList";

export const UserProgress = () => {
  const parsedSuperToken = useSelector((state: RootState) => state.superAdmin.parsedSuperToken);
  const [selectedAdminIds, setSelectedAdminIds] = useLocalStorage(
    "sa-progress-admins",
    parsedSuperToken ? [parsedSuperToken.id] : []
  );
  const [selectedTaskId, setSelectedTaskId] = useState<string>();
  const [selectedProgressType, setSelectedProgressType] = useState<EUserProgressType>(
    EUserProgressType.ACTIVE
  );
  const [keyword, setKeyword] = useState("");

  const [userProgressId, setUserProgressId] = useQueryState("userProgress", parseAsString);

  useEffect(() => {
    return () => {
      setUserProgressId(null);
    };
  }, []);

  const trimmedKeyword = keyword.trim();
  const isKeywordSearchActive = trimmedKeyword.length > 0;
  const usersProgressQuery = useUsersProgressSA({
    ...(isKeywordSearchActive
      ? { keyword: trimmedKeyword }
      : {
          assignedSuperAdminIds: selectedAdminIds,
          uncompletedTaskIdWithinActiveGoals: selectedTaskId,
          showUserProgressType: selectedProgressType,
        }),
    limit: 100,
    offset: 0,
  });
  const userProgressQuery = useUserProgressSA({
    id: userProgressId,
  });
  const userQuery = useUserSA({
    userId: userProgressQuery.data?.workerProfileId,
  });

  const selectedUser = useMemo(() => {
    return userQuery.data ? mapUserToForm(userQuery.data) : undefined;
  }, [userQuery.data]);

  const userProgressNextUser = useMemo(() => {
    const foundIndex = usersProgressQuery.data?.data?.findIndex(
      (item) => item.id === userProgressId
    );
    if (foundIndex !== undefined && foundIndex !== -1) {
      return usersProgressQuery.data?.data[foundIndex + 1];
    }
  }, [usersProgressQuery.data, userProgressId]);

  const userProgressPrevUser = useMemo(() => {
    const foundIndex = usersProgressQuery.data?.data?.findIndex(
      (item) => item.id === userProgressId
    );
    if (foundIndex !== undefined && foundIndex !== -1) {
      return usersProgressQuery.data?.data[foundIndex - 1];
    }
  }, [usersProgressQuery.data, userProgressId]);

  const goToNextUser = () => {
    if (userProgressNextUser) {
      setUserProgressId(userProgressNextUser.id);
    }
  };

  const goToPrevUser = () => {
    if (userProgressPrevUser) {
      setUserProgressId(userProgressPrevUser.id);
    }
  };

  return (
    <>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Row gutter={[16, 16]} align="top">
          <Col flex={1}>
            <Filters
              keyword={keyword}
              setKeyword={setKeyword}
              selectedTaskId={selectedTaskId}
              setSelectedTaskId={setSelectedTaskId}
              selectedAdminIds={selectedAdminIds ?? []}
              setSelectedAdminIds={setSelectedAdminIds}
              selectedProgressType={selectedProgressType}
              setSelectedProgressType={setSelectedProgressType}
            />
          </Col>
          <Col>
            <Reminders />
          </Col>
        </Row>
      </Space>
      <Row
        gutter={24}
        style={{
          overflowY: "auto",
          paddingTop: "12px",
          height: "100%",
        }}
      >
        <Col
          flex="220px"
          style={{
            maxHeight: "100%",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <UserList
            usersProgressQuery={usersProgressQuery}
            userProgressId={userProgressId}
            setUserProgressId={setUserProgressId}
          />
        </Col>
        <Col
          flex="1"
          style={{
            maxHeight: "100%",
            overflowY: "auto",
          }}
        >
          <Card
            style={{
              height: "100%",
            }}
            styles={{
              body: {
                height: "100%",
                overflowY: "auto",
                padding: 0,
              },
            }}
          >
            {!userProgressId && (
              <Empty
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                description="Click on one of the user cards on the left to start"
              />
            )}
            {userProgressId && (
              <UserModal
                renderInline
                showUserProgress
                userProgressHasNext={Boolean(userProgressNextUser)}
                userProgressHasPrev={Boolean(userProgressPrevUser)}
                userProgressNext={goToNextUser}
                userProgressPrev={goToPrevUser}
                open
                selectedUser={selectedUser}
                isLoading={userQuery.isLoading}
                isError={userQuery.isError}
                onSave={() => {
                  userQuery.refetch();
                  userProgressQuery.refetch();
                  usersProgressQuery.refetch();
                }}
              />
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};
