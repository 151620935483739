import {
  ANSWER_TYPE_MAP,
  EJobQuestionAnswerChoice,
  EJobQuestionAnswerType,
  EJobQuestionAnswerTypeCombined,
  EJobQuestionOptionType,
  JobQuestionDef,
  JobQuestionFormDef,
} from "@app/types/job-questions.types";
import { getKeys } from "./util.helper";

export const mapResponseToFormData = (item: JobQuestionDef): JobQuestionFormDef => {
  if (item.answerType === EJobQuestionAnswerType.OPTION) {
    if (item.answerChoice === EJobQuestionAnswerChoice.MULTI) {
      return {
        ...item,
        answerTypeCombined: EJobQuestionAnswerTypeCombined.OPTION_MULTI,
      };
    }
    if (item.options.find((option) => option.type === EJobQuestionOptionType.BOOLEAN_TRUE)) {
      return {
        ...item,
        answerTypeCombined: EJobQuestionAnswerTypeCombined.BOOLEAN,
      };
    }
    return {
      ...item,
      answerTypeCombined: EJobQuestionAnswerTypeCombined.OPTION_SINGLE,
    };
  }

  const foundAnswerTypeCombined = getKeys(ANSWER_TYPE_MAP).find(
    (key) => ANSWER_TYPE_MAP[key] === item.answerType
  );
  return {
    ...item,
    answerTypeCombined: foundAnswerTypeCombined || EJobQuestionAnswerTypeCombined.TEXT,
  };
};
