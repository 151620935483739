import { Badge, Space, Tag } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getJobAds } from "@app/api/job-ads.api";
import { Label } from "@app/components/ui/Label/Label";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { CandidateDef, UpdateCandidateDef } from "@app/types/candidate.types";
import { EJobAdStatus, JobAdDef } from "@app/types/job-ads.types";

type JobsSelectProps = {
  candidate: CandidateDef;
  updateCandidate: (payload: UpdateCandidateDef) => void;
};

export const JobsSelect = ({ candidate, updateCandidate }: JobsSelectProps) => {
  const { t } = useTranslation();
  const [allJobAds, setAllJobAds] = useState<JobAdDef[]>([]);
  const [currentJobIds, setCurrentJobIds] = useState<string[]>(
    candidate.jobAds.map((job) => job.id)
  );

  const saveCandidate = (newJobs: string[]) => {
    updateCandidate({
      jobAdsId: newJobs,
    });
    setCurrentJobIds(newJobs);
  };

  useEffect(() => {
    const getAllJobAds = async () => {
      // TODO: add pagination to get all jobs
      const response = await getJobAds({
        limit: 100,
      });
      if (response) {
        setAllJobAds(response.data);
      }
    };
    getAllJobAds();
  }, []);

  return (
    <>
      <Label htmlFor="candidate-assigned-job-ads">{t("assigned-job-ads")}</Label>
      <SelectAutocomplete
        mode="multiple"
        id="candidate-assigned-job-ads"
        value={currentJobIds}
        onChange={saveCandidate}
        options={allJobAds.map((item) => ({
          value: item.id,
          label: (
            <Space>
              <span>
                {item.title} {item.address?.city ? `(${item.address.city})` : ""}
              </span>
              <Tag>
                <Badge
                  key="status"
                  status={item.status === EJobAdStatus.DRAFT ? "warning" : "success"}
                  text={t(item.status)}
                />
              </Tag>
            </Space>
          ),
        }))}
      />
    </>
  );
};
