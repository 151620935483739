import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { Button } from "@app/components/ui/Button/Button";
import styles from "./AuthRedirectButton.module.scss";

type AuthRedirectButtonProps = {
  text?: string;
  actionText: string;
  path: string;
  align?: "left" | "center";
  isInline?: boolean;
};

export const AuthRedirectButton = ({
  text,
  actionText,
  path,
  align = "center",
  isInline,
}: AuthRedirectButtonProps) => {
  const location = useLocation();

  return (
    <div className={clsx({ [styles.center]: align === "center" })}>
      <Link to={path + location.search}>
        <Button type="text" className={clsx(styles.button, { [styles.inlineBtn]: isInline })}>
          {text}
          <span
            className={clsx(styles.underline, {
              [styles.inlineBtn]: !!text,
            })}
          >
            {actionText}
          </span>
        </Button>
      </Link>
    </div>
  );
};
