import { QuestionCircleOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Input, Row, Select, Space, Tag } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getJobAds } from "@app/api/job-ads.api";
import { ContentLayout } from "@app/components/layouts/ContentLayout/ContentLayout";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { EJobAdStatus, JobAdDef } from "@app/types/job-ads.types";
import { AverageResponseTime } from "./components/AverageResponseTime/AverageResponseTime";
import { CandidatesKanban } from "./components/CandidatesKanban/CandidatesKanban";
import { HelpModal } from "./components/HelpModal/HelpModal";
import { KanbanSettingsModal } from "./components/kanbanSettingsModal/KanbanSettingsModal";

const { Search } = Input;

const LAST_JOB_AD_ID = "candidate_kanban_last_job_ad_id";

export enum SpecialJobSelectValue {
  ALL = "all",
  CONTACTED = "contacted",
}

export const Candidates = () => {
  const { t } = useTranslation();
  const [allJobAds, setAllJobAds] = useState<JobAdDef[]>([]);
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentJobAdId, setCurrentJobAdId] = useState<string>(
    () => localStorage.getItem(LAST_JOB_AD_ID) || SpecialJobSelectValue.ALL
  );

  const handleOpenSettings = () => {
    setShowSettingModal(true);
  };

  const handleCloseSettings = () => {
    setShowSettingModal(false);
  };

  const handleOpenHelpModal = () => {
    setShowHelpModal(true);
  };

  const handleCloseHelpModal = () => {
    setShowHelpModal(false);
  };

  const handleOnSearch = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    const getAllJobAds = async () => {
      // TODO: add pagination to get all jobs
      const response = await getJobAds({
        limit: 100,
      });
      if (response) {
        setAllJobAds(response.data);
      }
    };
    getAllJobAds();
  }, []);

  useEffect(() => {
    localStorage.setItem(LAST_JOB_AD_ID, currentJobAdId);
  }, [currentJobAdId]);

  return (
    <>
      <ContentLayout
        compact
        fullHeight
        headerItems={[
          <Button
            key="help"
            type="default"
            size="large"
            onClick={handleOpenHelpModal}
            icon={<QuestionCircleOutlined />}
          >
            {t("Help")}
          </Button>,
          <Button key="kanban-settings" type="primary" size="large" onClick={handleOpenSettings}>
            {t("kanban-settings")}
          </Button>,
        ]}
      >
        <div style={{ padding: "0 40px 40px" }}>
          <Row justify="space-between" align="top" style={{ width: "100%", gap: 8 }}>
            <Col style={{ width: 500 }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <SelectAutocomplete
                  id="candidate-selected-job-ad"
                  value={currentJobAdId}
                  onChange={setCurrentJobAdId}
                  defaultActiveFirstOption
                >
                  <Select.Option
                    value={SpecialJobSelectValue.ALL}
                    label={`- ${t("all-candidates-select")}`}
                  >
                    <strong>{t("all-candidates-select")}</strong>
                  </Select.Option>
                  <Select.Option
                    value={SpecialJobSelectValue.CONTACTED}
                    label={`-1 ${t("all-contacted-candidates-select")}`}
                  >
                    <strong>{t("all-contacted-candidates-select")}</strong>
                  </Select.Option>
                  {allJobAds.map((jobAd) => (
                    <Select.Option key={jobAd.id} value={jobAd.id} label={jobAd.title}>
                      <Space>
                        <span>
                          {jobAd.title} {jobAd.address?.city ? `(${jobAd.address.city})` : ""}
                        </span>
                        <Tag>
                          <Badge
                            key="status"
                            status={jobAd.status === EJobAdStatus.DRAFT ? "warning" : "success"}
                            text={t(jobAd.status)}
                          />
                        </Tag>
                      </Space>
                    </Select.Option>
                  ))}
                </SelectAutocomplete>
                <Search
                  placeholder={t("kanban-search-placeholder")}
                  onSearch={handleOnSearch}
                  style={{ width: "100%" }}
                  allowClear
                  size="large"
                />
              </Space>
            </Col>
            <Col>
              <AverageResponseTime />
            </Col>
          </Row>
        </div>

        <CandidatesKanban currentJobAdId={currentJobAdId} searchValue={searchValue} />
      </ContentLayout>
      <KanbanSettingsModal
        open={showSettingModal}
        onCancel={handleCloseSettings}
        onSaved={handleCloseSettings}
      />
      <HelpModal open={showHelpModal} onClose={handleCloseHelpModal} />
    </>
  );
};
