import { RobotOutlined } from "@ant-design/icons";
import { Descriptions, Flex, Space, Typography } from "antd";
import { CandidateSADef } from "@app/features/super-admin/types/super-admin.candidate.types";

export const AISummary = ({ candidate }: { candidate: CandidateSADef }) => {
  if (!candidate.AIDescriptionSummary) {
    return null;
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Flex gap="small" align="center">
        <RobotOutlined style={{ fontSize: 20 }} />
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          AI Summary
        </Typography.Title>
      </Flex>
      <Descriptions
        bordered
        size="small"
        column={2}
        items={[
          {
            label: "Pro",
            children: (
              <ul
                style={{
                  margin: 0,
                  paddingLeft: 16,
                }}
              >
                {candidate.AIDescriptionSummary["pro-arguments"]?.map((argument, index) => (
                  <li key={index}>{argument}</li>
                ))}
              </ul>
            ),
          },
          {
            label: "Against",
            children: (
              <ul
                style={{
                  margin: 0,
                  paddingLeft: 16,
                }}
              >
                {candidate.AIDescriptionSummary["con-arguments"]?.map((argument, index) => (
                  <li key={index}>{argument}</li>
                ))}
              </ul>
            ),
          },
          {
            label: "Prime candidate",
            span: 2,
            children: (
              <div>
                {candidate.AIDescriptionSummary.prime_candidate_score_reason && (
                  <div>{candidate.AIDescriptionSummary.prime_candidate_score_reason}</div>
                )}
              </div>
            ),
          },
        ]}
      />
    </Space>
  );
};
