import { useQueryClient } from "@tanstack/react-query";
import { Alert, Button, Card, Col, Drawer, Flex, Row, Typography } from "antd";
import _groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import moment from "moment";
import { parseAsString, useQueryState } from "nuqs";
import { memo, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocalStorage, useSessionStorage } from "react-use";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { useAdminsSA } from "@app/features/super-admin/api/super-admin.admin.api";
import {
  Reminder,
  remindersQueryKey,
  useRemindersSA,
} from "@app/features/super-admin/api/super-admin.reminder.api";
import {
  RemindersList,
  ReminderUserName,
} from "@app/features/super-admin/components/SuperAdminStuff/components/Users/components/UserModal/components/DashboardTab/Reminders";
import { RootState } from "@app/store/store";

export const Reminders = memo(() => {
  const queryClient = useQueryClient();
  const parsedSuperToken = useSelector((state: RootState) => state.superAdmin.parsedSuperToken);
  const [isOpen, setIsOpen] = useState(false);
  const [groupBy, setGroupBy] = useLocalStorage(
    "sa-progress-reminders-group-by",
    "date" as "date" | "user"
  );
  const [selectedAdminId, setSelectedAdminId] = useSessionStorage(
    "sa-progress-reminders-admin",
    parsedSuperToken ? parsedSuperToken.id : undefined
  );
  const adminsQuery = useAdminsSA();
  const [, setUserProgressId] = useQueryState("userProgress", parseAsString);

  const remindersQuery = useRemindersSA({
    superAdminId: selectedAdminId,
  });

  const groupsToShow = useMemo(() => {
    const sortedReminders = orderBy(remindersQuery.data, "date", "asc");

    const grouped =
      groupBy === "date"
        ? _groupBy(sortedReminders, (r) => moment(r.date).format("LL"))
        : _groupBy(sortedReminders, (r) => r.workerProfileProgressId);

    return Object.entries(grouped).map(([key, value]) => ({
      title: key,
      items: value,
    }));
  }, [remindersQuery.data, groupBy]);

  const onReminderClick = (reminder: Reminder) => {
    setUserProgressId(reminder.workerProfileProgressId);
    setIsOpen(false);
  };

  return (
    <>
      <Button
        size="large"
        onClick={() => {
          setIsOpen(true);
          remindersQuery.refetch();
        }}
      >
        🔔 Reminders {remindersQuery.data ? `(${remindersQuery.data.length})` : ""}
      </Button>

      <Drawer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={`Reminders ${remindersQuery.data ? `(${remindersQuery.data.length})` : ""}`}
      >
        <Row gutter={8} style={{ marginBottom: 16 }}>
          <Col span={12}>
            <SelectAutocomplete
              allowClear
              sortAlphabetically={false}
              loading={adminsQuery.isLoading}
              value={selectedAdminId}
              placeholder="Admin"
              popupMatchSelectWidth
              options={
                adminsQuery.data?.map((admin) => ({
                  value: admin.id,
                  label: admin.firstName,
                })) ?? []
              }
              onChange={(value) => {
                setSelectedAdminId(value);
              }}
            />
          </Col>
          <Col span={12}>
            <SelectAutocomplete
              sortAlphabetically={false}
              value={groupBy}
              placeholder="Group by"
              popupMatchSelectWidth
              options={[
                { value: "date", label: "By date" },
                { value: "user", label: "By user" },
              ]}
              onChange={(value) => {
                setGroupBy(value);
              }}
            />
          </Col>
        </Row>
        {remindersQuery.isError && <Alert message="Failed to load reminders" type="error" />}
        {remindersQuery.data?.length === 0 && <Alert message="No reminders found" type="info" />}
        {groupsToShow.length > 0 && (
          <Flex vertical gap={4}>
            {groupsToShow.map((group) => (
              <Card
                key={group.title}
                size="small"
                bordered={false}
                title={
                  groupBy === "date" ? (
                    group.title
                  ) : (
                    <Typography.Link onClick={() => onReminderClick(group.items[0])}>
                      <ReminderUserName reminder={group.items[0]} />
                    </Typography.Link>
                  )
                }
              >
                <RemindersList
                  reminders={group.items}
                  onNameClick={groupBy === "date" ? onReminderClick : undefined}
                  onDelete={() => {
                    queryClient.invalidateQueries({
                      queryKey: remindersQueryKey,
                    });
                  }}
                />
              </Card>
            ))}
          </Flex>
        )}
      </Drawer>
    </>
  );
});
