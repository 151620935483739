import clsx from "clsx";
import { LabelHTMLAttributes } from "react";
import styles from "./Label.module.scss";

type LabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
  isInline?: boolean;
};

export const Label = ({ className, isInline, ...rest }: LabelProps) => {
  return <label className={clsx(className, styles.label, isInline && styles.inline)} {...rest} />;
};
