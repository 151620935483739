export const Dashboard = () => {
  return (
    <iframe
      src="https://metabase-zbx3.onrender.com/public/dashboard/9f598573-3a05-44be-bc93-a33b1fd975d1"
      width="100%"
      height="100%"
      style={{ border: 0 }}
    />
  );
};
